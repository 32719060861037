function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp);

    let day = date.getDate();

    if (day < 10) {
        day = '0' + day;
    }

    let month = date.getMonth() + 1;

    if (month < 10) {
        month = '0' + month;
    }

    let year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

function formatDateToUTCToSend(start, end) {
    const startDate = new Date(start);
    const sYear = startDate.getFullYear();
    const sMonth = startDate.getMonth();
    const sDate = startDate.getDate();

    const newStartDate = new Date(Date.UTC(sYear, sMonth, sDate));

    const endDate = new Date(end);
    const eYear = endDate.getFullYear();
    const eMonth = endDate.getMonth();
    const eDate = endDate.getDate();

    let newEndDate = new Date(Date.UTC(eYear, eMonth, eDate));

    newEndDate.setUTCHours(23, 59, 59, 999);

    return { newStartDate, newEndDate };
}

function prepareMonthPeriod(periodId) {
    const date = new Date();
    const year = date.getFullYear();
    const month = periodId === 'currentMonth' ? date.getMonth() : date.getMonth() - 1;
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);

    const { newStartDate, newEndDate } = { ...formatDateToUTCToSend(startDate, endDate) };

    return { newStartDate, newEndDate };
}

export {
    formatTimestampToDate,
    formatDateToUTCToSend,
    prepareMonthPeriod
}