<template>
    <div class="table__row premise-row" @click="handlerClick">
        <div class="table__column premise__building">
            <app-text-wrapper
                :text="buildingName"
                :sub-text="searchSubString"
                :is-match="true"
            />
        </div>
        <div class="table__column premise__entrance">
            {{ entrance }}
        </div>
        <div class="table__column premise__floor">
            {{ floor }}
        </div>
        <div class="table__column premise__type">
            <app-premise-type
                :type="type"
            />
        </div>
        <div class="table__column premise__name">
            <app-text-wrapper
                :text="name"
                :sub-text="searchSubString"
                :is-match="true"
            />
        </div>
    </div>
</template>

<script>
    import AppPremiseType from "../common/local/AppPremiseType";

    export default {
        name: "PremiseRow",

        components: {
            AppPremiseType
        },

        props: {
            id: {
                type: String,
                required: true
            },
            buildingName: {
                type: String,
                required: true
            },
            entrance: {
                type: Number,
                default: null
            },
            floor: {
                type: Number,
                required: true
            },
            type: {
                type: Number,
                required: true
            },
            name: {
                type: String,
                required: true
            }
        },

        inject: {
            subString: {
                default: ''
            }
        },

        data() {
            return {
                searchSubString: this.subString
            }
        },

        methods: {
            handlerClick() {
                this.$emit('update', this.id);
            }
        }
    }
</script>

<style scoped lang="scss">
    .premise-row {
        &:hover {
            cursor: pointer;
        }
    }
</style>