import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const dashboardSystemStatistics = [
    {
        id: '1',
        type: 'electricity',
        buildings: [
            {
                id: '1',
                group: t('common.group'),
                building: `${t('common.building')} 1`,
                parameters: [
                    {
                        value: 14760,
                        difference: 7
                    },
                    {
                        value: 13730,
                        difference: 2
                    }
                ]
            },
            {
                id: '2',
                group: t('common.group'),
                building: `${t('common.building')} 2`,
                parameters: [
                    {
                        value: 11230,
                        difference: -8
                    },
                    {
                        value: 12710,
                        difference: 5
                    }
                ]
            },
            {
                id: '3',
                group: t('common.group'),
                building: `${t('common.building')} 3`,
                parameters: [
                    {
                        value: 10760,
                        difference: 5
                    },
                    {
                        value: 11340,
                        difference: -7
                    }
                ]
            },
            {
                id: '4',
                group: t('common.group'),
                building: `${t('common.building')} 4`,
                parameters: [
                    {
                        value: 12320,
                        difference: -7
                    },
                    {
                        value: 13130,
                        difference: 6
                    }
                ]
            },
            {
                id: '5',
                group: t('common.group'),
                building: `${t('common.building')} 5`,
                parameters: [
                    {
                        value: 10760,
                        difference: 5
                    },
                    {
                        value: 10250,
                        difference: 3
                    }
                ]
            }
        ]
    },
    {
        id: '2',
        type: 'water',
        buildings: [
            {
                id: '1',
                group: t('common.group'),
                building: `${t('common.building')} 1`,
                parameters: [
                    {
                        value: 7820,
                        difference: 2
                    },
                    {
                        value: 7970,
                        difference: 1
                    }
                ]
            },
            {
                id: '2',
                group: t('common.group'),
                building: `${t('common.building')} 2`,
                parameters: [
                    {
                        value: 6200,
                        difference: -6
                    },
                    {
                        value: 6560,
                        difference: -2
                    }
                ]
            },
            {
                id: '3',
                group: t('common.group'),
                building: `${t('common.building')} 3`,
                parameters: [
                    {
                        value: 8720,
                        difference: 6
                    },
                    {
                        value: 8240,
                        difference: 4
                    }
                ]
            },
            {
                id: '4',
                group: t('common.group'),
                building: `${t('common.building')} 4`,
                parameters: [
                    {
                        value: 7560,
                        difference: 3
                    },
                    {
                        value: 7340,
                        difference: 2
                    }
                ]
            },
            {
                id: '5',
                group: t('common.group'),
                building: `${t('common.building')} 5`,
                parameters: [
                    {
                        value: 6540,
                        difference: -1
                    },
                    {
                        value: 6590,
                        difference: -2
                    }
                ]
            }
        ]
    }
]

const dashboardDeviceStates = [
    {
        id: '1',
        building: t('common.buildingName'),
        element: 'water',
        device: t('dashboard.devices.counter'),
        type: 0,
        state: 1
    },
    {
        id: '2',
        building: t('common.buildingName'),
        element: 'balancer',
        device: t('dashboard.devices.counter'),
        type: 2,
        state: 3
    },
    {
        id: '3',
        building: t('common.buildingName'),
        element: 'security',
        device: t('dashboard.devices.security'),
        type: 2,
        state: 4
    },
    {
        id: '4',
        building: t('common.buildingName'),
        element: 'elevator',
        device: t('dashboard.devices.elevator'),
        type: 0,
        state: 0
    },
    {
        id: '5',
        building: t('common.buildingName'),
        element: 'security',
        device: t('dashboard.devices.elevator'),
        type: 1,
        state: 2
    },
    {
        id: '6',
        building: t('common.buildingName'),
        element: 'electricity',
        device: t('dashboard.devices.switchgear'),
        type: 0,
        state: 0
    },
    {
        id: '7',
        building: t('common.buildingName'),
        element: 'water',
        device: t('dashboard.devices.counter'),
        type: 2,
        state: 5
    },
    {
        id: '8',
        building: t('common.buildingName'),
        element: 'elevator',
        device: t('dashboard.devices.elevator'),
        type: 2,
        state: 6
    },
    {
        id: '9',
        building: t('common.buildingName'),
        element: 'electricity',
        device: t('dashboard.devices.counter'),
        type: 2,
        state: 6
    }
]

const dashboardSystemStates = [
    {
        id: '1',
        element: 'security',
        type: 1,
        errorsQty: 15
    },
    {
        id: '2',
        element: 'elevator',
        type: 1,
        errorsQty: 3
    },
    {
        id: '3',
        element: 'electricity',
        type: 0,
        errorsQty: 0
    },
    {
        id: '4',
        element: 'water',
        type: 1,
        errorsQty: 3
    }
]

const dashboardStatisticMonths = [
    {
        id: 0,
        name: t('common.month.current')
    },
    {
        id: 1,
        name: t('common.month.last')
    }
]

export {
    dashboardDeviceStates,
    dashboardSystemStates,
    dashboardSystemStatistics,
    dashboardStatisticMonths
}