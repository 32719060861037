<template>
    <div class="buildings-table building">
        <div class="table__head">
            <div class="table__column building__name">
                <app-text-wrapper
                    :text="$t('buildings.table.name')"
                />
            </div>
            <div class="table__column building__logo">
                <app-text-wrapper
                    :text="$t('buildings.table.logo')"
                />
            </div>
            <div class="table__column building__entrances">
                <app-text-wrapper
                    :text="$t('buildings.table.entrances')"
                />
            </div>
            <div class="table__column building__floors">
                <app-text-wrapper
                    :text="$t('buildings.table.floors')"
                />
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }" @scroll="handlerBodyTableScroll">
            <building-row v-for="(building) in buildings" :key="building.id"
                :id="building.id"
                :name="building.name"
                :logo-url="building.logo"
                :entrances="building.entrances"
                :max-floor="building.maxFloor"
                :floors="building.floors"
                @update="updateBuilding"
            />
            <div v-if="isLoadedMore" class="table__loader">
                <app-loader
                    :size="'medium'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import BuildingRow from "./BuildingRow";

    export default {
        name: "BuildingsTable",

        components: {
            BuildingRow
        },

        props: {
            buildings: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                isLoadedMore: false,
                isNoMore: false
            }
        },

        mounted() {
            this.emitter.on('isLoadedMore', this.isLoadedMoreBuildings);
            this.emitter.on('isNoMore', this.isNoMoreBuildings);
            this.emitter.on('clearNoMore', this.clearNoMoreBuildings);
        },

        unmounted() {
            this.emitter.off('isLoadedMore', this.isLoadedMoreBuildings);
            this.emitter.off('isNoMore', this.isNoMoreBuildings);
            this.emitter.off('clearNoMore', this.clearNoMoreBuildings);
        },

        methods: {
            updateBuilding(id) {
                const building = this.buildings.find((building) => building.id === id);

                this.openModal({
                    name: 'modalBuilding',
                    selectedEl: building
                })
            },

            handlerBodyTableScroll(event) {
                //WE NEED ADDITIONAL OFFSET FOR SCALE WINDOWS(DEVIATION < 1px)
                const OFFSET = 4;

                const scrollHeight = event.target.scrollHeight;
                const clientHeight = event.target.clientHeight;
                const scrollPosition = event.target.scrollTop;

                const canLoadMore = !this.isLoadedMore && !this.isNoMore;

                if (scrollPosition >= scrollHeight - clientHeight - OFFSET && canLoadMore) {
                    this.isLoadedMore = true;
                    this.$emit('loadByScroll');
                }
            },

            isLoadedMoreBuildings() {
                this.isLoadedMore = false;
            },

            isNoMoreBuildings() {
                this.isNoMore = true;
            },

            clearNoMoreBuildings() {
                this.isNoMore = false;
            }
        }
    }
</script>

<style lang="scss">
    .building {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__name {
            width: 30%;
        }

        &__logo {
            width: 40%;

            img {
                max-width: 100%;
                max-height: 35px;
                object-fit: contain;
            }

            &--is-more-16 {
                .building__logo-name {
                    font-size: 16px;
                }
            }

            &--is-more-32 {
                .building__logo-name {
                    line-height: 16px;
                }
            }

            &--is-second-part {
                margin-top: 1px;
            }
        }

        &__logo-name {
            font-size: 28px;
            line-height: 33px;
            word-break: break-all;
            display: flex;
            flex-direction: column;
            justify-content: center;

            div {
                white-space: nowrap;
            }
        }

        &__entrances {
            width: 20%;
        }

        &__floors {
            width: 10%;
        }
    }
</style>
