<template>
    <div class="qty-counter">
        <div v-if="showLabel" class="app__label qty-counter__label">
            {{ label }}
        </div>
        <div class="qty-counter__counter counter"
             :class="{
                'counter--is-disabled': isDisabled
            }"
        >
            <div class="counter__value">
                {{ value }}
            </div>
            <div class="counter__wrapper counter__wrapper--dec">
                <button type="button" class="counter__btn"
                        :class="{
                            'counter__btn--is-disabled': isDisabledDecBtn || isDisabled
                        }"
                        @click="actionCounter('decrement')"
                >
                    <svg width="16" height="3" viewBox="0 0 16 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="1" y1="1.6665" x2="15" y2="1.6665" stroke="#3A3A3A" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                </button>
            </div>
            <div class="counter__wrapper counter__wrapper--inc">
                <button type="button" class="counter__btn"
                        :class="{
                            'counter__btn--is-disabled': isDisabled
                        }"
                        @click="actionCounter('increment')"
                >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.2 7.2H8.80005V0.799951C8.80005 0.358447 8.4416 0 7.99995 0C7.55845 0 7.2 0.358447 7.2 0.799951V7.2H0.799951C0.358447 7.2 0 7.55845 0 7.99995C0 8.4416 0.358447 8.80005 0.799951 8.80005H7.2V15.2C7.2 15.6416 7.55845 16 7.99995 16C8.4416 16 8.80005 15.6416 8.80005 15.2V8.80005H15.2C15.6416 8.80005 16 8.4416 16 7.99995C16 7.55845 15.6416 7.2 15.2 7.2Z" fill="#3A3A3A"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppQuantityCounter",

        props: {
            label: {
                type: String,
                default: ''
            },
            value: {
                type: Number,
                required: true
            },
            minValue: {
                type: Number,
                default: 1
            },
            type: {
                type: String,
                required: true
            },
            isDisabled: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            showLabel() {
                return !!this.label;
            },

            isDisabledDecBtn() {
                return this.value <= this.minValue;
            }
        },

        methods: {
            actionCounter(action) {
                this.$emit('update', this.type, action);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .qty-counter {
        display: flex;
        flex-direction: column;

        &__label {
            white-space: nowrap;
        }

        &__counter {
            width: 100%;
            height: 46px;
            border-radius: 13px;
            border: 1px solid var(--app-border);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background: transparent;
            font-size: 16px;
            line-height: 19px;
            color: var(--app-base-primary);
        }

        /*&--is-disabled {*/
        /*    color: var(--app-base-primary-10);*/
        /*}*/
    }

    .counter {
        &__wrapper {
            position: absolute;
            top: -2px;
            width: 48px;
            min-width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--white);
            border-radius: 13px;

            &--dec {
                left: -1px;
            }

            &--inc {
                right: -1px;
            }
        }

        &__btn {
            background: var(--app-menu-background);
            border: 1px solid var(--app-border);
            border-radius: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;

            &--is-disabled {
                pointer-events: none;
                /*background: var(--app-base-primary-10);*/

                line {
                    stroke-opacity: 0.5;
                }

                path {
                    fill-opacity: 0.5;
                }
            }
        }

        &--is-disabled {
            color: var(--app-base-secondary);
            pointer-events: none;
        }
    }
</style>