<template>
    <div class="base-status" @click.stop="handlerClick">
        <div class="base-status__view"
             :style="{
                'backgroundColor': actualStatus?.background
             }"
        >
            <span>{{ actualStatus?.text }}</span>
        </div>
        <div v-if="isExistErrors" class="base-status__collapse-btn"
             :class="{
                'base-status__collapse-btn--is-active': showErrors
             }"
        >
            <svg class="arrow" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.825 1.8252L5 5.64186L1.175 1.8252L0 3.0002L5 8.0002L10 3.0002L8.825 1.8252Z"/>
            </svg>
        </div>
    </div>
</template>

<script>
    import { getActualStatus } from "../../../constants/statuses";


    export default {
        name: "AppBaseStatus",

        props: {
            showErrors: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: '',
            },
            status: {
                type: [ Boolean, Number ],
                required: true
            },
            errors: {
                type: Array,
                default: []
            }
        },

        computed: {
            isExistErrors() {
                return !!this.errors.length;
            },

            actualStatus() {
                return getActualStatus(this.type, this.status, this.errors);
            }
        },

        methods: {
            handlerClick() {
                if (this.isExistErrors) {
                    this.$emit('showErrors');
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .base-status {
        display: flex;
        align-items: center;

        &__view {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            border-radius: 100px;
            font-size: 12px;
            line-height: 14px;
            padding: 5px 15px;
            color: var(--app-base-primary);
            white-space: nowrap;
        }

        &__collapse-btn {
            width: 24px;
            min-width: 24px;
            height: 24px;
            transition: .25s;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &--is-active {
                transform: scaleY(-1);

                .arrow {
                    path {
                        fill: var(--brand-main);
                    }
                }
            }
        }
    }
</style>

<style lang="scss">
    .arrow {
        path {
            fill: var(--app-base-primary);
        }
    }
</style>