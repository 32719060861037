<template>
    <app-modal-wrapper>
        <form @submit.prevent="doActionElevator" class="modal-elevator">
            <div class="modal-elevator__content">
                <div class="elevator-form">
                    <h4>{{ title }}</h4>
                    <div class="elevator-form__info">
                        <div class="elevator-form__row">
                            <div class="elevator-form__details">
                                <app-base-input
                                    v-model="elevator.name"
                                    :label="$t('elevators.elevator.name.label')"
                                    :place-holder="$t('elevators.elevator.name.ph')"
                                    :error-message="errors.name"
                                />
                            </div>
                            <div class="elevator-form__details">
                                <app-base-input
                                    v-model="elevator.hwId"
                                    :label="$t('elevators.elevator.hwId.label')"
                                    :place-holder="$t('elevators.elevator.hwId.ph')"
                                    :error-message="errors.hwId"
                                />
                            </div>
                        </div>
                        <div class="elevator-form__row">
                            <div class="elevator-form__details">
                                <app-base-select
                                    :label="$t('elevators.elevator.building.label')"
                                    :place-holder="$t('elevators.elevator.building.ph')"
                                    :selected-option="selectedBuilding"
                                    :options="buildings"
                                    :type="'text'"
                                    :error-message="errors.building"
                                    @select="selectBuilding"
                                />
                            </div>
                            <div class="elevator-form__details">
                                <app-base-select ref="floor"
                                    :label="$t('elevators.elevator.floor.label')"
                                    :place-holder="$t('elevators.elevator.floor.ph')"
                                    :selected-option="selectedFloor"
                                    :options="floors"
                                    :type="'text'"
                                    :error-message="errors.floor"
                                    @select="selectFloor"
                                />
                            </div>
                        </div>
                        <div class="elevator-form__row">
                            <div class="elevator-form__details">
                                <app-base-select ref="entrance"
                                    :is-clear="true"
                                    :is-required="false"
                                    :label="$t('elevators.elevator.entrance.label')"
                                    :place-holder="$t('elevators.elevator.entrance.ph')"
                                    :selected-option="selectedEntrance"
                                    :options="entrances"
                                    :type="'text'"
                                    @select="selectEntrance"
                                />
                            </div>
                            <div class="elevator-form__details">
                                <app-base-select ref="premise"
                                    :is-clear="true"
                                    :is-required="false"
                                    :label="$t('elevators.elevator.premise.label')"
                                    :place-holder="$t('elevators.elevator.premise.ph')"
                                    :selected-option="selectedPremise"
                                    :options="premises"
                                    :type="'text'"
                                    @select="selectPremise"
                                />
                            </div>
                        </div>
                        <div class="elevator-form__row">
                            <div class="elevator-form__details">
                                <app-base-select ref="dc"
                                    :label="$t('elevators.elevator.dc.label')"
                                    :place-holder="$t('elevators.elevator.dc.ph')"
                                    :selected-option="selectedDC"
                                    :options="dcs"
                                    :type="'text'"
                                    :error-message="errors.dc"
                                    @select="selectDC"
                                />
                            </div>
                            <div class="elevator-form__details"></div>
                        </div>
                    </div>
                    <div class="elevator-form__info">
                        <div class="elevator-form__row">
                            <div class="elevator-form__details" v-for="channel in channels" :key="channel.id">
                                <elevator-channels
                                    :is-update="isUpdate"
                                    :id="channel.id"
                                    :title="channel.title"
                                    :ports="channel.ports"
                                    :selected-ports="elevator.ports[channel.id]"
                                    @update="updatePorts"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-elevator__control">
                <app-base-btn v-if="isUpdate"
                    :variation="'delete'"
                    :text="$t('common.buttons.delete')"
                    @action="openModalConfirm"
                />
                <app-base-btn
                    :variation="'cancel'"
                    :text="$t('common.buttons.cancel')"
                    @action="closeModalElevator"
                />
                <app-base-btn
                    :type="'submit'"
                    :variation="'save'"
                    :text="$t('common.buttons.save')"
                />
            </div>
        </form>
    </app-modal-wrapper>
</template>

<script>
    import { mapGetters } from "vuex";
    import { greenHubAPI } from "../../../config/api";
    import { elevatorsToastsMixin } from "../../../mixins/toasts/elevators";
    import { elevatorChannels } from "../../../constants/elevators";
    import cloneDeep from "lodash.clonedeep";
    import { prepareQueryFilterParams } from "../../../helpers/api";
    import { clearDependentSelects } from "../../../helpers/selects";
    import { mapEquipmentsDCToSelect } from "../../../api/mappers/equipments";
    import { mapPremisesToSelect } from "../../../api/mappers/premises";
    import { mapElevatorToSend } from "../../../api/mappers/elevators";
    import { validateElevator } from "../../../validations/elevators";
    import ElevatorChannels from "../../elevators/channels/ElevatorChannels";

    export default {
        name: "ModalElevator",

        components: {
            ElevatorChannels
        },

        props: {
            selectedElevator: {
                type: Object
            }
        },

        data() {
            return {
                selectedBuilding: null,
                selectedEntrance: null,
                selectedFloor: null,
                selectedPremise: null,
                selectedDC: null,

                dcs: [],
                premises: [],

                elevator: {
                    name: '',
                    hwId: '',
                    ports: {
                        inputs: null,
                        outputs: null
                    }
                },

                errors: {
                    name: '',
                    hwId: '',
                    building: '',
                    floor: '',
                    dc: ''
                }
            }
        },

        mixins: [ elevatorsToastsMixin ],

        created() {
            this.checkUpdateElevator();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings'
            }),

            isUpdate() {
                return !!this.selectedElevator;
            },

            title() {
                return !this.isUpdate
                    ? this.$t('elevators.elevator.title.create')
                    : this.$t('elevators.elevator.title.edit');
            },

            entrances() {
                return this.selectedBuilding?.entrances || [];
            },

            floors() {
                return this.selectedBuilding?.floors || [];
            },

            channels() {
                return elevatorChannels;
            }
        },

        methods: {
            checkUpdateElevator() {
                if (this.isUpdate) {
                    this.elevator = cloneDeep(this.selectedElevator);

                    this.selectedBuilding = this.buildings.find((building) => building.id === this.elevator.buildingId);
                    this.selectedFloor = this.floors.find((floor) => floor.id === this.elevator.floor);

                    if (this.elevator.entrance) {
                        this.selectedEntrance = this.entrances.find((entrance) => entrance.id === this.elevator.entrance);
                    }

                    this.getAdditionalData();
                }
            },

            doActionElevator() {
                this.emitter.emit('validateChannelPort');
                this.clearElevatorErrors();

                const { isValid, errors } = validateElevator(this.elevator, this.selectedBuilding, this.selectedFloor, this.selectedDC);
                this.errors = errors;

                if (isValid) {
                    if (!this.isUpdate) {
                        this.createElevator();
                    } else {
                        this.changeElevator();
                    }
                }
            },

            createElevator() {
                this.prepareDataFromSelects();
                const elevatorToSend = mapElevatorToSend(this.elevator);

                greenHubAPI.post('/elevators', elevatorToSend)
                    .then(() => {
                        this.updateElevators();
                        this.showCreateElevatorToast();
                    })
            },

            changeElevator() {
                this.prepareDataFromSelects();
                const id = this.elevator.id;
                const elevatorToSend = mapElevatorToSend(this.elevator);

                greenHubAPI.patch(`/elevators/${id}`, elevatorToSend)
                    .then(() => {
                        this.updateElevators();
                    })
            },

            prepareDataFromSelects() {
                this.elevator.buildingId = this.selectedBuilding.id;
                this.elevator.floor = this.selectedFloor.id;
                this.elevator.dcId = this.selectedDC.id;
                //<--------OPTIONAL FIELD-------->//
                this.elevator.entrance = this.selectedEntrance?.id || null;
                this.elevator.premiseId = this.selectedPremise?.id || null;
            },

            updateElevators() {
                this.closeModalElevator();
                this.emitter.emit('updateElevators');
            },

            getAdditionalData() {
                this.getDCs();
                this.getPremises();
            },

            getDCs() {
                const buildingId = this.selectedBuilding.id;

                greenHubAPI.get(`/equipments/${buildingId}`)
                    .then((result) => {
                        this.dcs = mapEquipmentsDCToSelect(result.data.buildingControllers);

                        if (this.isUpdate) {
                            this.selectedDC = this.dcs.find((dc) => dc.id === this.elevator.dcId);
                        }
                    })
            },

            getPremises() {
                const queryFilterParams = {
                    building_id: this.selectedBuilding.id
                }

                const params = prepareQueryFilterParams(queryFilterParams);

                greenHubAPI.get('/premises', params)
                    .then((result) => {
                        const { qty, premises } = result.data;
                        this.premises = mapPremisesToSelect(premises);

                        if (this.isUpdate) {
                            this.selectedPremise = this.premises.find((premise) => premise.id === this.elevator.premiseId);
                        }
                    })
            },

            selectBuilding(building) {
                this.selectedBuilding = building;
                this.clearDependentFields();
                this.getAdditionalData();
            },

            selectEntrance(entrance) {
                this.selectedEntrance = entrance;
            },

            selectFloor(floor) {
                this.selectedFloor = floor;
            },

            selectPremise(premise) {
                this.selectedPremise = premise;
            },

            selectDC(dc) {
                this.selectedDC = dc;
            },

            clearDependentFields() {
                const dependentSelects = {
                    entrance: this.$refs.entrance,
                    floor: this.$refs.floor,
                    premise: this.$refs.premise,
                    dc: this.$refs.dc
                };

                clearDependentSelects(dependentSelects);
            },

            clearElevatorErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            updatePorts(id, ports) {
                this.elevator.ports[id] = ports;
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.elevator.id,
                    name: this.elevator.name,
                    type: 'elevator'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            },

            closeModalElevator() {
                this.closeModal('modalElevator');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-elevator {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;
        max-height: 80vh;
        overflow-y: auto;

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    .elevator-form {
        &__info {
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            &:not(:last-child) {
                padding-bottom: 30px;
                border-bottom: 1px solid var(--app-border);
            }
        }

        &__row {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }
    }
</style>