import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

function getStatus(type, errors) {
    const lastErrors = errors ? errors : [];

    const statuses = {
        online: {
            text: t('common.statuses.success'),
            background: 'var(--system-green-15)'
        },
        offline: {
            text: t('common.statuses.offline'),
            background: 'var(--app-base-primary-15)'
        },
        error: {
            text: getErrorStatusText(lastErrors),
            background: 'var(--system-red-15)'
        }
    }

    return statuses[type];
}

function getErrorStatusText(errors) {
    const qty = errors.length;
    let text = '';

    switch (qty) {
        case 1:
            text = t('common.errors.1');
            break;
        case 2:
        case 3:
        case 4:
            text = t('common.errors.2');
            break;
        default:
            text = t('common.errors.5');
            break;
    }

    return `${qty} ${text}`;
}

function getActualStatus(type, status, errors) {
    let actualStatus = null;

    switch (type) {
        case 'temperature':
            actualStatus = getActualTemperatureStatus(status);
            break;
        case 'parameter':
            actualStatus = getActualParameterStatus(status);
            break;
        case 'balancerInput':
            actualStatus = getActualBalancerInputStatus(status);
            break;
        case 'balancer':
            actualStatus = getActualBalancerStatus(status);
            break;
        case 'securityObject':
            actualStatus = getActualSecurityObjectStatus(status);
            break;
        default:
            actualStatus = getActualDefaultStatus(status, errors);
            break;
    }

    return actualStatus;
}

function getActualDefaultStatus(status, errors) {
    return errors.length
        ? getStatus('error', errors)
        : status
            ? getStatus('online')
            : getStatus('offline')
}

function getActualTemperatureStatus(tempStatus) {
    return temperatureStatuses.find((status) => status.id === tempStatus);
}

function getActualParameterStatus(parameterStatus) {
    return parameterStatuses.find((status) => status.id === parameterStatus);
}

function getActualBalancerInputStatus(balancerInputStatus) {
    return balancerInputStatuses.find((status) => status.id === balancerInputStatus);
}

function getActualBalancerStatus(balancerStatus) {
    return balancerStatuses.find((status) => status.id === balancerStatus);
}

function getActualSecurityObjectStatus(securityObjectStatus) {
    return securityObjectStatuses.find((status) => status.id === securityObjectStatus);
}

const defaultDeviceStatuses = [
    {
        id: 0,
        name: t('common.statuses.offline'),
    },
    {
        id: 1,
        name: t('common.statuses.success'),
    },
    {
        id: 2,
        name: t('common.statuses.errors'),
    }
]

const temperatureStatuses = [
    {
        id: 0,
        text: t('common.statuses.inActive'),
        background: 'var(--app-base-primary-15)'
    },
    {
        id: 1,
        text: t('common.statuses.less'),
        background: 'var(--system-red-15)'
    },
    {
        id: 2,
        text: t('common.statuses.success'),
        background: 'var(--system-green-15)'
    }
]

const parameterStatuses = [
    {
        id: -1,
        text: t('common.statuses.invalid'),
        background: 'var(--app-base-primary-15)'
    }
]

const balancerInputStatuses = [
    {
        id: -1,
        text: t('common.statuses.unknown'),
        background: 'var(--app-base-primary-15)'
    },
    {
        id: 0,
        text: t('common.statuses.offline'),
        background: 'var(--app-base-primary-15)'
    },
    {
        id: 1,
        text: t('common.statuses.success'),
        background: 'var(--system-green-15)'
    },
    {
        id: 2,
        text: t('common.statuses.alarm'),
        background: 'var(--system-red-15)'
    },
    {
        id: 3,
        text: `${t('common.statuses.notLoaded')}`,
        background: 'var(--system-yellow-15)'
    }
]

const balancerStatuses = [
    {
        id: -1,
        text: t('common.statuses.notActivated'),
        background: 'var(--app-base-primary-15)'
    },
    {
        id: 0,
        text: t('common.statuses.success'),
        background: 'var(--system-green-15)'
    },
    {
        id: 1,
        text: t('common.statuses.softBalanced'),
        background: 'var(--system-yellow-15)'
    },
    {
        id: 2,
        text: t('common.statuses.hardBalanced'),
        background: 'var(--system-orange-15)'
    },
    {
        id: 3,
        text: `${t('common.statuses.criticalBalanced')}`,
        background: 'var(--system-red-15)'
    },
    {
        id: 4,
        text: `${t('common.statuses.limitExceeded')}`,
        background: 'var(--system-red-15)'
    }
]

const securityObjectStatuses = [
    {
        id: 0,
        text: t('common.statuses.unknown'),
        background: 'var(--app-base-primary-15)'
    },
    {
        id: 1,
        text: t('common.statuses.opened'),
        background: 'var(--system-orange-15)'
    },
    {
        id: 2,
        text: t('common.statuses.closed'),
        background: 'var(--system-iris-15)'
    }
]

export {
    getActualStatus,
    defaultDeviceStatuses
}