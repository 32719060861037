<template>
    <div class="balancer-input__row table__row"
         :class="{
            'balancer-input__row--is-switchgear': isSwitchgearInput
         }"
    >
        <div class="table__column balancer-monitoring__name">
            <div v-if="!index" class="balancer-monitoring__panel-name"
                 :class="{
                    'balancer-monitoring__panel-name--is-station': !level
                 }"
                 :style="{
                    'paddingLeft': `${40 * level}px`
                }"
            >
                <div class="balancer-monitoring__collapse-btn">
                    <app-collapse-btn v-if="isExistChildren"
                        :default-is-active="true"
                        @collapse="collapseChildren"
                    />
                </div>
                <app-text-wrapper
                    :is-parent="true"
                    :text="name"
                />
            </div>
        </div>
        <div class="table__column balancer-monitoring__input">
            {{ inputName }}
        </div>
        <div class="table__column balancer-monitoring__consumption">
            <app-base-parameter
                :value="consumption"
                :units="$t('common.units.power')"
            />
        </div>
        <div class="table__column balancer-monitoring__nominal-limit">
            <app-base-limit v-if="nominalLimit !== undefined"
                :is-edit-limit="isEditNominalLimits && isSwitchgearInput"
                :type="'nominal'"
                :value="nominalLimit"
                @update="updateBalancerInputLimit"
            />
        </div>
        <div class="table__column balancer-monitoring__accident-limit">
            <app-base-limit v-if="accidentLimit !== undefined"
                :is-edit-limit="isEditAccidentLimits && isSwitchgearInput"
                :type="'accident'"
                :value="accidentLimit"
                @update="updateBalancerInputLimit"
            />
        </div>
        <div class="table__column balancer-monitoring__phase-imbalance">
            <app-base-parameter v-if="imbalance !== undefined"
                :value="imbalance"
                :units="$t('common.units.percent')"
            />
        </div>
        <div class="table__column balancer-monitoring__input-status">
            <app-base-status
                :type="'balancerInput'"
                :status="status"
            />
        </div>
        <div class="table__column balancer-monitoring__balancer-status">
            <app-base-status
                :type="'balancer'"
                :status="balancerStatus"
            />
        </div>
    </div>
</template>

<script>
    import AppBaseLimit from "../../common/local/AppBaseLimit";

    export default {
        name: "BalancerInputRow",

        components: {
            AppBaseLimit
        },

        props: {
            level: {
                type: Number,
                required: true
            },
            isExistChildren: {
                type: Boolean,
                required: true
            },
            index: {
                type: Number,
                required: true
            },
            name: {
                type: String,
                default: ''
            },
            consumption: {
                type: Number,
                required: true
            },
            nominalLimit: {
                type: Number
            },
            accidentLimit: {
                type: Number
            },
            imbalance: {
                type: Number
            },
            status: {
                type: Number,
                required: true
            },
            balancerStatus: {
                type: Number,
                required: true
            },
            errors: {
                type: Array,
                required: true
            },
            isEditNominalLimits: {
                type: Boolean,
                required: true
            },
            isEditAccidentLimits: {
                type: Boolean,
                required: true
            }
        },

        computed: {
            inputName() {
                return `${this.$t('balancer.station.input')} ${this.index + 1}`
            },

            isSwitchgearInput() {
                return this.level === 2;
            }
        },

        methods: {
            collapseChildren() {
                this.$emit('collapse');
            },

            updateBalancerInputLimit(type, isValid, limit) {
                const isNominalLimit = type === 'nominal';

                this.$emit('update', isNominalLimit, this.index, isValid, limit);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .balancer-input {
        display: flex;
        width: 100%;

        &__row {
            background: var(--system-white) !important;

            &--is-switchgear {
                background: var(--app-table-background) !important;
            }
        }
    }
</style>