import { mapActions } from "vuex";

const modalActionsMixin = {
    methods: {
        ...mapActions({
            openModal: 'modals/openModal',
            closeModal: 'modals/closeModal',
            closeOneModal: 'modals/closeOneModal'
        })
    }
}

export {
    modalActionsMixin
}