<template>
    <router-view/>
</template>

<script>
    export default {
        name: 'App',

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        methods: {
            subscribeToEvents() {
                document.addEventListener('keydown', this.handlerKeyEsc);
            },

            unsubscribeFromEvents() {
                document.removeEventListener('keydown', this.handlerKeyEsc);
            },

            handlerKeyEsc(event) {
                if (event.keyCode === 27) {
                    this.closeOneModal();
                }
            }
        }
    }
</script>

