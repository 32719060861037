<template>
    <div class="errors-row">
        <div class="errors-row__error" v-for="(code, index) in errors" :key="index">
            <app-base-error
                :type="type"
                :code="code"
            />
        </div>
        <div v-if="isWaterErrors" class="errors-row__clear-btn">
            <app-text-btn
                :is-not-main-color="true"
                :text="$t('common.buttons.clear')"
                @action="clearErrors"
            />
        </div>
    </div>
</template>

<script>
    import AppBaseError from "../local/AppBaseError";

    export default {
        name: "AppErrorsRow",

        components: {
            AppBaseError
        },

        props: {
            type: {
                type: String,
                required: true
            },
            errors: {
                type: Array,
                required: true
            }
        },

        computed: {
            isWaterErrors() {
                return this.type === 'water';
            }
        },

        methods: {
            clearErrors() {
                this.$emit('clear');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .errors-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        padding: 15px 30px 5px 15px;
        border-bottom: 1px solid var(--app-border);

        &__error, &__clear-btn {
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }
</style>