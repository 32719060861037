<template>
    <app-modal-wrapper>
        <form @submit.prevent="doActionBuilding" class="modal-building" ref="modalBuilding">
            <div class="modal-building__content">
                <div class="building-form">
                    <h4>{{ title }}</h4>
                    <div class="building-form__info">
                        <div class="building-form__details">
                            <div class="building-form__name">
                                <app-base-input
                                    v-model="building.name"
                                    :label="$t('buildings.building.name.label')"
                                    :place-holder="$t('buildings.building.name.ph')"
                                    :error-message="errors.name"
                                />
                            </div>
                            <div class="building-form__entrances">
                                <app-base-input
                                    v-model="building.entrances"
                                    :label="$t('buildings.building.entrances.label')"
                                    :place-holder="$t('buildings.building.entrances.ph')"
                                    :error-message="errors.entrances"
                                />
                            </div>
                            <div class="building-form__floors floors">
                                <div class="app__label">
                                    {{ $t('buildings.building.floors.label') }}
                                </div>
                                <div class="floors__number">
                                    <div class="floors__info">
                                        <div class="floors__details">
                                            <div class="floors__input">
                                                <div class="floors__label">
                                                    {{ $t('buildings.building.floors.from.label') }}
                                                </div>
                                                <app-base-input
                                                    v-model="building.minFloor"
                                                    :place-holder="$t('buildings.building.floors.from.ph')"
                                                    :error-message="errors.minFloor"
                                                />
                                            </div>
                                        </div>
                                        <div class="floors__details">
                                            <div class="floors__input">
                                                <div class="floors__label">
                                                    {{ $t('buildings.building.floors.to.label') }}
                                                </div>
                                                <app-base-input
                                                    v-model="building.maxFloor"
                                                    :place-holder="$t('buildings.building.floors.to.ph')"
                                                    :error-message="errors.maxFloor"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="errors.floors" class="floors__error">
                                        {{ errors.floors }}
                                    </div>
                                </div>
                                <div class="floors__zero">
                                    <app-base-checkbox
                                        v-model="building.isZeroFloor"
                                        :value="'isZeroFloor'"
                                        :label="$t('buildings.building.floors.zeroFloor')"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="building-form__details">
                            <div class="building-form__logo">
                                <app-upload-image
                                    :label="$t('upload.label.mobile')"
                                    :type="'image'"
                                    :accept-types="acceptBuildingLogoTypes"
                                    :url="building.logo"
                                    :preview-type="'building'"
                                    @update="updateBuildingLogo"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-building__control">
                <app-base-btn v-if="isUpdate"
                    :variation="'delete'"
                    :text="$t('common.buttons.delete')"
                    @action="openModalConfirm"
                />
                <app-base-btn
                    :variation="'cancel'"
                    :text="$t('common.buttons.cancel')"
                    @action="closeModalBuilding"
                />
                <app-base-btn
                    :type="'submit'"
                    :variation="'save'"
                    :text="$t('common.buttons.save')"
                />
            </div>
        </form>
    </app-modal-wrapper>
</template>

<script>
    import cloneDeep from "lodash.clonedeep";
    import AppUploadImage from "../../common/local/upload/AppUploadImage";
    import { buildingLogoTypes } from "../../../constants/uploadFileTypes";
    import { buildingsToastsMixin } from "../../../mixins/toasts/buildings";
    import { validateBuilding } from "../../../validations/buildings";
    import { mapBuildingToSend } from "../../../api/mappers/buildings";
    import { greenHubAPI } from "../../../config/api";
    import { mapGetters } from "vuex";

    export default {
        name: "ModalBuilding",

        components: {
            AppUploadImage
        },

        props: {
            selectedBuilding: {
                type: Object
            }
        },

        mixins: [
            buildingsToastsMixin
        ],

        data() {
            return {
                building: {
                    name: '',
                    logo: '',
                    entrances: '',
                    minFloor: '',
                    maxFloor: '',
                    isZeroFloor: true
                },

                logo: {
                    file: '',
                    isChanged: false
                },

                errors: {
                    name: '',
                    floors: '',
                    minFloor: '',
                    maxFloor: '',
                    entrances: ''
                }
            }
        },

        created() {
            this.checkUpdateBuilding();
        },

        computed: {
            isUpdate() {
                return !!this.selectedBuilding;
            },

            title() {
                return !this.isUpdate
                    ? this.$t('buildings.building.title.create')
                    : this.$t('buildings.building.title.edit');
            },

            acceptBuildingLogoTypes() {
                return buildingLogoTypes;
            }
        },

        methods: {
            checkUpdateBuilding() {
                if (this.isUpdate) {
                    this.building = { ...this.selectedBuilding };
                }
            },

            doActionBuilding() {
                this.clearBuildingErrors();
                const { isValid, errors } = validateBuilding(this.building);
                this.errors = errors;

                if (isValid) {
                    if (!this.isUpdate) {
                        this.createBuilding();
                    } else {
                        this.changeBuilding();
                    }
                }
            },

            createBuilding() {
                const buildingToSend = mapBuildingToSend(this.building);

                greenHubAPI.post('/buildings', buildingToSend)
                    .then((result) => {
                        const id = result.data._id;
                        this.showCreateBuildingToast();
                        this.uploadLogo(id);
                    })
            },

            changeBuilding() {
                const id = this.building.id;
                const buildingToSend = mapBuildingToSend(this.building);

                greenHubAPI.patch(`/buildings/${id}`, buildingToSend)
                    .then(() => {
                        this.uploadLogo(id);
                    })
            },

            updateBuildingLogo(image) {
                if (!this.isUpdate) {
                    this.building.logo = image.file;
                } else {
                    this.logo.file = image.file;
                    this.logo.isChanged = image.isChanged;
                }
            },

            uploadLogo(id) {
                if (!this.isUpdate) {
                    if (this.building.logo) {
                        this.sendLogo(id, this.building.logo);
                    } else {
                        this.updateBuildings();
                    }
                } else {
                    if (this.building.logo) {
                        //Logo have already been uploaded
                        if (this.logo.isChanged) {
                            //Avatar has been changed
                            if (this.logo.file) {
                                this.sendLogo(id, this.logo.file);
                            } else {
                                this.deleteLogo(id);
                            }
                        } else {
                            this.updateBuildings();
                        }
                    } else {
                        if (this.logo.file) {
                            this.sendLogo(id, this.logo.file);
                        } else {
                            this.updateBuildings();
                        }
                    }
                }
            },

            deleteLogo(id) {
                greenHubAPI.delete(`/buildings/${id}/logo`)
                    .then(() => {
                        this.updateBuildings();
                    })
            },

            sendLogo(id, logo) {
                const data = new FormData();
                data.append('logo', logo);

                greenHubAPI.post(`/buildings/${id}/logo`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                    .then(() => {
                        this.updateBuildings();
                    })
            },

            updateBuildings() {
                this.closeModalBuilding();
                this.emitter.emit('updateBuildings');
            },

            clearBuildingErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.building.id,
                    name: this.building.name,
                    type: 'building'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            },

            closeModalBuilding() {
                this.closeModal('modalBuilding');
            },
        }
    }
</script>

<style lang="scss" scoped>
    .modal-building {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;
        max-height: 80vh;
        overflow-y: auto;

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    .building-form {
        h4 {
            margin-bottom: 30px;
        }

        &__info {
            display: flex;
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__name, &__entrances {
            margin-bottom: 15px;
        }

        &__logo {
            height: 100%;
        }
    }

    .floors {
        &__info {
            display: flex;
            align-items: center;
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__input {
            position: relative;
        }

        &__label {
            position: absolute;
            top: 12px;
            left: 15px;
            z-index: 10;
            height: 24px;
            width: 44px;
            border-right: 1px solid var(--app-border);
            font-size: 16px;
            line-height: 24px;
        }

        &__number {
            margin-bottom: 15px;
        }

        &__error {
            margin-top: 10px;
            font-size: 14px;
            color: var(--system-red);
        }
    }
</style>

<style lang="scss">
    .floors__input {
        .field__entry {
            input {
                padding-left: 70px !important;
            }
        }
    }
</style>
