<template>
    <div class="elevator-channels">
        <div class="elevator-channels__label app__label">
            {{ title }}
        </div>
        <div class="elevator-channels__port" v-for="port in ports" :key="port.id">
            <elevator-channels-port
                :is-update="isUpdate"
                :id="port.id"
                :name="port.name"
                :pins="pins"
                :selected-pins="selectedPinsForPorts"
                @update="updatePins"
            />
        </div>
    </div>
</template>

<script>
    import ElevatorChannelsPort from "./ElevatorChannelsPort";
    import { generatePinsForChannelType } from "../../../constants/elevators";

    export default {
        name: "ElevatorChannels",

        components: {
            ElevatorChannelsPort
        },

        props: {
            isUpdate: {
                type: Boolean,
                required: true
            },
            id: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            ports: {
                type: Array,
                required: true
            },
            selectedPorts: {
                type: [ Object, null ],
                required: true
            }
        },

        data() {
            return {
                pins: [],
                selectedPinsForPorts: null
            }
        },

        created() {
            this.init();
        },

        methods: {
            init() {
                this.pins = generatePinsForChannelType(9);
                this.checkUpdateElevator();
            },

            checkUpdateElevator() {
                if (this.isUpdate) {
                    this.selectedPinsForPorts = { ...this.selectedPorts };
                } else {
                    this.selectedPinsForPorts = this.ports.reduce((acc, port) => {
                        acc[port.id] = null;
                        return acc;
                    }, {});
                }
            },

            updatePins(id, selectedPin) {
                this.selectedPinsForPorts[id] = selectedPin ? selectedPin.id : null;
                this.$emit('update', this.id, this.selectedPinsForPorts);
            }
        }
    }
</script>

<style lang="scss">
    .elevator-channels {
        &__port:not(:last-child) {
            margin-bottom: 15px;
        }
    }
</style>