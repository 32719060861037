<template>
    <button type="button" class="circle-btn" @click.stop="handlerClick"
        :class="{
            'circle-btn--is-transparent': isTransparent,
            'circle-btn--is-delete': isDelete,
            'circle-btn--is-disabled': isDisabled
        }"
    >
        <slot/>
    </button>
</template>

<script>
    export default {
        name: "AppCircleBtn",

        props: {
            isTransparent: {
                type: Boolean,
                default: false
            },
            isDelete: {
                type: Boolean,
                default: false
            },
            isDisabled: {
                type: Boolean,
                default: false
            }
        },

        methods: {
            handlerClick() {
                this.$emit('action');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .circle-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        min-width: 30px;
        height: 30px;
        min-height: 30px;
        border-radius: 100px;
        transition: 0.3s;
        background: var(--app-btn-secondary);

        &:hover {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), var(--app-btn-secondary);
        }

        &--is-transparent {
            background: transparent;

            &:hover {
                background: transparent;
            }
        }

        &--is-delete {
            background: var(--system-red-10);

            &:hover {
                background: var(--system-red-20);
            }
        }

        &--is-disabled {
            pointer-events: none;
            background: var(--app-base-primary-10);
        }
    }
</style>