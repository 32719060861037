function mapBalancerEquipmentsToView(equipments) {
    return equipments.map((equipment) => {
       return {
           id: equipment._id,
           buildingId: equipment.building_id,
           buildingName: equipment.building_name,
           floor: equipment.floor,
           entrance: equipment.entrance || null,
           premiseId: equipment.premise_id,
           premiseName: equipment.premise_name,
           premiseType: equipment.premise_type,
           switchgearId: equipment.switchgear_id,
           inputNumber: equipment.input_no,
           parentSwId: equipment.dc_sw_id,
           boiler: {
               hwId: equipment.hw_id,
               model: equipment.model,
               currentPower: equipment.current_power,
               maxPower: equipment.max_power,
               limit: equipment.percent
           },
           tempStatus: equipment.temperature,
           status: equipment.status,
           errors: equipment.devErrors,
       }
    })
}

function mapBalancerStationsToView(stations) {
    return stations.map((station) => {
        const inputs = [ station.input1, station.input2 ];

        return {
            id: station._id,
            name: station.name,
            inputs: mapBalancerInputsToView(inputs),
            children: mapBalancerBuildingInputs(station.buildings)
        }
    })
}

function mapBalancerBuildingInputs(buildings) {
    return buildings.map((building) => {
        const inputs = [ building.input1, building.input2 ];

        return {
            id: building.building_id,
            name: building.building_name,
            inputs: mapBalancerInputsToView(inputs),
            children: mapBalancerSwitchgearInputs(building.switchgears)
        }
    })
}

function mapBalancerSwitchgearInputs(switchgears) {
    return switchgears.map((switchgear) => {
        const inputs = [ switchgear.input1, switchgear.input2 ];

        return {
            id: switchgear.switchgear_id,
            name: switchgear.switchgear_name,
            inputs: mapBalancerInputsToView(inputs),
        }
    })
}

function mapBalancerInputsToView(inputs) {
    return inputs.map((input) => {
        return {
            consumption: input.consumption,
            nominalLimit: input.limit_nominal,
            isValidNominalLimit: false,
            accidentLimit: input.limit_accident,
            isValidAccidentLimit: false,
            imbalance: input.imbalance,
            status: input.status,
            balancerStatus: input.balance_status,
            errors: input.input_errors
        }
    })
}

function mapBalancerEquipmentToSend(equipment) {
    return {
        building_id: equipment.buildingId,
        premise_id: equipment.premiseId,
        switchgear_id: equipment.switchgearId,
        input_no: equipment.inputNumber,
        hw_id: equipment.boiler.hwId,
        dc_sw_id: equipment.parentSwId,
        max_power: equipment.boiler.maxPower
    }
}

function mapBalancerStationToSend(station) {
    return {
        name: station.name,
        buildings: station.buildings.map((building) => {
            return {
                building_id: building.id
            }
        })
    }
}

function getAllBalancerSwitchgears(stations) {
    return stations.reduce((acc, station) => {
        return station.children.reduce((acc, building) => {
            return building.children.reduce((acc, switchgear) => {
                acc.push(switchgear);
                return acc;
            }, acc)
        }, acc)
    }, []);
}

function mapBalancerInputLimitsToSend(stations, isNominalLimits) {
    const switchgears = getAllBalancerSwitchgears(stations);

    return {
        transformers: stations.map((station) => mapBalancerLimitsToSend(station.id, station.inputs, isNominalLimits)),
        switchgears: switchgears.map((switchgear) => mapBalancerLimitsToSend(switchgear.id, switchgear.inputs, isNominalLimits))
    }
}

function mapBalancerLimitsToSend(id, inputs, isNominalLimits) {
    const [ input1, input2 ] = [ ...inputs ];

    return {
        _id: id,
        input1: isNominalLimits? input1.nominalLimit : input1.accidentLimit,
        input2: isNominalLimits? input2.nominalLimit : input2.accidentLimit,
    }
}

function getAllBalancerInputsForValidation(stations) {
    const switchgears = getAllBalancerSwitchgears(stations);

    const stationInputs = stations.map((station) => station.inputs).flat();
    const switchgearInputs = switchgears.map((switchgear) => switchgear.inputs).flat();

    return switchgearInputs.concat(stationInputs);
}

export {
    mapBalancerEquipmentsToView,
    mapBalancerStationsToView,
    mapBalancerEquipmentToSend,
    mapBalancerStationToSend,
    mapBalancerInputLimitsToSend,
    getAllBalancerInputsForValidation
}