<template>
    <div class="water-filters filters">
        <div class="filters__control">
            <div class="filters__building filters__element">
                <app-base-select ref="building"
                    :selected-option="selectedBuilding"
                    :options="buildings"
                    :place-holder="$t('filters.buildings.ph')"
                    :type="'filter'"
                    @select="selectBuilding"
                />
            </div>
            <div class="filters__filtering filters__element">
                <app-base-filters
                    :qty="qty"
                    :filters="filters"
                    :active-filters="activeFilters"
                    @update="updateActiveFilters"
                />
            </div>
            <div class="filters__searching filters__element">
                <app-base-search
                    v-model="search.currentValue"
                    :qty="qty"
                    :show-qty="search.showQty"
                    @update="updateBySearch"
                    @clear="clearAndUpdateSearch"
                />
            </div>
            <div class="filters__view filters__element">
                <app-base-select-view
                    @update="updatePeriod"
                />
            </div>
        </div>
        <div v-if="showFilterPanel" class="filters__panel">
            <div class="filters__results">
                {{ qtyResults }}
            </div>
            <div v-for="filter in modActiveFilters" :key="filter.id">
                <app-filter-active-item class="filters__active-element"
                    :id="filter.id"
                    :name="filter.name"
                    :filter-key="filter.key"
                    @remove="removeActiveFilter"
                />
            </div>
            <div class="filters__reset-btn">
                <app-text-btn
                    :text="$t('filters.clear')"
                    @action="clearAndUpdateActiveFilters"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import AppFilterActiveItem from "../filters/AppFilterActiveItem";
    import { mapGetters, mapActions } from "vuex";
    import cloneDeep from "lodash.clonedeep";
    import { getResultText, mapActiveFiltersToString } from "../../constants/filters";

    export default {
        name: "WaterFilters",

        components: {
            AppFilterActiveItem
        },

        props: {
            qty: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                selectedBuilding: null,

                search: {
                    currentValue: '',
                    updatedValue: '',
                    showQty: false
                },
                activeFilters: {
                    buildingEntrances: [],
                    buildingFloors: [],
                    deviceStatuses: []
                },
                modActiveFilters: [],
                showFilterPanel: false,

                selectedPeriod: null,
            }
        },

        mounted() {
            //TODO: MAYBE SOMETHING ELSE
            this.selectBuilding(this.buildings[0]);

            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        watch: {
            isLoaded: {
                immediate: false,
                handler() {
                    this.selectBuilding(this.buildings[0]);
                    this.$refs.building.initSelect();
                },
            },
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings',
                isLoaded: 'buildings/isLoadedBuildings',
                filters: 'filters/waterFilters',
            }),

            qtyResults() {
                return `${this.qty} ${getResultText(this.qty)}`;
            }
        },

        methods: {
            ...mapActions({
                addFilter: 'filters/addFilter',
            }),

            subscribeToEvents() {
                this.emitter.on('updateShowQty', this.checkShowQty);
                this.emitter.on('clearQueryParams', this.clearQueryParams);
                this.emitter.on('updateFilters', this.updateFilters);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateShowQty', this.checkShowQty);
                this.emitter.off('clearQueryParams', this.clearQueryParams);
                this.emitter.off('updateFilters', this.updateFilters);
            },

            selectBuilding(building) {
                this.selectedBuilding = building;

                if (this.selectedBuilding) {
                    this.updateQueryId();
                    this.mapAndSetAdditionalBuildingFilters();
                }
            },

            mapAndSetAdditionalBuildingFilters() {
                const entrances = cloneDeep(this.selectedBuilding.entrances);
                const floors = cloneDeep(this.selectedBuilding.floors);

                this.addFilter({
                    buildingEntrances: entrances.map((entrance) => {
                        entrance.name = `${entrance.name} ${this.$t('filters.entrance')}`;
                        return entrance;
                    })
                });

                this.addFilter({
                    buildingFloors: floors.map((floor) => {
                        floor.name = `${floor.name} ${this.$t('filters.floor')}`;
                        return floor;
                    })
                });
            },

            updateQueryId() {
                this.$emit('updateQueryId', this.selectedBuilding.id);
            },

            updateBySearch() {
                this.search.updatedValue = this.search.currentValue;
                this.updateQueryParams();
            },

            clearQueryParams() {
                this.clearSearch();
                this.clearActiveFilters();
                this.updateQueryParams();
            },

            clearAndUpdateSearch() {
                this.clearSearch();
                this.updateQueryParams();
            },

            clearSearch() {
                this.search.updatedValue = '';
                this.search.currentValue = '';
                this.checkShowQty();
            },

            checkShowQty() {
                this.search.showQty = !!this.search.updatedValue;
            },

            updateFilters() {
                this.updateQueryParams();
            },

            clearActiveFilters() {
                const activeFiltersKeys = Object.keys(this.activeFilters);

                activeFiltersKeys.forEach((key) => {
                    this.activeFilters[key].length = 0;
                });

                // this.showFilterPanel = false;
                this.checkShowFiltersPanel();
            },

            clearAndUpdateActiveFilters() {
                this.clearActiveFilters();
                this.updateQueryParams();
            },

            removeActiveFilter(key, id) {
                this.activeFilters[key] = this.activeFilters[key].filter((elementId) => elementId !== id);
                this.checkShowFiltersPanel();
                this.updateQueryParams();
            },

            checkShowFiltersPanel() {
                const activeFiltersKeys = Object.keys(this.activeFilters);
                this.modActiveFilters = [];

                activeFiltersKeys.forEach((key) => {
                    this.activeFilters[key].forEach((id) => {
                        const targetFilter = this.filters[key].find((filter) => filter.id === id);
                        const filter = { ...targetFilter };
                        filter.key = key;
                        this.modActiveFilters.push(filter);
                    })
                })

                this.showFilterPanel = this.modActiveFilters.length;
            },

            updateActiveFilters(name, activeFilters) {
                this.activeFilters[name] = activeFilters;
                //TODO: SEVERAL TIMES I CHECK SHOW PANEL
                this.checkShowFiltersPanel();
            },

            updatePeriod(period) {
                this.selectedPeriod = period;
                this.$emit('updateQueryType', period.id);
                this.updateQueryParams();
            },

            updateQueryParams() {
                let params = null;
                const match = this.search.updatedValue;
                const filters = this.modActiveFilters.length;
                const isSelectPeriod = this.selectedPeriod && this.selectedPeriod.id !== 'online';

                if (match || filters || isSelectPeriod) {
                    params = {};

                    if (filters) {
                        params.filter =  mapActiveFiltersToString(this.activeFilters);
                    }

                    if (match) {
                        params.match = match;
                    }

                    if (isSelectPeriod) {
                        params.startPeriod = this.selectedPeriod.startPeriod;
                        params.endPeriod = this.selectedPeriod.endPeriod;
                    }
                }

                this.$emit('updateQueryParams', params);
            }
        }
    }
</script>