<template>
    <div class="select"
         :class="{
            'select--is-disabled': isDisabled,
            'select--is-error': isError
         }"
    >
        <div v-if="showLabel" class="app__label">
            {{ label }}
            <span class="select__optional">
                {{ optionalText }}
            </span>
        </div>
        <div class="select__wrapper">
            <VueMultiselect
                :class="{
                    'is-language': isLanguageSelect,
                    'is-text': isTextSelect,
                    'is-filter': isSortOrFilterSelect,
                    'is-pin': isPinSelect
                }"
                v-model="selectedValue"
                :options="options"
                :searchable="isSearchable"
                :openDirection="openDirection"
                :placeholder="placeHolder"
                :show-labels="false"
                :allow-empty="false"
                :label="labelType"
                :track-by="labelType"
                @select="selectOption"
                @open="focusSelect"
                @close="closeSelect"
            >
                <template v-slot:singleLabel="{ option }">
                    <div class="select__option">
                        <div class="select-option__text can-overflow">
                            {{ option.name }}
                        </div>
                    </div>
                </template>
                <template v-slot:option="{ option }">
                    <div class="select__option">
                        <div class="select-option__text can-overflow">
                            {{ option.name }}
                        </div>
                    </div>
                </template>
                <template v-slot:noResult>
                    {{ $t('filters.noResult') }}
                </template>
                <template v-slot:noOptions>
                    {{ $t('filters.noData') }}
                </template>
            </VueMultiselect>
            <div v-if="selectedValue && isClear" class="select__clear" :class="{ 'select__clear--is-pin': isPinSelect }">
                <button type="button" @click="clearSelectedOption">
                    <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.875 1.50625L7.99375 0.625L4.5 4.11875L1.00625 0.625L0.125 1.50625L3.61875 5L0.125 8.49375L1.00625 9.375L4.5 5.88125L7.99375 9.375L8.875 8.49375L5.38125 5L8.875 1.50625Z" fill="#A4AFBE"/>
                    </svg>
                </button>
            </div>
        </div>
        <div v-if="showError" class="select__error">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
    import cloneDeep from "lodash.clonedeep";
    import VueMultiselect from 'vue-multiselect';
    import "vue-multiselect/dist/vue-multiselect.css";
    import { filtersText } from "../../../constants/filters";

    export default {
        name: "AppBaseSelect",

        components: {
            VueMultiselect
        },

        props: {
            label: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: 'select'
            },
            isSearchable: {
                type: Boolean,
                default: false
            },
            placeHolder: {
                type: String,
                default: ''
            },
            openDirection: {
                type: String,
                default: 'bottom',
                validator(direction) {
                    return ['top', 'bottom'].includes(direction);
                }
            },
            selectedOption: {
                type: Object
            },
            options: {
                type: Array,
                required: true
            },
            type: {
                type: String,
                default: 'text',
                validator(type) {
                    return ['text', 'language', 'filter', 'pin'].includes(type);
                }
            },
            errorMessage: {
                type: String,
                default: ''
            },
            isDisabled: {
                type: Boolean,
                default: false
            },
            isRequired: {
                type: Boolean,
                default: true
            },
            isClear: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                selectedValue: null,
            }
        },

        created() {
            this.initSelect();
        },

        //Need to watch because we get some data from backend
        watch: {
            selectedOption(option) {
                if (option && !this.isFirstInit) {
                    this.initSelect();
                    this.isFirstInit = true;
                }
            },
        },

        computed: {
            isError() {
                return !!this.errorMessage;
            },

            showError() {
                return !!this.errorMessage && this.errorMessage !== 'isNotShowError';
            },

            showLabel() {
                return !!this.label;
            },

            isTextSelect() {
                return this.type === 'text';
            },

            isLanguageSelect() {
                return this.type === 'language';
            },

            isSortOrFilterSelect() {
                return this.type === 'filter';
            },

            isPinSelect() {
                return this.type === 'pin';
            },

            labelType() {
                return 'name';
            },

            optionalText() {
                return this.isRequired ? '' : ` ${this.$t('common.optional')}`;
            }
        },

        methods: {
            initSelect() {
                if (this.selectedOption) {
                    this.selectedValue = cloneDeep(this.selectedOption);
                }
            },

            selectOption(option) {
                this.$emit('select', option);
            },

            clearSelectedOption() {
                this.selectedValue = null;
                this.$emit('select', null);
            },

            focusSelect() {
                this.$emit('focus', this.name);
            },

            closeSelect() {
                this.$emit('close', this.name);
            }
        }
    }
</script>

<style lang="scss">
    .select-option {
        &__text {
            max-width: 450px;
        }
    }

    .select {
        position: relative;
        width: 100%;

        &__wrapper {
            position: relative;
        }

        &__clear {
            position: absolute;
            right: 32px;
            top: 11px;
            background: transparent;
            height: 24px;
            border-right: 1px solid var(--app-border);

            button {
                padding-right: 10px;
            }

            &--is-pin {
                height: 20px;
                top: 9px;

                button {
                    padding-right: 8px;
                }
            }
        }

        &__optional {
            font-size: 12px;
            font-style: italic;
        }

        .is-filter {
            .multiselect__select {
                height: 44px;
            }

            .multiselect__tags {
                border: 1px solid var(--app-border);
                padding: 12px 40px 0 8px;
            }

            .multiselect__content-wrapper {
                background: var(--system-white);
                box-shadow: 0 16px 32px rgba(35, 23, 5, 0.26);
                border-radius: 13px;
                border: none;
                margin-top: 10px;
                padding: 5px 0;
            }

            .multiselect--active {
                .multiselect__tags {
                    border-color: var(--brand-main) !important;
                }
            }

            .select-option__text {
                max-width: 250px;
            }

            .multiselect__single {
                .option__text {
                    max-width: 220px;
                }
            }
        }

        .is-text {
            .multiselect__select {
                height: 44px;
            }

            .multiselect__tags {
                border: 1px solid var(--app-border);
                padding: 12px 40px 0 8px;
            }

            .multiselect__content-wrapper {
                background: var(--system-white);
                box-shadow: 0 16px 32px rgba(35, 23, 5, 0.26);
                border-radius: 13px;
                border: none;
                margin: 10px 0;
                padding: 5px 0;
                max-height: 160px !important;
            }

            .multiselect--active {
                .multiselect__tags {
                    border-color: var(--brand-main) !important;
                }
            }
        }

        .is-language {
            .multiselect__single {
                font-weight: 500;
                background: transparent;
                color: var(--app-base-primary);
            }

            .multiselect__tags {
                padding: 12px 35px 0 10px;
                border: none;
                background: transparent;
                box-shadow: none;

            }

            .multiselect__option {
                padding: 8px 13px;
                min-height: unset;
            }

            .multiselect__content-wrapper {
                border-radius: 13px;
            }

            .multiselect__select {
                height: 40px;
            }

            .multiselect__select:before {
                border-color: var(--app-base-primary) transparent transparent transparent;
            }
        }

        .is-pin {
            .multiselect__tags {
                padding: 10px 35px 0 6px;
                border: none;
                background: var(--system-white);
                box-shadow: none;
                width: 100px;
                min-height: 40px !important;
            }

            .multiselect__content-wrapper {
                border-radius: 13px;
                border: none;
                max-height: 160px !important;
            }

            .multiselect__placeholder {
                margin-top: 0;
                padding-top: 0;
            }
        }


        &--is-disabled {
            pointer-events: none;

            .app__label, .multiselect__single, .multiselect__placeholder {
                opacity: 0.5 !important;
            }
        }

        &--is-error {
            .multiselect__tags {
                border: 1px solid var(--system-red) !important;
            }
        }

        &__error {
            margin-top: 10px;
            font-size: 14px;
            color: var(--system-red);
        }
    }
</style>