function mapElevatorsToView(elevators) {
    return elevators.map((elevator) => {
        return {
            id: elevator._id,
            name: elevator.name,
            hwId: elevator.hw_id,
            buildingId: elevator.building_id,
            buildingName: elevator.building_name,
            floor: elevator.floor,
            dcId: elevator.dc_sw_id,
            entrance: elevator.entrance || null,
            premiseId: elevator.premise_id || null,
            premiseName: elevator.premise_id ? elevator.premise_name : '',
            ports: {
                inputs: {
                    rbf: elevator.rbf,
                    rea: elevator.rea,
                    rib: elevator.rib,
                    rsm: elevator.rsm,
                    rab: elevator.rab,
                    refe: elevator.refe
                },
                outputs: {
                    floor1: elevator.floor1,
                    floor2: elevator.floor2,
                    floor3: elevator.floor3,
                    floor4: elevator.floor4,
                    restart: elevator.restart,
                    ctl: elevator.ctl
                }
            },
            status: elevator.status,
            errors: elevator.devErrors || [],
        }
    })
}

function mapElevatorToSend(elevator) {
    let elevatorToSend = {
        name: elevator.name,
        hw_id: elevator.hwId,
        building_id: elevator.buildingId,
        floor: elevator.floor,
        dc_sw_id: elevator.dcId,
        entrance: elevator.entrance,
        premise_id: elevator.premiseId
    }

    elevatorToSend = { ...elevatorToSend, ...elevator.ports.inputs,  ...elevator.ports.outputs };

    return elevatorToSend;
}

export {
    mapElevatorsToView,
    mapElevatorToSend
}