<template>
    <div class="electricity__table">
        <div class="table__head">
            <div class="table__column switchgear-counter__name">
                <app-text-wrapper
                    :text="$t('electricity.table.name')"
                />
            </div>
            <div class="table__column switchgear-counter__entrance">
                <app-text-wrapper
                    :text="$t('electricity.table.entrance')"
                />
            </div>
            <div class="table__column switchgear-counter__floor">
                <app-text-wrapper
                    :text="$t('electricity.table.floor')"
                />
            </div>
            <div class="table__column switchgear-counter__premise">
                <app-text-wrapper
                    :text="$t('electricity.table.premise')"
                />
            </div>
            <div class="table__column switchgear-counter__input-number">
                <app-text-wrapper
                    :text="$t('electricity.table.inputNumber')"
                />
            </div>
            <div class="table__column switchgear-counter__hwId">
                <app-text-wrapper
                    :text="$t('electricity.table.hwId')"
                />
            </div>
            <div class="table__column switchgear-counter__model">
                <app-text-wrapper
                    :text="$t('electricity.table.model')"
                />
            </div>
            <div class="table__column switchgear-counter__data">
                <div v-if="isOnline" class="switchgear-counter__data-view">
                    <div class="table__column switchgear-counter__readings">
                        <app-text-wrapper
                            :text="$t('electricity.table.readings')"
                        />
                    </div>
                    <div class="table__column switchgear-counter__consumptions">
                        <app-text-wrapper
                            :text="$t('electricity.table.consumptions')"
                        />
                    </div>
                </div>
                <div v-else class="switchgear-counter__data-view">
                    <div class="table__column switchgear-counter__consumpted">
                        <app-text-wrapper
                            :text="$t('electricity.table.consumpted')"
                        />
                    </div>
                    <div class="table__column switchgear-counter__imbalance">
                        <app-text-wrapper
                            :text="$t('electricity.table.imbalance')"
                        />
                    </div>
                </div>
            </div>
            <div class="table__column switchgear-counter__status">
                <app-text-wrapper
                    :text="$t('electricity.table.status')"
                />
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }">
            <switchgear-counter-row v-for="switchgear in switchgears" :key="switchgear.id"
                :is-online="isOnline"
                :level="0"
                :id="switchgear.id"
                :name="switchgear.name"
                :floor="switchgear.floor"
                :entrance="switchgear.entrance"
                :premise-name="switchgear.premiseName"
                :children="switchgear.children || []"
                @update="updateSwitchgear"
            />
        </div>
    </div>
</template>

<script>
    import SwitchgearCounterRow from "./elements/SwitchgearCounterRow";

    export default {
        name: "WaterTable",

        components: {
            SwitchgearCounterRow
        },

        props: {
            switchgears: {
                type: Array,
                required: true
            },
            isOnline: {
                type: Boolean,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            }
        },

        methods: {
            updateSwitchgear(id) {
                const switchgear = this.switchgears.find((switchgear) => switchgear.id === id);

                this.openModal({
                    name: 'modalElectricitySwitchgear',
                    selectedEl: switchgear
                })
            }
        }
    }
</script>