<template>
    <div class="water-pads__table">
        <div class="table__head">
            <div class="table__column water-pad__name">
                <app-text-wrapper
                    :text="$t('water.pad.table.name')"
                />
            </div>
            <div class="table__column water-pad__entrance">
                <app-text-wrapper
                    :text="$t('water.pad.table.entrance')"
                />
            </div>
            <div class="table__column water-pad__floor">
                <app-text-wrapper
                    :text="$t('water.pad.table.floor')"
                />
            </div>
            <div class="table__column water-pad__premise">
                <app-text-wrapper
                    :text="$t('water.pad.table.premise')"
                />
            </div>
            <div class="table__column water-pad__counterId">
                <app-text-wrapper
                    :text="$t('water.pad.table.counterId')"
                />
            </div>
            <div class="table__column water-pad__hwId">
                <app-text-wrapper
                    :text="$t('water.pad.table.hwId')"
                />
            </div>
            <div class="table__column water-pad__model">
                <app-text-wrapper
                    :text="$t('water.pad.table.model')"
                />
            </div>
            <div class="table__column water-pad__data">
                <div v-if="isOnline" class="water-pad__data-view">
                    <div class="table__column water-pad__readings">
                        <app-text-wrapper
                            :text="$t('water.pad.table.readings')"
                        />
                    </div>
                    <div class="table__column water-pad__consumptions">
                        <app-text-wrapper
                            :text="$t('water.pad.table.consumptions')"
                        />
                    </div>
                </div>
                <div v-else class="water-pad__data-view">
                    <div class="table__column water-pad__consumpted">
                        <app-text-wrapper
                            :text="$t('water.pad.table.consumpted')"
                        />
                    </div>
                    <div class="table__column water-pad__imbalance">
                        <app-text-wrapper
                            :text="$t('water.pad.table.imbalance')"
                        />
                    </div>
                </div>
            </div>
            <div class="table__column water-pad__status">
                <app-text-wrapper
                    :text="$t('water.pad.table.status')"
                />
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }">
            <water-pad-row v-for="(pad,index) in pads" :key="pad.id"
                :index="index"
                :length="pads.length"
                :is-online="isOnline"
                :level="0"
                :id="pad.id"
                :name="pad.name"
                :floor="pad.floor"
                :entrance="pad.entrance"
                :premise-name="pad.premiseName"
                :counter-id="pad.counterId"
                :hw-id="pad.hwId"
                :model="pad.model"
                :type="pad.type"
                :readings="pad.readings"
                :consumptions="pad.consumptions"
                :consumpted="pad.consumpted"
                :imbalance="pad.imbalance"
                :children="pad.children || []"
                :status="pad.status"
                :errors="pad.errors"
                @update="updateWaterPad"
            />
        </div>
    </div>
</template>

<script>
    import WaterPadRow from "./elements/WaterPadRow";

    export default {
        name: "WaterTable",

        components: {
            WaterPadRow
        },

        props: {
            pads: {
                type: Array,
                required: true
            },
            isOnline: {
                type: Boolean,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            }
        },

        methods: {
            updateWaterPad(id) {
                const pad = this.pads.find((pad) => pad.id === id);

                this.openModal({
                    name: 'modalWaterPad',
                    selectedEl: pad
                })
            },
        }
    }
</script>