<template>
    <div class="elevator-row">
        <div class="table__row elevator-row__entry" @click.stop="handlerClickElevator">
            <div class="table__column elevator__name">
                <app-text-wrapper
                    :text="name"
                    :sub-text="searchSubString"
                    :is-match="true"
                />
            </div>
            <div class="table__column elevator__hwId">
                <app-text-wrapper
                    :text="hwId"
                    :sub-text="searchSubString"
                    :is-match="true"
                />
            </div>
            <div class="table__column elevator__premise">
                <app-text-wrapper
                    :text="premiseName"
                    :sub-text="searchSubString"
                    :is-match="true"
                />
            </div>
            <div class="table__column elevator__building">
                <app-text-wrapper
                    :text="buildingName"
                    :sub-text="searchSubString"
                    :is-match="true"
                />
            </div>
            <div class="table__column elevator__entrance">
                {{ entrance }}
            </div>
            <div class="table__column elevator__floor">
                {{ floor }}
            </div>
            <div class="table__column elevator__actions">
                <div class="elevator__action-control" ref="actionControlPanel">
                    <app-circle-btn
                        :is-disabled="!status"
                        @action="toggleActionPanel"
                    >
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.99992 4.66732C7.64159 4.66732 8.16659 4.14232 8.16659 3.50065C8.16659 2.85898 7.64159 2.33398 6.99992 2.33398C6.35825 2.33398 5.83325 2.85898 5.83325 3.50065C5.83325 4.14232 6.35825 4.66732 6.99992 4.66732ZM6.99992 5.83398C6.35825 5.83398 5.83325 6.35898 5.83325 7.00065C5.83325 7.64232 6.35825 8.16732 6.99992 8.16732C7.64159 8.16732 8.16659 7.64232 8.16659 7.00065C8.16659 6.35898 7.64159 5.83398 6.99992 5.83398ZM6.99992 9.33398C6.35825 9.33398 5.83325 9.85898 5.83325 10.5007C5.83325 11.1423 6.35825 11.6673 6.99992 11.6673C7.64159 11.6673 8.16659 11.1423 8.16659 10.5007C8.16659 9.85898 7.64159 9.33398 6.99992 9.33398Z" fill="#3A3A3A"/>
                        </svg>
                    </app-circle-btn>
                    <div v-if="showActionPanel" class="elevator__action-wrapper">
                        <app-action-btn
                            :id="'restart'"
                            :text="$t('elevators.actions.restart')"
                            @action="handlerClickActionBtn"
                        />
                        <app-action-btn
                            :id="'send'"
                            :text="$t('elevators.actions.send')"
                            @action="handlerClickActionBtn"
                        />
                    </div>
                </div>
            </div>
            <div class="table__column elevator__status">
                <app-base-status
                    :show-errors="showErrors"
                    :type="'elevator'"
                    :status="status"
                    :errors="errors"
                    @show-errors="showErrorsElevator"
                />
            </div>
        </div>
        <div v-if="showErrors" class="elevator-row__errors">
            <app-errors-row
                :type="'elevator'"
                :errors="errors"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "ElevatorRow",

        props: {
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            hwId: {
                type: String,
                required: true
            },
            premiseName: {
                type: String,
                required: true
            },
            buildingName: {
                type: String,
                required: true
            },
            entrance: {
                type: [ Number, null ],
                required: true
            },
            floor: {
                type: Number,
                required: true
            },
            status: {
                type: Boolean,
                required: true
            },
            errors: {
                type: Array,
                required: true
            }
        },

        inject: {
            subString: {
                default: ''
            }
        },

        data() {
            return {
                showActionPanel: false,
                showErrors: false,
                searchSubString: this.subString
            }
        },

        mounted() {
            document.addEventListener("mousedown", this.handlerClickOutside);
        },

        unmounted() {
            document.removeEventListener("mousedown", this.handlerClickOutside);
        },

        watch: {
            hasErrors: {
                handler(newValue) {
                    this.showErrors = newValue;
                },

                immediate: true
            }
        },

        computed: {
            hasErrors() {
                return !!this.errors.length;
            }
        },

        methods: {
            toggleActionPanel() {
                this.showActionPanel = !this.showActionPanel;
            },

            handlerClickOutside(event) {
                if (!this.$refs.actionControlPanel.contains(event.target)) {
                    this.closeActionPanel();
                }
            },

            closeActionPanel() {
                //Need it to keep the modal user from opening, after click outside, but it doesn't work for another row
                setTimeout(() => {
                    this.showActionPanel = false;
                }, 100)
            },

            handlerClickActionBtn(id) {
                switch (id) {
                    case 'restart':
                        this.$emit('openModalRestart', this.id);
                        break;
                    case 'send':
                        this.$emit('openModalSend', this.id);
                        break;
                }

                this.closeActionPanel();
            },

            handlerClickElevator() {
                this.$emit('update', this.id);
            },

            showErrorsElevator() {
                this.showErrors = !this.showErrors;
            }
        }
    }
</script>

<style lang="scss">
    .elevator-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: var(--system-white);

        .table__row {
            background: unset;
        }

        &__entry {
            &:hover {
                cursor: pointer;
            }
        }

        &:nth-child(2n) {
            background: var(--app-table-background);
        }
    }
</style>