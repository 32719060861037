<template>
    <app-modal-wrapper>
        <form @submit.prevent="doActionEquipmentBC" class="modal-equipment-bc">
            <div class="modal-equipment-bc__content">
                <div class="equipment-bc-form">
                    <h4>{{ title }}</h4>
                    <div class="equipment-bc-form__info">
                        <div class="equipment-bc-form__row">
                            <div class="equipment-bc-form__details">
                                <app-base-select ref="building"
                                    :is-disabled="isUpdate"
                                    :label="$t('equipments.equipment.building.label')"
                                    :place-holder="$t('equipments.equipment.building.ph')"
                                    :selected-option="selectedBuilding"
                                    :options="buildings"
                                    :type="'text'"
                                    :error-message="errors.building"
                                    @select="selectBuilding"
                                />
                            </div>
                            <div class="equipment-bc-form__details">
                                <app-base-select ref="entrance"
                                    :is-required="false"
                                    :label="$t('equipments.equipment.entrance.label')"
                                    :place-holder="$t('equipments.equipment.entrance.ph')"
                                    :selected-option="selectedEntrance"
                                    :options="entrances"
                                    :type="'text'"
                                    :is-clear="true"
                                    @select="selectEntrance"
                                />
                            </div>
                        </div>
                        <div class="equipment-bc-form__row">
                            <div class="equipment-bc-form__details">
                                <app-base-input
                                    v-model="bc.name"
                                    :label="$t('equipments.equipment.name.label')"
                                    :place-holder="$t('equipments.equipment.name.ph')"
                                    :error-message="errors.name"
                                />
                            </div>
                            <div class="equipment-bc-form__details">
                                <app-base-select ref="floor"
                                    :label="$t('equipments.equipment.floor.label')"
                                    :place-holder="$t('equipments.equipment.floor.ph')"
                                    :selected-option="selectedFloor"
                                    :options="floors"
                                    :type="'text'"
                                    :error-message="errors.floor"
                                    @select="selectFloor"
                                />
                            </div>
                        </div>
                        <div class="equipment-bc-form__row">
                            <div class="equipment-bc-form__details">
                                <app-base-input
                                    v-model="bc.serialNumber"
                                    :is-disabled="isUpdate"
                                    :label="$t('equipments.equipment.serialNumber.label')"
                                    :place-holder="$t('equipments.equipment.serialNumber.ph')"
                                    :error-message="errors.serialNumber"
                                />
                            </div>
                            <div class="equipment-bc-form__details"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-equipment-bc__control">
                <app-base-btn v-if="isUpdate"
                    :variation="'delete'"
                    :text="$t('common.buttons.delete')"
                    @action="openModalConfirm"
                />
                <app-base-btn
                    :variation="'cancel'"
                    :text="$t('common.buttons.cancel')"
                    @action="closeModalEquipmentBC"
                />
                <app-base-btn
                    :type="'submit'"
                    :variation="'save'"
                    :text="$t('common.buttons.save')"
                />
            </div>
        </form>
    </app-modal-wrapper>
</template>

<script>
    import { mapGetters } from "vuex";
    import { greenHubAPI } from "../../../config/api";
    import { equipmentsToastsMixin } from "../../../mixins/toasts/equipments";
    import cloneDeep from "lodash.clonedeep";
    import { clearDependentSelects } from "../../../helpers/selects";
    import { mapEquipmentBCToSend } from "../../../api/mappers/equipments";
    import { validateEquipmentsBC } from "../../../validations/equipments";

    export default {
        name: "ModalEquipmentBC",

        props: {
            selectedBC: {
                type: Object
            }
        },

        data() {
            return {
                selectedBuilding: null,
                selectedEntrance: null,
                selectedFloor: null,

                bc: {
                    name: '',
                    serialNumber: ''
                },

                errors: {
                    building: '',
                    name: '',
                    serialNumber: '',
                    floor: '',
                }
            }
        },

        mixins: [
            equipmentsToastsMixin
        ],

        created() {
            this.checkUpdateBC();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings'
            }),

            isUpdate() {
                return !!this.selectedBC?.id;
            },

            title() {
                return !this.isUpdate
                    ? this.$t('equipments.equipment.bc.title.create')
                    : this.$t('equipments.equipment.bc.title.edit');
            },

            entrances() {
                return this.selectedBuilding?.entrances || [];
            },

            floors() {
                return this.selectedBuilding?.floors || [];
            }
        },

        methods: {
            checkUpdateBC() {
                const buildingId = this.selectedBC.buildingId;
                this.selectedBuilding = this.buildings.find((building) => building.id === buildingId);

                if (this.isUpdate) {
                    this.bc = cloneDeep(this.selectedBC);

                    if (this.bc.entrance) {
                        this.selectedEntrance = this.entrances.find((entrance) => entrance.id === this.bc.entrance);
                    }

                    this.selectedFloor = this.floors.find((floor) => floor.id === this.bc.floor);
                }
            },

            doActionEquipmentBC() {
                this.clearEquipmentBCErrors();
                const { isValid, errors } = validateEquipmentsBC(this.bc, this.selectedBuilding, this.selectedFloor);
                this.errors = errors;

                if (isValid) {
                    if (!this.isUpdate) {
                        this.createEquipmentBC();
                    } else {
                        this.changeEquipmentBC();
                    }
                }
            },

            createEquipmentBC() {
                this.prepareDataBCFromSelects();
                const bcToSend = mapEquipmentBCToSend(this.bc);

                greenHubAPI.post('/equipments/building_controllers', bcToSend)
                    .then(() => {
                        this.updateEquipments();
                        this.showCreateEquipmentsBCToast();
                    })
            },

            changeEquipmentBC() {
                this.prepareDataBCFromSelects();
                const id = this.bc.id;
                const bcToSend = mapEquipmentBCToSend(this.bc);

                greenHubAPI.patch(`/equipments/building_controllers/${id}`, bcToSend)
                    .then(() => {
                        this.updateEquipments();
                    })
            },

            prepareDataBCFromSelects() {
                this.bc.buildingId = this.selectedBuilding.id;
                this.bc.floor = this.selectedFloor.id;
                //<--------OPTIONAL FIELD-------->//
                this.bc.entrance = this.selectedEntrance?.id || null;
            },

            updateEquipments() {
                this.closeModalEquipmentBC();
                this.emitter.emit('updateEquipments');
            },

            selectBuilding(building) {
                this.selectedBuilding = building;
                this.clearDependentFields();
            },

            selectFloor(floor) {
                this.selectedFloor = floor;
            },

            selectEntrance(entrance) {
                this.selectedEntrance = entrance;
            },

            clearDependentFields() {
                const dependentSelects = {
                    entrance: this.$refs.entrance,
                    floor: this.$refs.floor
                };

                clearDependentSelects(dependentSelects);
            },

            clearEquipmentBCErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.selectedBC.id,
                    name: this.selectedBC.name,
                    type: 'equipmentBC'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            },

            closeModalEquipmentBC() {
                this.closeModal('modalEquipmentBC');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-equipment-bc {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;
        max-height: 80vh;
        overflow-y: auto;

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    .equipment-bc-form {
        &__info {
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            &:not(:last-child) {
                padding-bottom: 30px;
                border-bottom: 1px solid var(--app-border);
            }
        }

        &__row {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__sm {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 10px;
        }
    }
</style>