<template>
    <div class="electricity">
        <div class="electricity__panel">
            <div class="electricity__title">
                {{ $t('electricity.title') }}
            </div>
            <div class="electricity__actions">
                <div class="electricity__action-btn">
                    <app-base-btn
                        :size="'padding'"
                        :variation="'base'"
                        :text="$t('common.buttons.newConsumer')"
                        @action="openModalElectricityConsumer"
                    >
                        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.7917 14.0413H13.5417V20.2913H11.4584V14.0413H5.20837V11.958H11.4584V5.70801H13.5417V11.958H19.7917V14.0413Z" fill="#2B3037"/>
                        </svg>
                    </app-base-btn>
                </div>
                <app-add-btn
                    @open-modal="openModalElectricitySwitchgear"
                />
            </div>
        </div>
        <div class="electricity__control">
            <div class="electricity__filters">
                <electricity-filters
                    :qty="qty"
                    @update-query-id="updateQueryId"
                    @update-query-params="updateQueryParams"
                />
            </div>
        </div>
        <div class="electricity__content" ref="electricity">
            <div v-if="showTable" class="electricity__table">
                <electricity-table
                    :switchgears="switchgears"
                    :is-online="isOnline"
                    :max-height="maxHeightScroll"
                />
            </div>
            <div v-if="showEmpty" class="electricity__empty">
                <app-empty-content
                    :content-type="'switchgears'"
                    @open-modal="openModalElectricitySwitchgear"
                />
            </div>
            <div v-if="showNoResults" class="electricity__empty">
                <app-no-results
                    @clear="clearQueryParams"
                />
            </div>
            <div v-if="isLoaded" class="electricity__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { greenHubAPI } from "../../config/api";
    import { maxHeightMixin } from "../../mixins/calcMaxHeight";
    import { electricityToastsMixin } from "../../mixins/toasts/electricity";
    import { mapSwitchgearsToView } from "../../api/mappers/electricity";
    import ElectricityFilters from "./ElectricityFilters";
    import ElectricityTable from "./ElectricityTable";
    import {computed} from "vue";

    export default {
        name: "ElectricityControl",

        components: {
            ElectricityFilters,
            ElectricityTable
        },

        mixins: [
            maxHeightMixin,
            electricityToastsMixin
        ],

        data() {
            return {
                isLoaded: false,
                timerId: '',
                switchgears: [],
                qty: 0,

                query: {
                    type: 'online',
                    id: '',
                    params: null
                }
            }
        },

        provide() {
            return {
                subString: computed(() => this.query?.params?.match || '')
            }
        },

        created() {
            this.timerId = setInterval(() => {
                this.getElectricitySwitchgears();
            }, 5000);
        },

        mounted() {
            this.subscribeToResize('electricity');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
            clearInterval(this.timerId);
        },

        computed: {
            isSwitchgearsExist() {
                return !!this.switchgears.length;
            },

            showTable() {
                return this.isSwitchgearsExist && !this.isLoaded;
            },

            showEmpty() {
                return !this.isSwitchgearsExist && !this.isLoaded && !this.isExistQueryParams;
            },

            showNoResults() {
                return !this.isSwitchgearsExist && !this.isLoaded && this.isExistQueryParams;
            },

            isExistQueryParams() {
                return this.query.params;
            },

            isOnline() {
                return this.query.type === 'online';
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateElectricitySwitchgears', this.updateElectricitySwitchgears);
                this.emitter.on('deleteSwitchgear', this.deleteElectricitySwitchgear);
                this.emitter.on('deleteConsumer', this.deleteElectricityConsumer);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateWaterPads', this.updateElectricitySwitchgears);
                this.emitter.off('deleteSwitchgear', this.deleteElectricitySwitchgear);
                this.emitter.off('deleteConsumer', this.deleteElectricityConsumer);
            },

            //<----------------------------.......................>
            //TODO: ONE UPDATE FOR QUERY
            updateQueryParams(params) {
                this.query.params = params;
                this.updateElectricitySwitchgears();
            },

            updateQueryId(id) {
                this.query.id = id;
                this.clearQueryParams();
                this.updateElectricitySwitchgears();
            },

            updateElectricitySwitchgears() {
                this.isLoaded = true;
                this.getElectricitySwitchgears();
                this.emitter.emit('updateSwitchgearsFilters');
            },

            clearQueryParams() {
                this.emitter.emit('clearQueryParams');
            },

            //<----------------------------.......................>

            getElectricitySwitchgears() {
                const id = this.query.id;

                const params = {
                    params: this.query.params
                };

                greenHubAPI.get(`electricity/${id}`, params)
                    .then((result) => {
                        const { qty, mappedSwitchgears } = mapSwitchgearsToView(result.data);

                        this.switchgears = mappedSwitchgears;
                        this.qty = qty;

                        this.emitter.emit('updateShowQty');
                        this.$nextTick(() => {
                            this.calcMaxHeight();
                        });
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            deleteElectricitySwitchgear({ id, name }) {
                greenHubAPI.delete(`/electricity/switchgear/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.closeModalElectricitySwitchgear();
                        this.showDeleteSwitchgearToast(name);
                        this.updateElectricitySwitchgears();
                    })
            },

            deleteElectricityConsumer({ id, name }) {
                greenHubAPI.delete(`/electricity/consumer/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.closeModalElectricityConsumer();
                        this.showDeleteConsumerToast(name);
                        this.updateElectricitySwitchgears();
                    })
            },

            openModalElectricitySwitchgear() {
                const selectedElement = {
                    buildingId: this.query.id
                };

                this.openModal({
                    name: 'modalElectricitySwitchgear',
                    selectedEl: selectedElement
                })
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            },

            closeModalElectricitySwitchgear() {
                this.closeModal('modalElectricitySwitchgear');
            },

            closeModalElectricityConsumer() {
                this.closeModal('modalElectricityConsumer');
            },

            openModalElectricityConsumer() {
                const selectedElement = {
                    buildingId: this.query.id
                };

                this.openModal({
                    name: 'modalElectricityConsumer',
                    selectedEl: selectedElement
                })
            }
        }
    }
</script>

<style lang="scss">
    .switchgear-counter {
        &__name {
            width: 17%;
            display: flex;
            align-items: center;

            button {
                margin-right: 30px;
            }

            &--is-switchgear {
                font-weight: 600;
            }
        }

        &__entrance {
            width: 6%;
        }

        &__floor {
            width: 6%;
        }

        &__premise {
            width: 13%;
        }

        &__input-number {
            width: 6%;
        }

        &__hwId {
            width: 12%;
        }

        &__model {
            width: 12%;
        }

        &__data {
            width: 20%;
            padding-right: unset !important;
        }

        &__data-view {
            display: flex;
            align-items: center;
        }

        &__readings {
            width: 50%;
            display: flex;
            align-items: center;

            /*button {*/
            /*    margin-left: 10px;*/
            /*}*/
        }

        /*&__readings-wrapper {*/
        /*    min-width: 80px;*/
        /*}*/

        &__consumptions {
            width: 50%;
        }

        &__consumpted {
            width: 50%;
        }

        &__imbalance {
            width: 50%;
        }

        &__status {
            width: 8%;
        }
    }
</style>