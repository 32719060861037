<template>
    <label class="checkbox">
        <input type="checkbox"
            v-model="checkboxValue"
            :value="value"
        />
        <div v-if="showLabel" class="checkbox__label can-overflow">
            {{ label }}
        </div>
        <span class="checkmark"></span>
    </label>
</template>

<script>
    export default {
        name: "AppBaseCheckbox",

        props: {
            modelValue: {
                type: [Boolean, Array],
                required: true
            },
            value: {
                type: String,
                required: true
            },
            label: {
                type: String,
                default: ''
            }
        },

        emits: ['update:modelValue'],

        computed: {
            checkboxValue: {
                get() {
                    return this.modelValue;
                },
                set(val) {
                    this.$emit('update:modelValue', val);
                }
            },

            showLabel() {
                return !!this.label;
            }
        }
    }
</script>