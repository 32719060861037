<template>
    <router-link :to="route" class="menu-item">
        <span>{{ name }}</span>
    </router-link>
</template>

<script>
    export default {
        name: "AppMenuItem",

        props: {
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            route: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .menu-item {
        color: var(--app-base-primary);
        font-size: 16px;
        margin-right: 30px;
        padding: 15px 0 12px 0;
        transition: 0.3s;
        border-bottom: 3px solid transparent;

        &:hover {
            color: var(--brand-purple);
        }
    }

    .menu-item.router-link-active {
        color: var(--brand-purple);
        border-bottom: 3px solid var(--brand-purple);
    }
</style>