import { showToast } from "../../helpers/toasts";

const electricityToastsMixin = {
    methods: {
        showDeleteSwitchgearToast(name) {
            const deleteToastTitle = this.$t('toasts.switchgears.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.switchgears.delete.details1`)}
                    ${name}
                    ${this.$t(`toasts.switchgears.delete.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showCreateSwitchgearToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.switchgears.create.details')
            );
        },

        showDeleteConsumerToast() {
            const deleteToastTitle = this.$t('toasts.consumers.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.consumers.delete.details1`)}
                    ${this.$t(`toasts.consumers.delete.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showCreateConsumerToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.consumers.create.details')
            );
        }
    }
}

export {
    electricityToastsMixin
}