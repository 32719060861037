<template>
    <app-modal-wrapper>
        <div class="modal-recovery-password">
            <div v-if="currentStep === 0" class="recovery-step-1">
                <form @submit.prevent="forgotUserPassword(false)" class="recovery-step-1__form">
                    <h4>{{ $t('recovery.step1.title') }}</h4>
                    <div class="recovery-step-1__login">
                        <app-base-input
                            v-model="username"
                            :label="$t('recovery.step1.label')"
                            :error-message="errors.login"
                        />
                    </div>
                    <div class="recovery-step-1__control">
                        <app-base-btn
                            :size="'full'"
                            :variation="'cancel'"
                            :text="$t('common.buttons.cancel')"
                            @action="previousStep"
                        />
                        <app-base-btn
                            :type="'submit'"
                            :size="'full'"
                            :variation="'save'"
                            :text="$t('common.buttons.next')"
                        />
                    </div>
                </form>
            </div>
            <div v-if="currentStep === 1" class="recovery-step-2">
                <form @submit.prevent="forgotUserPasswordCode" class="recovery-step-2__form">
                    <h4>{{ $t('recovery.step2.title') }}</h4>
                    <div class="recovery-step-2__code">
                        <app-base-input
                            v-model="code"
                            :label="formattedCodeLabel"
                            :error-message="errors.code"
                        />
                    </div>
                    <div class="recovery-step-2__repeat-btn">
                        <app-text-btn
                            :text="$t('common.buttons.repeat')"
                            @action="forgotUserPassword(true)"
                        />
                    </div>
                    <div class="recovery-step-2__control">
                        <app-base-btn
                            :size="'full'"
                            :variation="'cancel'"
                            :text="$t('common.buttons.cancel')"
                            @action="previousStep"
                        />
                        <app-base-btn
                            :type="'submit'"
                            :size="'full'"
                            :variation="'save'"
                            :text="$t('common.buttons.next')"
                        />
                    </div>
                </form>
            </div>
            <div v-if="currentStep === 2" class="recovery-step-3">
                <form @submit.prevent="forgotUserPasswordSubmit" class="recovery-step-3__form">
                    <h4>{{ $t('recovery.step3.title') }}</h4>
                    <div class="recovery-step-3__new-password-value">
                        <app-base-input
                            v-model="newPassword.value"
                            :type="'password'"
                            :label="$t('recovery.step3.label.value')"
                            :error-message="errors.newPasswordValue"
                        />
                    </div>
                    <div class="recovery-step-3__new-password-confirm">
                        <app-base-input
                            v-model="newPassword.confirm"
                            :type="'password'"
                            :label="$t('recovery.step3.label.confirm')"
                            :error-message="errors.newPasswordConfirm"
                        />
                    </div>
                    <div class="recovery-step-3__control">
                        <app-base-btn
                            :size="'full'"
                            :variation="'cancel'"
                            :text="$t('common.buttons.cancel')"
                            @action="previousStep"
                        />
                        <app-base-btn
                            :type="'submit'"
                            :size="'full'"
                            :variation="'save'"
                            :text="$t('common.buttons.next')"
                        />
                    </div>
                </form>
            </div>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import {forgotPassword, forgotPasswordSubmit, logout} from "../../../services/checkAccess";
    import { formatEmail, formatPhone} from "../../../helpers/symbols";
    import { recoveryErrors } from "../../../validations/recovery";
    import { validateLoginData } from "../../../validations/login";
    import { passwordErrors, validatePasswordData} from "../../../validations/password";
    import { authToastsMixin } from "../../../mixins/toasts/auth";
    import { greenHubAPI } from "../../../config/api";

    export default {
        name: "ModalRecoveryPassword",

        props: {
            isLoggedIn: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                currentStep: 0,
                username: '',
                code: '',
                newPassword: {
                    value: '',
                    confirm: ''
                },
                errors: {
                    login: '',
                    code: '',
                    newPasswordValue: '',
                    newPasswordConfirm: ''
                }
            }
        },

        mixins: [authToastsMixin],

        created() {
            this.checkIsLoggedIn();
        },

        computed:{
            formattedCodeLabel() {
                const loginType = this.username.includes('@')
                    ? this.$t('recovery.step2.label.email')
                    : this.$t('recovery.step2.label.phone');
                const formattedLogin = this.username.includes('@')
                    ? formatEmail(this.username)
                    : formatPhone(this.username);

                return `${loginType} ${formattedLogin} ${this.$t('recovery.step2.label.details')}`;
            }
        },

        methods: {
            checkIsLoggedIn() {
                if (this.isLoggedIn) {
                    greenHubAPI.get('/admins/me')
                        .then((result) => {
                            const profile = result.data;
                            this.username = profile.username;
                        })
                }
            },

            async forgotUserPassword(isRepeatCode) {
                this.clearErrorsStep1();
                const isValid = validateLoginData(this.username);

                if (isValid) {
                    await forgotPassword(this.username)
                        .then(isSuccess => {
                            if (isSuccess && !isRepeatCode) {
                                this.nextStep();
                            }
                        })
                } else {
                    this.setErrorsStep1();
                }
            },

            forgotUserPasswordCode() {
                this.clearErrorsStep2();
                const isValid = !!this.code;

                if (isValid) {
                    this.nextStep();
                } else {
                    this.setErrorsStep2();
                }
            },

            async forgotUserPasswordSubmit() {
                this.clearErrorsStep3();
                const isValid = this.validateNewPasswordData(this.newPassword.value, this.newPassword.confirm);

                if (isValid) {
                    await forgotPasswordSubmit(this.username, this.code, this.newPassword.value)
                        .then(isSuccess => {
                            if (isSuccess) {
                                this.showRecoveryPasswordToast();

                                if (this.isLoggedIn) {
                                    this.closeModal('modalRecoveryPassword');
                                    this.closeModal('modalChangePassword');
                                    this.logoutUser();
                                } else {
                                    this.closeModal('modalRecoveryPassword');
                                }
                            }
                        })
                }
            },

            async logoutUser() {
                await logout()
                    .then(isSuccess => {
                        if (isSuccess) {
                            this.$router.push({ name: 'Login' });
                        }
                    })
            },

            nextStep() {
                this.currentStep += 1;
            },

            previousStep() {
                if (this.currentStep === 0) {
                    this.closeModal('modalRecoveryPassword');
                } else {
                    this.currentStep -= 1;
                }
            },

            setErrorsStep1() {
                this.errors.login = recoveryErrors.access;
            },

            clearErrorsStep1() {
                this.errors.login = '';
            },

            setErrorsStep2() {
                this.errors.code = recoveryErrors.code;
            },

            clearErrorsStep2() {
                this.errors.code = '';
            },

            validateNewPasswordData(value, confirm) {
                let isValid = false;

                if (value === confirm) {
                    const isValidByRules = validatePasswordData(value);

                    if (isValidByRules) {
                        isValid = true;
                    } else {
                        this.setErrorsStep3('validation');
                    }
                } else {
                    this.setErrorsStep3('identity');
                }

                return isValid;
            },

            setErrorsStep3(type) {
                this.errors.newPasswordValue = passwordErrors[type];
                this.errors.newPasswordConfirm = 'isNotShowError';
            },

            clearErrorsStep3() {
                this.errors.newPasswordValue = '';
                this.errors.newPasswordConfirm = '';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-recovery-password {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 574px;
        width: 100%;
    }

    .recovery-step-1, .recovery-step-2, .recovery-step-3 {
        h4 {
            margin-bottom: 30px;
        }

        &__control {
            display: flex;
            margin-top: 45px;

            button:first-child {
                margin-right: 30px;
            }
        }
    }

    .recovery-step-2 {
        &__code {
            margin-bottom: 10px;
        }
    }

    .recovery-step-3 {
        &__new-password-value {
            margin-bottom: 30px;
        }
    }
</style>