import { createI18n } from 'vue-i18n';
import { setDefaultLocale } from "../config/localization";
import en from './en.json';
import uk from './uk.json';
import ru from './ru.json';
import ka from './ka.json';

const i18n = createI18n({
    // locale: localStorage.language ? setDefaultLocale(localStorage.language) : setDefaultLocale(navigator.language),
    locale: 'uk',
    messages: {
        en,
        uk,
        ru,
        ka
    }
});

export default i18n;
