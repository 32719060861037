<template>
    <div class="premises">
        <div class="premises__panel">
            <div class="premises__settings">
                <div class="premises__title">
                    {{ $t('premises.title') }}
                </div>
            </div>
            <div class="premises__actions">
                <app-add-btn
                    @open-modal="openModalPremise"
                />
            </div>
        </div>
        <div class="premises__control">
            <div class="premises__filters">
                <premises-filters
                    :qty="qty"
                    @update-query-params="updateQueryParams"
                />
            </div>
        </div>
        <div class="premises__content" ref="premises">
            <div v-if="showTable" class="premises__table">
                <premises-table
                    :premises="premises"
                    :max-height="maxHeightScroll"
                    @load-by-scroll="loadMorePremisesByScroll"
                />
            </div>
            <div v-if="showEmpty" class="premises__empty">
                <app-empty-content
                    :content-type="'premises'"
                    @open-modal="openModalPremise"
                />
            </div>
            <div v-if="showNoResults" class="premises__empty">
                <app-no-results
                    @clear="clearQueryParams"
                />
            </div>
            <div v-if="isLoaded" class="premises__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { computed } from "vue";
    import { greenHubAPI } from "../../config/api";
    import { defaultQuery } from "../../helpers/api";
    import { maxHeightMixin } from "../../mixins/calcMaxHeight";
    import { premisesToastsMixin } from "../../mixins/toasts/premises";
    import cloneDeep from "lodash.clonedeep";
    import { mapPremisesToView } from "../../api/mappers/premises";
    import PremisesFilters from "./PremisesFilters";
    import PremisesTable from "./PremisesTable";

    export default {
        name: "PremisesControl",

        components: {
            PremisesFilters,
            PremisesTable
        },

        mixins: [
            maxHeightMixin,
            premisesToastsMixin
        ],

        data() {
            return {
                isLoaded: false,
                premises: [],
                qty: 0,

                query: null
            }
        },

        provide() {
            return {
                subString: computed(() => this.searchSubString)
            }
        },

        created() {
            this.updateQueryParamsByDefault();
            this.getPremises(false);
        },

        mounted() {
            this.subscribeToResize('premises');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
        },

        computed: {
            isExistPremises() {
                return !!this.premises.length;
            },

            showTable() {
                return this.isExistPremises && !this.isLoaded;
            },

            showEmpty() {
                return !this.isExistPremises && !this.isLoaded && !this.isExistQueryParams;
            },

            showNoResults() {
                return !this.isExistPremises && !this.isLoaded && this.isExistQueryParams;
            },

            isExistQueryParams() {
                return this.searchSubString || this.filterSubString;
            },

            searchSubString() {
                return this.query.params.match ? this.query.params.match : '';
            },

            filterSubString() {
                return this.query.params.filter ? this.query.params.filter : '';
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updatePremises', this.updatePremises);
                this.emitter.on('deletePremise', this.deletePremise);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updatePremises', this.updatePremises);
                this.emitter.off('deletePremise', this.deletePremise);
            },

            getPremises(isLoadedMore) {
                if (!isLoadedMore) {
                    this.isLoaded = true;
                }

                const params = {
                    params: this.query.params
                };

                greenHubAPI.get('/premises', params)
                    .then((result) => {
                        const { qty, premises } = result.data;

                        this.qty = qty;
                        const mappedPremises = mapPremisesToView(premises);

                        if (isLoadedMore) {
                            this.premises = [...this.premises, ...mappedPremises];

                            if (this.qty <= this.premises.length) {
                                this.emitter.emit('isNoMore');
                            }
                        } else {
                            this.premises = mappedPremises;
                        }

                        this.emitter.emit('isLoadedMore');
                        this.emitter.emit('updateShowQty');
                        this.$nextTick(() => {
                            this.calcMaxHeight();
                        });
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            loadMorePremisesByScroll() {
                this.updateQueryParamsByScroll();
                this.getPremises(true);
            },

            updateQueryParams(params) {
                this.updateQueryParamsByDefault();
                this.query.params = { ...this.query.params, ...params };
                this.emitter.emit('clearNoMore');
                this.getPremises(false);
            },

            updateQueryParamsByScroll() {
                this.query.page += 1;
                this.query.params.skip = this.query.page * this.query.params.limit;
            },

            updateQueryParamsByDefault() {
                this.query = cloneDeep(defaultQuery);
            },

            updateQueryParamsByDefaultManual() {
                this.query.page = 0;
                this.query.params.skip = 0;
                this.query.params.limit = 15;
            },

            clearQueryParams() {
                this.emitter.emit('clearQueryParams');
            },

            updatePremises() {
                this.updateQueryParamsByDefaultManual();
                this.emitter.emit('clearNoMore');
                this.getPremises(false);
            },

            deletePremise({ id, name }) {
                greenHubAPI.delete(`/premises/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.closeModalPremise();
                        this.showDeletePremiseToast(name);
                        this.updatePremises();
                    })
            },

            openModalPremise() {
                this.openModal({
                    name: 'modalPremise'
                })
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            },

            closeModalPremise() {
                this.closeModal('modalPremise');
            }
        }
    }
</script>