<template>
    <div class="switchgear-counter-row">
        <div class="switchgear-counter-row__parent"
             :class="{
                'switchgear-counter-row__parent--is-consumer': isConsumer,
             }"
        >
            <div class="table__row switchgear-counter-row__entry" @click.stop="handlerClickSwitchgearCounter">
                <div class="table__column switchgear-counter__name"
                     :class="{
                        'switchgear-counter__name--is-switchgear': !level
                    }"
                     :style="{
                        'paddingLeft': `${40 * level}px`
                     }"
                >
                    <div class="switchgear-counter__collapse-btn">
                        <app-collapse-btn v-if="isExistChildren"
                            :default-is-active="true"
                            @collapse="collapseChildren"
                        />
                    </div>
                    <app-text-wrapper
                        :is-parent="true"
                        :text="name"
                    />
                </div>
                <div class="table__column switchgear-counter__entrance">
                    {{ entrance }}
                </div>
                <div class="table__column switchgear-counter__floor">
                    {{ floor }}
                </div>
                <div class="table__column switchgear-counter__premise">
                    <app-text-wrapper
                        :text="premiseName"
                        :sub-text="searchSubString"
                        :is-match="isConsumer"
                    />
                </div>
                <div class="table__column switchgear-counter__input-number">
                    <div v-if="isSwitchgearCounter">
                        {{ inputNumber }}
                    </div>
                </div>
                <div class="table__column switchgear-counter__hwId">
                    <app-text-wrapper v-if="isSwitchgearCounter"
                        :text="hwId"
                        :sub-text="searchSubString"
                        :is-match="isConsumer"
                    />
                </div>
                <div class="table__column switchgear-counter__model">
                    <app-text-wrapper v-if="isSwitchgearCounter"
                        :text="counterModel"
                        :is-match="isConsumer"
                    />
                </div>
                <div class="table__column switchgear-counter__data">
                    <div v-if="isOnline && isSwitchgearCounter" class="switchgear-counter__data-view">
                        <div class="table__column switchgear-counter__readings">
                            <app-text-wrapper
                                :text="formattedReadings"
                            />
                        </div>
                        <div class="table__column switchgear-counter__consumptions">
                            <app-text-wrapper
                                :text="formattedConsumptions"
                            />
                        </div>
                    </div>
                    <div v-if="!isOnline && isSwitchgearCounter" class="switchgear-counter__data-view">
                        <div class="table__column switchgear-counter__consumpted">
                            <app-text-wrapper
                                :text="formattedConsumpted"
                            />
                        </div>
                        <div class="table__column switchgear-counter__imbalance">
                            <app-text-wrapper
                                :text="formattedImbalance"
                            />
                        </div>
                    </div>
                </div>
                <div class="table__column switchgear-counter__status">
                    <app-base-status v-if="isSwitchgearCounter"
                        :show-errors="showErrors"
                        :type="'electricity'"
                        :status="status"
                        :errors="errors"
                        @show-errors="showErrorsSwitchgearCounter"
                    />
                </div>
            </div>
            <div v-if="showErrors" class="switchgear-counter-row__errors">
                <app-errors-row
                    :type="'electricity'"
                    :errors="errors"
                />
            </div>
        </div>
        <div v-if="isExistChildren && showChildren">
            <switchgear-counter-row v-for="counter in children" :key="counter.id"
                :is-online="isOnline"
                :level="level + 1"
                :id="counter.id"
                :name="counter.name"
                :floor="counter.floor"
                :entrance="counter.entrance"
                :premise-name="counter.premiseName"
                :children="counter.children || []"
                :input-number="counter.inputNumber"
                :hw-id="counter.hwId"
                :model="counter.model"
                :readings="counter.readings"
                :consumptions="counter.consumptions"
                :consumpted="counter.consumpted"
                :imbalance="counter.imbalance"
                :status="counter.status"
                :errors="counter.errors"
                @update="updateSwitchgearCounter"
            />
        </div>
    </div>
</template>

<script>
    import { electricityCounterModels } from "../../../constants/electricity";

    export default {
        name: "SwitchgearCounterRow",

        props: {
            isOnline: {
                type: Boolean,
                required: true
            },
            level: {
                type: Number,
                required: true
            },
            id: {
                type: [Number, String],
                required: true
            },
            name: {
                type: String,
                default: ''
            },
            floor: {
                type: Number,
                default: null
            },
            entrance: {
                type: Number,
                default: null
            },
            premiseName: {
                type: String,
                required: true
            },
            children: {
                type: Array,
                required: true
            },
            inputNumber: {
                type: Number,
                default: 0
            },
            hwId: {
                type: String,
                default: ''
            },
            model: {
                type: Number,
                default: 0
            },
            readings: {
                type: Number,
                default: 0
            },
            consumptions: {
                type: Number,
                default: 0
            },
            consumpted: {
                type: Number,
                default: 0
            },
            imbalance: {
                type: Number,
                default: 0
            },
            status: {
                type: Boolean,
                default: false
            },
            errors: {
                type: Array,
                default: () => []
            }
        },

        inject: {
            subString: {
                default: ''
            }
        },

        data() {
            return {
                showChildren: true,
                showErrors: false,
                searchSubString: this.subString
            }
        },

        watch: {
            hasErrors: {
                handler(newValue) {
                    this.showErrors = newValue;
                },

                immediate: true
            }
        },

        computed: {
            hasErrors() {
                return !!this.errors.length;
            },

            isExistChildren() {
                return !!this.children.length;
            },

            isSwitchgearCounter() {
                return this.level !== 0;
            },

            isConsumer() {
                return this.level === 2;
            },

            counterModel() {
                const model = electricityCounterModels.find((model) => model.id === this.model);

                return this.isSwitchgearCounter ? model.name : '';
            },

            formattedReadings() {
                return `${this.readings.toFixed(3)} ${this.$t('common.units.energy')}`;
            },

            formattedConsumptions() {
                return `${this.consumptions.toFixed(2)} ${this.$t('common.units.power')}`;
            },

            formattedConsumpted() {
                return `${this.consumpted.toFixed(3)} ${this.$t('common.units.power')}`;
            },

            formattedImbalance() {
                return `${this.imbalance.toFixed(3)} ${this.$t('common.units.power')}`;
            }
        },

        methods: {
            collapseChildren() {
                this.showChildren = !this.showChildren;
            },

            showErrorsSwitchgearCounter() {
                this.showErrors = !this.showErrors;
            },

            handlerClickSwitchgearCounter() {
                this.$emit('update', this.id);
            },

            updateSwitchgearCounter(id) {
                if (this.level === 1) {
                    const consumer = this.children.find((consumer) => consumer.id === id);

                    this.openModal({
                        name: 'modalElectricityConsumer',
                        selectedEl: consumer
                    })
                } else {
                    this.$emit('update', this.id);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .switchgear-counter-row {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__parent {
            &--is-consumer {
                background: var(--app-table-background) !important;
            }

            /*&--is-input-consumer {*/
            /*    background: var(--app-table-background-secondary) !important;*/
            /*}*/

            /*&--is-last-input-consumer,*/
            /*&--is-show-input-consumers {*/
            /*    border-bottom: 1px solid var(--app-base-secondary) !important;*/
            /*}*/
        }

        .table__row {
            background: unset;
        }

        &__entry {
            &:hover {
                cursor: pointer;
            }
        }
    }
</style>