<template>
    <div class="balancer-element" @click="handlerStationClick"
        :class="{
            'balancer-element--is-edited-limits': isEditLimits
        }"
    >
        <balancer-input-row v-for="(input, index) in inputs" :key="input.id" class="table__row"
            :level="level"
            :is-exist-children="!!children.length"
            :index="index"
            :name="name"
            :consumption="input.consumption"
            :nominal-limit="input.nominalLimit"
            :accident-limit="input.accidentLimit"
            :imbalance="input.imbalance"
            :status="input.status"
            :balancer-status="input.balancerStatus"
            :errors="input.errors"
            :is-edit-nominal-limits="isEditNominalLimits"
            :is-edit-accident-limits="isEditAccidentLimits"
            @collapse="collapseChildren"
            @update="updateBalancerInputLimit"
        />
        <div v-if="showChildren">
            <balancer-element-row v-for="element in children" :key="element.id"
                :level="level + 1"
                :id="element.id"
                :name="element.name"
                :inputs="element.inputs"
                :children="element.children"
                :is-edit-nominal-limits="isEditNominalLimits"
                :is-edit-accident-limits="isEditAccidentLimits"
            />
        </div>
    </div>
</template>

<script>
    import BalancerInputRow from "./BalancerInputRow";

    export default {
        name: "BalancerElementRow",

        components: {
            BalancerInputRow
        },

        props: {
            level: {
                type: Number,
                required: true
            },
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            inputs: {
                type: Array,
                default: () => []
            },
            children: {
                type: Array,
                default: () => []
            },
            isEditNominalLimits: {
                type: Boolean,
                required: true
            },
            isEditAccidentLimits: {
                type: Boolean,
                required: true
            }
        },

        data() {
            return {
                showChildren: true
            }
        },

        computed: {
            isEditLimits() {
                return this.isEditNominalLimits || this.isEditAccidentLimits;
            }
        },

        methods: {
            collapseChildren() {
                this.showChildren = !this.showChildren;
            },

            handlerStationClick() {
                if (this.level === 0 && !this.isEditLimits) {
                    this.$emit('update', this.id);
                }
            },

            updateBalancerInputLimit(isNominalLimit, index, isValid, limit) {
                const targetInput =  this.inputs[index];
                const limitTypeKey = isNominalLimit ? 'nominalLimit' : 'accidentLimit';
                const isValidLimitTypeKey = isNominalLimit ? 'isValidNominalLimit' : 'isValidAccidentLimit';

                targetInput[isValidLimitTypeKey] = isValid;

                if (isValid) {
                    targetInput[limitTypeKey] = Number(limit);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .balancer-element {
        display: flex;
        flex-direction: column;
        width: 100%;

        &:hover:not(.balancer-element--is-edited-limits) {
            cursor: pointer;
        }
    }
</style>