<template>
    <app-modal-wrapper>
        <form @submit.prevent="doActionSecurityObject" class="modal-security-object">
            <div class="modal-security-object__content">
                <div class="security-object-form">
                    <h4>{{ title }}</h4>
                    <div class="security-object-form__info">
                        <div class="security-object-form__row">
                            <div class="security-object-form__details">
                                <app-base-select
                                    :label="$t('security.object.building.label')"
                                    :place-holder="$t('security.object.building.ph')"
                                    :selected-option="selectedBuilding"
                                    :options="buildings"
                                    :type="'text'"
                                    @select="selectBuilding"
                                />
                            </div>
                            <div class="security-object-form__details">
                                <app-base-input
                                    v-model="securityObject.name"
                                    :label="$t('security.object.name.label')"
                                    :place-holder="$t('security.object.name.ph')"
                                    :error-message="errors.name"
                                />
                            </div>
                        </div>
                        <div class="security-object-form__row">
                            <div class="security-object-form__details">
                                <app-base-select ref="entrance"
                                    :is-clear="true"
                                    :label="$t('security.object.entrance.label')"
                                    :place-holder="$t('security.object.entrance.ph')"
                                    :selected-option="selectedEntrance"
                                    :options="entrances"
                                    :type="'text'"
                                    @select="selectEntrance"
                                />
                            </div>
                            <div class="security-object-form__details">
                                <app-base-select ref="module"
                                    :label="$t('security.object.module.label')"
                                    :place-holder="$t('security.object.module.ph')"
                                    :selected-option="selectedModule"
                                    :options="modules"
                                    :type="'text'"
                                    :error-message="errors.module"
                                    @select="selectModule"
                                />
                            </div>
                        </div>
                        <div class="security-object-form__row">
                            <div class="security-object-form__details">
                                <app-base-select ref="floor"
                                    :open-direction="'top'"
                                    :is-clear="true"
                                    :label="$t('security.object.floor.label')"
                                    :place-holder="$t('security.object.floor.ph')"
                                    :selected-option="selectedFloor"
                                    :options="floors"
                                    :type="'text'"
                                    @select="selectFloor"
                                />
                            </div>
                            <div class="security-object-form__details">
                                <app-base-select ref="channel"
                                    :open-direction="'top'"
                                    :label="$t('security.object.channel.label')"
                                    :place-holder="$t('security.object.channel.ph')"
                                    :selected-option="selectedChannel"
                                    :options="channels"
                                    :type="'text'"
                                    :error-message="errors.channel"
                                    @select="selectChannel"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-security-object__control">
                <app-base-btn v-if="isUpdate"
                    :variation="'delete'"
                    :text="$t('common.buttons.delete')"
                    @action="openModalConfirm"
                />
                <app-base-btn
                    :variation="'cancel'"
                    :text="$t('common.buttons.cancel')"
                    @action="closeModalSecurityObject"
                />
                <app-base-btn
                    :type="'submit'"
                    :variation="'save'"
                    :text="$t('common.buttons.save')"
                />
            </div>
        </form>
    </app-modal-wrapper>
</template>

<script>
    import { greenHubAPI } from "../../../config/api";
    import { mapGetters } from "vuex";
    import { securityToastsMixin } from "../../../mixins/toasts/security";
    import { securityModuleChannels } from "../../../constants/security";
    import { clearDependentSelects } from "../../../helpers/selects";
    import { prepareQueryFilterParams } from "../../../helpers/api";
    import { mapSecurityModulesToSelect, mapSecurityObjectToSend } from "../../../api/mappers/security";
    import { validateSecurityObject } from "../../../validations/security";

    export default {
        name: "ModalSecurityObject",

        props: {
            selectedSecurityObject: {
                type: Object
            }
        },

        mixins: [
            securityToastsMixin
        ],

        data() {
            return {
                selectedBuilding: null,
                selectedEntrance: null,
                selectedFloor: null,

                selectedModule: null,
                selectedChannel: null,

                modules: [],

                securityObject: {
                    name: ''
                },

                errors: {
                    name: '',
                    module: '',
                    channel: ''
                }
            }
        },

        created() {
            this.checkUpdateSecurityObject();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings'
            }),

            isUpdate() {
                return !!this.selectedSecurityObject;
            },

            title() {
                return !this.isUpdate
                    ? this.$t('security.object.title.create')
                    : this.$t('security.object.title.edit');
            },

            entrances() {
                return this.selectedBuilding?.entrances || [];
            },

            floors() {
                return this.selectedBuilding?.floors || [];
            },

            channels() {
                return securityModuleChannels;
            }
        },

        methods: {
            checkUpdateSecurityObject() {
                if (this.isUpdate) {
                    this.securityObject = { ...this.selectedSecurityObject };

                    this.selectedBuilding = this.buildings.find((building) => building.id === this.securityObject.buildingId);
                    this.selectedChannel = this.channels.find((channel) => channel.id === this.securityObject.channel);
                }

                this.getAdditionalData();
            },

            doActionSecurityObject() {
                this.clearSecurityObjectErrors();
                const { isValid, errors } = validateSecurityObject(this.securityObject, this.selectedModule, this.selectedChannel);
                this.errors = errors;

                if (isValid) {
                    if (!this.isUpdate) {
                        this.createSecurityObject();
                    } else {
                        this.changeSecurityObject();
                    }
                }
            },

            createSecurityObject() {
                this.prepareDataFromSelects();
                const securityObjectToSend = mapSecurityObjectToSend(this.securityObject);

                greenHubAPI.post('/security', securityObjectToSend)
                    .then(() => {
                        this.updateSecurityObjects();
                        this.showCreateSecurityObjectToast();
                    })
            },

            changeSecurityObject() {
                this.prepareDataFromSelects();
                const id = this.securityObject.id;
                const securityObjectToSend = mapSecurityObjectToSend(this.securityObject);

                greenHubAPI.patch(`/security/${id}`, securityObjectToSend)
                    .then(() => {
                        this.updateSecurityObjects();
                    })
            },

            prepareDataFromSelects() {
                this.securityObject.moduleId = this.selectedModule.id;
                this.securityObject.channelId = this.selectedChannel.id;
            },

            updateSecurityObjects() {
                this.closeModalSecurityObject();
                this.emitter.emit('updateSecurityObjects');
            },

            selectBuilding(building) {
                this.selectedBuilding = building;
                this.clearDependentFields();
                this.getAdditionalData();
            },

            clearDependentFields() {
                const dependentSelects = {
                    entrance: this.$refs.entrance,
                    floor: this.$refs.floor,
                    module: this.$refs.module,
                    channel: this.$refs.channel
                };

                clearDependentSelects(dependentSelects);
            },

            selectEntrance(entrance) {
                this.selectedEntrance = entrance;
                this.clearSelectedModuleSelect();

                this.getAdditionalData();
            },

            selectFloor(floor) {
                this.selectedFloor = floor;
                this.clearSelectedModuleSelect();

                this.getAdditionalData();
            },

            clearSelectedModuleSelect() {
                const dependentSelects = {
                    module: this.$refs.module
                };

                clearDependentSelects(dependentSelects)
            },

            getAdditionalData() {
                this.getSecurityModules();
            },

            getSecurityModules() {
                const queryFilterParams = this.prepareQueryFilterParamsFromSelects();
                const params = prepareQueryFilterParams(queryFilterParams);

                greenHubAPI.get('/equipments/security_modules', params)
                    .then((result) => {
                        this.modules = mapSecurityModulesToSelect(result.data);

                        if (this.isUpdate) {
                            this.selectedModule = this.modules.find((module) => module.id === this.securityObject.moduleId);
                        }
                    })
            },

            prepareQueryFilterParamsFromSelects() {
                const queryFilterParams = {};

                if (this.selectedBuilding) {
                    queryFilterParams.building_id = this.selectedBuilding.id;
                }

                if (this.selectedEntrance) {
                    queryFilterParams.entrance = this.selectedEntrance.id;
                }

                if (this.selectedFloor) {
                    queryFilterParams.floor = this.selectedFloor.id;
                }

                return queryFilterParams;
            },

            selectModule(module) {
                this.selectedModule = module;
            },

            selectChannel(channel) {
                this.selectedChannel = channel;
            },

            clearSecurityObjectErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            closeModalSecurityObject() {
                this.closeModal('modalSecurityObject');
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.securityObject.id,
                    name: this.securityObject.name,
                    type: 'security'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-security-object {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    .security-object-form {
        &__info {
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            &:not(:last-child) {
                padding-bottom: 30px;
                border-bottom: 1px solid var(--app-border);
            }
        }

        &__row {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }
    }
</style>