<template>
    <div class="building-statistics">
        <div class="building-statistics__building">
            <div class="building-statistics__group">
                {{ group }}
            </div>
            <div>{{ building }}</div>
        </div>
        <div class="building-statistics__parameter">
            <div class="building-statistics__value">
                {{ formattedValue }}
            </div>
            <div class="building-statistics__difference"
                 :class="{
                    'building-statistics__difference--is-positive': isPositiveDifference
            }"
            >
                {{ formattedDifference }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BuildingStatisticsRow",

        props: {
            id: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true
            },
            group: {
                type: String,
                required: true
            },
            building: {
                type: String,
                required: true
            },
            value: {
                type: Number,
                required: true
            },
            difference: {
                type: Number,
                required: true
            }
        },

        computed: {
            formattedValue() {
                return `${this.value} ${this.$t(`common.units.month.${this.type}`)}`;
            },

            isPositiveDifference() {
                return this.difference >= 0;
            },

            formattedDifference() {
                return this.isPositiveDifference
                    ? `+ ${this.difference}%`
                    : `- ${Math.abs(this.difference)}%`;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .building-statistics {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;

        &__group {
            margin-bottom: 4px;
        }

        &__value {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 4px;
        }

        &__difference {
            font-size: 14px;
            font-weight: 500;
            color: var(--system-red);

            &--is-positive {
                color: var(--system-green);
            }
        }
    }
</style>