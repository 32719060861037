function prepareQueryFilterParams(queryFilterParams) {
    return {
        params: {
            filter: Object.keys(queryFilterParams).reduce((acc, key) => {
                acc.push(`${key}:${queryFilterParams[key]}`);

                return acc;
            }, []).join(';')
        }
    }
}

const defaultQuery = {
    page: 0,
    params: {
        skip: 0,
        limit: 15
    }
}

export {
    defaultQuery,
    prepareQueryFilterParams
}