const maxHeightMixin = {
    data() {
        return {
            maxHeight: '',
            type: ''
        }
    },
    methods: {
        subscribeToResize(type) {
            this.type = type;
            window.addEventListener('resize', this.calcMaxHeight);
            this.calcMaxHeight();
        },

        unsubscribeFromResize() {
            window.removeEventListener('resize', this.calcMaxHeight);
        },

        calcMaxHeight() {
            this.maxHeight = this.$refs[this.type].offsetHeight;
        }
    },

    computed: {
        maxHeightScroll() {
            let maxHeight = '';

            switch (this.type) {
                case 'menu':
                    maxHeight = `${this.maxHeight - 60}px`;
                    break;
                case 'buildings':
                case 'premises':
                case 'equipments':
                case 'water':
                case 'elevators':
                case 'security':
                case 'electricity':
                //TODO: CHECK THIS!!!
                case 'balancerSystem':
                case 'balancerMonitoring':
                    maxHeight = `${this.maxHeight - 50}px`;
                    break;
                default:
                    maxHeight = `${this.maxHeight}px`;
            }

            return maxHeight;
        }
    }
};

export {
    maxHeightMixin
}