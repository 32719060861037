import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const balancerStates = [
    {
        id: 0,
        name: t('common.states.inActive'),
        background: 'var(--app-base-primary-15)'
    },
    {
        id: 1,
        name: t('common.states.success'),
        background: 'var(--system-green-15)'
    },
    {
        id: 2,
        name: t('common.states.allowable'),
        background: 'var(--system-yellow-15)'
    },
    {
        id: 3,
        name: t('common.states.attention'),
        background: 'var(--system-orange-15)'
    },
    {
        id: 4,
        name: t('common.states.critical'),
        background: 'var(--system-red-15)'
    }
]

const dashboardCardStates = [
    {
        id: 0,
        name: t('common.states.offline'),
        background: 'var(--app-base-primary-15)'
    },
    {
        id: 1,
        name: t('common.states.inActive'),
        background: 'var(--app-base-primary-15)'
    },
    {
        id: 2,
        name: t('common.states.operation'),
        background: 'var(--system-orange-15)'
    },
    {
        id: 3,
        name: t('common.states.critical'),
        background: 'var(--system-red-15)'
    },
    {
        id: 4,
        name: t('common.states.lineBreak'),
        background: 'var(--system-red-15)'
    },
    {
        id: 5,
        name: t('common.states.reverse'),
        background: 'var(--system-red-15)'
    },
    {
        id: 6,
        name: t('common.states.error'),
        background: 'var(--system-red-15)'
    },
    {
        id: 7,
        name: t('common.states.balanced'),
        background: 'var(--system-red-15)'
    }
]

export {
    balancerStates,
    dashboardCardStates
}