import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const premiseErrors = {
    building: t('validation.required'),
    type: t('validation.required'),
    floor: t('validation.required'),
    name: t('validation.required'),
}

function validatePremise(premise, building, type, floor) {
    let isValid = false;

    const errors = {
        building: '',
        type: '',
        floor: '',
        name: ''
    }

    const isValidBuilding = !!building;

    if (!isValidBuilding) {
        errors.building = premiseErrors.building;
    }

    const isValidType = !!type;

    if (!isValidType) {
        errors.type = premiseErrors.type;
    }

    const isValidFloor = !!floor;

    if (!isValidFloor) {
        errors.floor = premiseErrors.floor;
    }

    const isValidName = !!premise.name;

    if (!isValidName) {
        errors.name = premiseErrors.name;
    }

    isValid = isValidBuilding && isValidType && isValidFloor && isValidName;

    return { isValid, errors };
}

export {
    validatePremise
}