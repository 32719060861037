<template>
    <app-modal-wrapper>
        <div class="modal-elevator-turn-off-alarm">
            <div class="modal-elevator-turn-off-alarm__content">
                <h4>{{ $t('confirm.elevator.off') }}</h4>
            </div>
            <div class="modal-elevator-turn-off-alarm__control">
                <app-base-btn
                    :size="'full'"
                    :variation="'cancel'"
                    :text="$t('common.buttons.cancel')"
                    @action="closeModalElevatorTurnOffAlarm"
                />
                <app-base-btn
                    :type="'submit'"
                    :size="'full'"
                    :variation="'save'"
                    :text="$t('common.buttons.off')"
                />
            </div>
        </div>
    </app-modal-wrapper>
</template>

<script>
    export default {
        name: "ModalElevatorTurnOffAlarm",

        methods: {
            closeModalElevatorTurnOffAlarm() {
                this.closeModal('modalElevatorTurnOffAlarm');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-elevator-turn-off-alarm {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 574px;
        width: 100%;

        &__control {
            display: flex;
            margin-top: 45px;

            button:first-child {
                margin-right: 30px;
            }
        }
    }
</style>