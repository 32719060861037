<template>
    <div class="base-error">
        <span>{{ error.name }}</span>
    </div>
</template>

<script>
    import { errors, unknownError } from "../../../constants/errors";

    export default {
        name: "AppBaseError",

        props: {
            type: {
                type: String,
                required: true
            },
            code: {
                type: Number,
                required: true
            }
        },

        computed: {
            error() {
                const error = errors[this.type].find((error) => error.id === this.code);

                return error ? error : unknownError;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .base-error {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        border-radius: 100px;
        font-size: 12px;
        line-height: 14px;
        padding: 5px 15px;
        color: var(--app-base-primary);
        background: var(--system-red-15);;
        white-space: nowrap;
    }
</style>