<template>
    <div class="balancer-equipment__table">
        <div class="table__head">
            <div class="table__column balancer-equipment__building">
                <app-text-wrapper
                    :text="$t('balancer.table.building')"
                />
            </div>
            <div class="table__column balancer-equipment__premise-type">
                <app-text-wrapper
                    :text="$t('balancer.table.premiseType')"
                />
            </div>
            <div class="table__column balancer-equipment__entrance">
                <app-text-wrapper
                    :text="$t('balancer.table.entrance')"
                />
            </div>
            <div class="table__column balancer-equipment__floor">
                <app-text-wrapper
                    :text="$t('balancer.table.floor')"
                />
            </div>
            <div class="table__column balancer-equipment__premise-name">
                <app-text-wrapper
                    :text="$t('balancer.table.premiseName')"
                />
            </div>
            <div class="table__column balancer-equipment__temp-status">
                <app-text-wrapper
                    :text="$t('balancer.table.tempStatus')"
                />
            </div>
            <div class="table__column balancer-equipment__boiler-power">
                <app-text-wrapper
                    :text="$t('balancer.table.boilerPower')"
                />
            </div>
            <div class="table__column balancer-equipment__boiler-limit">
                <app-text-wrapper
                    :text="$t('balancer.table.boilerLimit')"
                />
            </div>
            <div class="table__column balancer-equipment__status">
                <app-text-wrapper
                    :text="$t('balancer.table.status')"
                />
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }">
            <balancer-equipment-row v-for="equipment in equipments" :key="equipment.id"
                :id="equipment.id"
                :building-name="equipment.buildingName"
                :premise-type="equipment.premiseType"
                :floor="equipment.floor"
                :entrance="equipment.entrance"
                :temp-status="equipment.tempStatus"
                :premise-name="equipment.premiseName"
                :boiler-current-power="equipment.boiler.currentPower"
                :boiler-max-power="equipment.boiler.maxPower"
                :boiler-limit="equipment.boiler.limit"
                :status="equipment.status"
                :errors="equipment.errors"
                @update="updateBalancerEquipment"
            />
        </div>
    </div>
</template>

<script>
    import BalancerEquipmentRow from "../elements/BalancerEquipmentRow";

    export default {
        name: "BalancerSystemTable",

        components: {
            BalancerEquipmentRow
        },

        props: {
            equipments: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            }
        },

        methods: {
            updateBalancerEquipment(id) {
                const equipment = this.equipments.find((equipment) => equipment.id === id);

                this.openModal({
                    name: 'modalBalancerEquipment',
                    selectedEl: equipment
                })
            }
        }
    }
</script>

<style lang="scss">
    .balancer-equipment {
        &__building {
            width: 14%;
        }

        &__premise-type {
            width: 10%;
        }

        &__entrance {
            width: 6%;
        }

        &__floor {
            width: 6%;
        }

        &__premise-name {
            width: 16%;
        }

        &__temp-status {
            width: 16%;
        }

        &__boiler-power {
            width: 12%;
        }

        &__boiler-limit {
            width: 12%;
        }

        &__status {
            width: 8%;
        }
    }
</style>