import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const qtyElectricitySwitchgearCounters = {
    inputs: 2,
    outputs: 2
}

const electricityCounterModels = [
    {
        id: 3,
        name: t('electricity.consumer.model.type')
    }
]

const electricityInputs = [
    {
        id: 1,
        name: '1'
    },
    {
        id: 2,
        name: '2'
    }
]

export {
    qtyElectricitySwitchgearCounters,
    electricityCounterModels,
    electricityInputs
}