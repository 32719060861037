<template>
    <app-modal-wrapper>
        <div class="modal-confirm">
            <h4>{{ title }}</h4>
            <div class="modal-confirm__control">
                <app-base-btn
                    :variation="'cancel'"
                    :text="type.cancelBtn"
                    @action="closeModalConfirm"
                />
                <app-base-btn
                    :variation="'delete'"
                    :text="type.btn"
                    @action="deleteElement"
                />
            </div>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import { types } from "../../constants/confirm";

    export default {
        name: "ModalConfirm",

        props: {
            selectedElement: {
                type: Object,
                required: true
            }
        },

        computed: {
            type() {
                return types[this.selectedElement.type];
            },

            title() {
                return this.selectedElement.name
                    ? `${this.type.text1} ${this.selectedElement.name} ${this.type.text2}`
                    : this.type.text;
            }
        },

        methods: {
            closeModalConfirm() {
                this.closeModal('modalConfirm');
            },

            deleteElement() {
                const event = this.type.event;
                const details = {
                    id: this.selectedElement.id,
                    name: this.selectedElement.name
                }

                this.emitter.emit(`${event}`, details);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-confirm {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 574px;
        max-height: 80vh;
        overflow-y: auto;
        scrollbar-width: thin;

        &__control {
            display: flex;
            align-items: center;
            margin-top: 45px;

            button {
                margin-right: 30px;
            }

            button:last-child {
                margin-right: 0;
            }
        }
    }
</style>