<template>
    <app-modal-wrapper>
        <form @submit.prevent="doActionElectricitySwitchgear" class="modal-electricity-switchgear">
            <div class="modal-electricity-switchgear__content">
                <div class="electricity-switchgear-form">
                    <h4>{{ title }}</h4>
                    <div class="electricity-switchgear-form__info">
                        <div class="electricity-switchgear-form__row">
                            <div class="electricity-switchgear-form__details">
                                <app-base-select
                                    :is-disabled="isUpdate"
                                    :label="$t('electricity.switchgear.building.label')"
                                    :place-holder="$t('electricity.switchgear.building.ph')"
                                    :selected-option="selectedBuilding"
                                    :options="buildings"
                                    :type="'text'"
                                    @select="selectBuilding"
                                />
                            </div>
                            <div class="electricity-switchgear-form__details">
                                <app-base-select ref="premise"
                                    :is-clear="true"
                                    :label="$t('electricity.switchgear.premise.label')"
                                    :place-holder="$t('electricity.switchgear.premise.ph')"
                                    :selected-option="selectedPremise"
                                    :options="premises"
                                    :type="'text'"
                                    :error-message="errors.premise"
                                    @select="selectPremise"
                                />
                            </div>
                        </div>
                        <div class="electricity-switchgear-form__row">
                            <div class="electricity-switchgear-form__details">
                                <app-base-input
                                    v-model="switchgear.name"
                                    :label="$t('electricity.switchgear.fullName.label')"
                                    :place-holder="$t('electricity.switchgear.fullName.ph')"
                                    :error-message="errors.name"
                                />
                            </div>
                            <div class="electricity-switchgear-form__details">
                                <div class="switchgear-counters">
                                    <div class="switchgear-counters__counter">
                                        <app-quantity-counter
                                            :label="$t('electricity.switchgear.qty.inputs')"
                                            :type="'input'"
                                            :value="switchgear.inputCounters.length"
                                            :is-disabled="true"
                                            @update="updateNumberCounters"
                                        />
                                    </div>
                                    <div class="switchgear-counters__counter">
                                        <app-quantity-counter
                                            :label="$t('electricity.switchgear.qty.outputs')"
                                            :type="'output'"
                                            :value="switchgear.outputCounters.length"
                                            :is-disabled="isUpdate"
                                            @update="updateNumberCounters"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="electricity-switchgear-form__row">
                            <div class="electricity-switchgear-form__details">
                                <app-base-select ref="controller"
                                    :label="$t('electricity.switchgear.controller.label')"
                                    :is-disabled="isUpdate"
                                    :place-holder="$t('electricity.switchgear.controller.ph.swId')"
                                    :selected-option="selectedController"
                                    :options="bcs"
                                    :type="'text'"
                                    :error-message="errors.controller"
                                    @select="selectController"
                                />
                            </div>
                            <div class="electricity-switchgear-form__details"></div>
                        </div>
                    </div>
                    <div class="electricity-switchgear-form__counters">
                        <switchgear-counter-form v-for="(counter, index) in switchgear.inputCounters" :key="counter.id"
                            :switchgear-id="switchgear.id"
                            :is-update="isUpdate"
                            :index="index"
                            :length="switchgear.inputCounters.length"
                            :type="'input'"
                            :id="counter.id"
                            :hw-id="counter.hwId"
                            :input-number="counter.inputNumber"
                            :coefficient="counter.coefficient"
                            :line="counter.line"
                            @update-counter="updateSwitchgearCounter"
                        />
                        <switchgear-counter-form v-for="(counter, index) in switchgear.outputCounters" :key="counter.id"
                            :switchgear-id="switchgear.id"
                            :is-update="isUpdate"
                            :index="index"
                            :length="switchgear.outputCounters.length"
                            :type="'output'"
                            :id="counter.id"
                            :name="counter.name"
                            :hwId="counter.hwId"
                            :input-number="counter.inputNumber"
                            :coefficient="counter.coefficient"
                            :line="counter.line"
                            :lines="lines"
                            @update-counter="updateSwitchgearCounter"
                        />
                    </div>
                </div>
            </div>
            <div class="modal-electricity-switchgear__control">
                <app-base-btn v-if="isUpdate"
                    :variation="'delete'"
                    :text="$t('common.buttons.delete')"
                    @action="openModalConfirm"
                />
                <app-base-btn
                    :variation="'cancel'"
                    :text="$t('common.buttons.cancel')"
                    @action="closeModalElectricitySwitchgear"
                />
                <app-base-btn
                    :type="'submit'"
                    :variation="'save'"
                    :text="$t('common.buttons.save')"
                />
            </div>
        </form>
    </app-modal-wrapper>
</template>

<script>
    import { mapGetters } from "vuex";
    import { greenHubAPI } from "../../../config/api";
    import { electricityToastsMixin } from "../../../mixins/toasts/electricity";
    import { qtyElectricitySwitchgearCounters } from "../../../constants/electricity";
    import cloneDeep from "lodash.clonedeep";
    import { prepareQueryFilterParams } from "../../../helpers/api";
    import { mapEquipmentsBCToSelect } from "../../../api/mappers/equipments";
    import { mapPremisesToSelect } from "../../../api/mappers/premises";
    import {getLinesFromSwitchgears, mapSwitchgearToSend} from "../../../api/mappers/electricity";
    import { clearDependentSelects } from "../../../helpers/selects";
    import { createCounterInputsList } from "../../../helpers/electricity";
    import { generateTemporaryId } from "../../../helpers/common";
    import { validateSwitchgear } from "../../../validations/electricity";
    import AppQuantityCounter from "../../common/local/AppQuantityCounter";
    import SwitchgearCounterForm from "../../electricity/elements/SwitchgearCounterForm";

    export default {
        name: "ModalElectricitySwitchgear",

        components: {
            AppQuantityCounter,
            SwitchgearCounterForm
        },

        props: {
            selectedSwitchgear: {
                type: Object
            }
        },

        mixins: [
            electricityToastsMixin
        ],

        data() {
            return {
                selectedBuilding: null,
                selectedPremise: null,
                selectedController: null,

                bcs: [],
                premises: [],
                lines: [],

                switchgear: {
                    id: '',
                    name: '',
                    inputCounters: [],
                    outputCounters: []
                },

                errors: {
                    premise: '',
                    name: '',
                    controller: ''
                }
            }
        },

        created() {
            this.checkUpdateSwitchgear();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings'
            }),

            isUpdate() {
                return !!this.selectedSwitchgear?.id;
            },

            title() {
                return !this.isUpdate
                    ? this.$t('electricity.switchgear.title.create')
                    : this.$t('electricity.switchgear.title.edit');
            }
        },

        methods: {
            checkUpdateSwitchgear() {
                if (this.isUpdate) {
                    this.switchgear = cloneDeep(this.selectedSwitchgear);
                } else {
                    this.initSystemCounters();
                }

                this.selectedBuilding = this.buildings.find((building) => building.id === this.selectedSwitchgear.buildingId);
                this.getAdditionalData();
            },

            getAdditionalData() {
                this.getPremises();
                this.getControllers();
                this.getLines();
            },

            getPremises() {
                const queryFilterParams = {
                    building_id: this.selectedBuilding.id
                };

                const params = prepareQueryFilterParams(queryFilterParams);

                greenHubAPI.get('/premises', params)
                    .then((result) => {
                        const { qty, premises } = result.data;
                        this.premises = mapPremisesToSelect(premises);

                        if (this.isUpdate) {
                            this.selectedPremise = this.premises.find((premise) => premise.id === this.selectedSwitchgear.premiseId);
                        }
                    })
            },

            getControllers() {
                const buildingId = this.selectedBuilding.id;

                greenHubAPI.get(`/equipments/${buildingId}`)
                    .then((result) => {
                        const controllers = result.data.buildingControllers;

                        this.bcs = mapEquipmentsBCToSelect(controllers);

                        if (this.isUpdate) {
                            this.selectedController = this.bcs.find((controller) => controller.id === this.selectedSwitchgear.parentSwId);
                        }
                    })
            },

            getLines() {
                const id = this.selectedBuilding.id;

                greenHubAPI.get(`electricity/${id}`)
                    .then((result) => {
                        const switchgears = result.data;
                        this.lines = getLinesFromSwitchgears(switchgears);
                    })
            },

            doActionElectricitySwitchgear() {
                this.clearSwitchgearErrors();
                const { isValid, errors } = validateSwitchgear(
                    this.switchgear,
                    this.selectedController
                );
                this.errors = errors;

                this.emitter.emit('validateSwitchgearCounter');
                const allSwitchgearCounters = this.switchgear.inputCounters.concat(this.switchgear.outputCounters);
                const isValidSwitchgearCounters =  allSwitchgearCounters.every((counter) => counter.isValid);

                let isValidSwitchgearOutputCountersUniqueLines = false;

                if (isValidSwitchgearCounters) {
                    isValidSwitchgearOutputCountersUniqueLines = this.checkIsUniqueOutputCounterLine(this.switchgear.outputCounters);
                }

                const isValidSwitchgear = isValid && isValidSwitchgearCounters && isValidSwitchgearOutputCountersUniqueLines;

                if (isValidSwitchgear) {
                    if (!this.isUpdate) {
                        this.createElectricitySwitchgear();
                    } else {
                        this.changeElectricitySwitchgear();
                    }
                }
            },

            createElectricitySwitchgear() {
                this.prepareDataSwitchgearFromSelects();
                const switchgearToSend = mapSwitchgearToSend(this.switchgear);

                greenHubAPI.post('/electricity/switchgear', switchgearToSend)
                    .then(() => {
                        this.updateElectricitySwitchgears();
                        this.showCreateSwitchgearToast();
                    })
            },

            changeElectricitySwitchgear() {
                this.prepareDataSwitchgearFromSelects();
                const id = this.switchgear.id;
                const switchgearToSend = mapSwitchgearToSend(this.switchgear, true);

                greenHubAPI.patch(`/electricity/switchgear/${id}`, switchgearToSend)
                    .then(() => {
                        this.updateElectricitySwitchgears();
                    })
            },

            checkIsUniqueOutputCounterLine(counters) {
                const lines = [];

                counters.forEach((counter) => {
                    lines.push(counter.line);
                })

                const notUniqueLines = lines.filter((line, index, array) => array.indexOf(line) !== index);
                const notUniqueLinesCounterIds = counters.filter((counter) => notUniqueLines.includes(counter.line)).map((counter) => counter.id);
                const isValidLines = !notUniqueLinesCounterIds.length;

                if (!isValidLines) {
                    this.emitter.emit('showNotUniqueLineError', notUniqueLinesCounterIds);
                }

                return isValidLines;
            },

            updateElectricitySwitchgears() {
                this.closeModalElectricitySwitchgear();
                this.emitter.emit('updateElectricitySwitchgears');
            },

            prepareDataSwitchgearFromSelects() {
                this.switchgear.buildingId = this.selectedBuilding.id;
                this.switchgear.parentSwId = this.selectedController.id;
                // //<--------OPTIONAL FIELD-------->//
                this.switchgear.premiseId = this.selectedPremise?.id || null;
                this.switchgear.floor = this.selectedPremise?.floor || null;
                this.switchgear.entrance = this.selectedPremise?.entrance || null;
            },

            selectBuilding(building) {
                this.selectedBuilding = building;

                this.clearDependentFields(this.$refs);
                this.getAdditionalData();
            },

            selectPremise(premise) {
                this.selectedPremise = premise;
            },

            selectController(controller) {
                this.selectedController = controller;
            },

            clearDependentFields(selects) {
                clearDependentSelects(selects);
                const refKeys = Object.keys(selects);

                this.$nextTick(() => {
                    refKeys.forEach((key) => selects[key].initSelect());
                })
            },

            clearSwitchgearErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            updateNumberCounters(type, action) {
                const targetCounters = type === 'input' ? this.switchgear.inputCounters : this.switchgear.outputCounters;

                if (action === 'increment') {
                    this.addCounter(type);
                } else {
                    targetCounters.length = targetCounters.length - 1;
                }

                if (type === 'input') {
                    this.generateInputCountersList();
                }
            },

            initSystemCounters() {
                const qtyInputCounters = qtyElectricitySwitchgearCounters.inputs;
                const qtyOutputCounters = qtyElectricitySwitchgearCounters.outputs;

                this.initCounters(qtyInputCounters, 'input');
                this.initCounters(qtyOutputCounters, 'output');

                setTimeout(() => {
                    //Need this timeout because i have timeouts for initCounters)
                    this.generateInputCountersList();
                }, 1000);
            },

            initCounters(number, type) {
                if (number > 0){
                    setTimeout(() => {
                        //Need this timeout for generate temporaryId)
                        this.addCounter(type);
                        this.initCounters(number - 1, type);
                    }, 10);
                }
            },

            addCounter(type) {
                const id = generateTemporaryId();

                const counter = {
                    id: id,
                    name: '',
                    type: type,
                    isValid: false,
                    hwId: '',
                    inputNumber: 1,
                    line: '',
                    coefficient: 1
                }

                const targetCounters = type === 'input' ? this.switchgear.inputCounters : this.switchgear.outputCounters;
                targetCounters.push(counter);
            },

            generateInputCountersList() {
                this.inputs = createCounterInputsList(this.switchgear.inputCounters.length);
            },

            updateSwitchgearCounter(switchgearCounter) {
                const targetCounters = switchgearCounter.type === 'input' ? this.switchgear.inputCounters : this.switchgear.outputCounters;
                let targetCounter = targetCounters.find((counter) => counter.id === switchgearCounter.id);

                //TODO: MAYBE SOMETHING ELSE?
                targetCounter.name = switchgearCounter.name;
                targetCounter.hwId = switchgearCounter.hwId;
                targetCounter.isValid = switchgearCounter.isValid;
                targetCounter.inputNumber = switchgearCounter.inputNumber;
                targetCounter.coefficient = switchgearCounter.coefficient;
                targetCounter.line = switchgearCounter.line;
            },

            closeModalElectricitySwitchgear() {
                this.closeModal('modalElectricitySwitchgear');
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.switchgear.id,
                    name: this.switchgear.name,
                    type: 'switchgear'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .modal-electricity-switchgear {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;
        max-height: 80vh;
        overflow-y: auto;

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 15px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    .electricity-switchgear-form {
        &__info {
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            &:not(:last-child) {
                padding-bottom: 30px;
                border-bottom: 1px solid var(--app-border);
            }
        }

        &__row {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__counters {
            display: flex;
            flex-direction: column;
        }

        &__error {
            margin-top: 10px;
            font-size: 14px;
            color: var(--system-red);
        }
    }

    .switchgear-counters {
        display: flex;

        &__counter {
            flex-basis: 50%;
        }

        &__counter:first-child {
            margin-right: 30px;
        }
    }
</style>

<style lang="scss">
    .electricity-switchgear-form {
        .switchgear-counter-form {
            &:not(:last-child) {
                border-bottom: 1px solid var(--app-border);
            }
        }
    }
</style>