import { showToast } from "../../helpers/toasts";

const securityToastsMixin = {
    methods: {
        showDeleteSecurityObjectToast(name) {
            const deleteToastTitle = this.$t('toasts.security.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.security.delete.details1`)}
                    ${name}
                    ${this.$t(`toasts.security.delete.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showCreateSecurityObjectToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.security.create.details')
            );
        }
    }
}

export {
    securityToastsMixin
}