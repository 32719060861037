import { createFloorsList } from "../../helpers/buildings";

function mapBuildingsToView(buildings) {
    return buildings.map((building) => {
        return {
            id: building._id,
            name: building.name,
            logo: building.logo ? building.logo : '',
            entrances: building.entrances,
            minFloor: building.min_floor,
            maxFloor: building.max_floor,
            isZeroFloor: building.ground_floor,
            floors: createFloorsList(building.min_floor, building.max_floor, building.ground_floor).length
        }
    })
}

function mapBuildingToSend(building) {
    return {
        name: building.name,
        entrances: Number(building.entrances),
        min_floor: Number(building.minFloor),
        max_floor: Number(building.maxFloor),
        ground_floor: building.isZeroFloor
    }
}

export {
    mapBuildingsToView,
    mapBuildingToSend
}