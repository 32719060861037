<template>
    <div class="switch-toggle"
        :class="{
            'switch-toggle--is-active': isActive
        }"
    >
        <div class="switch-toggle__label">
            {{ label }}
        </div>
        <div class="switch-toggle__element"  @click="handlerClickToggle">
            <div class="switch-toggle__circle"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppBaseSwitchToggle",

        props: {
            isActive: {
                type: Boolean,
                required: true
            },
            label: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                isToggled: false
            }
        },

        created() {
            this.initSwitchToggle();
        },

        methods: {
            initSwitchToggle() {
                this.isToggled = this.isActive;
            },

            handlerClickToggle() {
                this.isToggled = !this.isToggled;
                this.$emit('update');
            },
        }
    }
</script>

<style lang="scss" scoped>
    .switch-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__label {
            font-size: 16px;
            color: var(--app-base-primary);
        }

        &__element {
            width: 48px;
            height: 25px;
            display: flex;
            align-items: center;
            padding: 2px;
            border-radius: 100px;
            background: var(--app-border);
            margin-right: 15px;

            &:hover {
                cursor: pointer;
            }
        }

        &__circle {
            width: 21px;
            height: 21px;
            border-radius: 100px;
            background: var(--system-white);
        }

        &--is-active {
            .switch-toggle__element {
                background: var(--brand-main);
                justify-content: end;
            }
        }
    }
</style>