<template>
    <app-modal-wrapper>
        <form @submit.prevent="doActionEquipmentDC" class="modal-equipment-dc">
            <div class="modal-equipment-dc__content">
                <div class="equipment-dc-form">
                    <h4>{{ title }}</h4>
                    <div class="equipment-dc-form__info">
                        <div class="equipment-dc-form__row">
                            <div class="equipment-dc-form__details">
                                <app-base-select
                                    :label="$t('equipments.equipment.bc.label')"
                                    :place-holder="$t('equipments.equipment.bc.ph')"
                                    :selected-option="selectedBC"
                                    :options="bcs"
                                    :type="'text'"
                                    :error-message="errors.bc"
                                    @select="selectBC"
                                />
                            </div>
                            <div class="equipment-dc-form__details">
                                <div class="equipment-dc-form__sm">
                                    <app-base-switch-toggle
                                        :is-active="dc.isInstalledSM"
                                        :label="$t('equipments.equipment.sm.isInstalled')"
                                        @update="updateIsInstalledSM"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="equipment-dc-form__info">
                        <div class="equipment-dc-form__row">
                            <div class="equipment-dc-form__details">
                                <app-base-input
                                    v-model="dc.name"
                                    :label="$t('equipments.equipment.name.label')"
                                    :place-holder="$t('equipments.equipment.name.ph')"
                                    :error-message="errors.name"
                                />
                            </div>
                            <div class="equipment-dc-form__details">
                                <app-base-select
                                    :is-clear="true"
                                    :is-required="false"
                                    :open-direction="'top'"
                                    :label="$t('equipments.equipment.entrance.label')"
                                    :place-holder="$t('equipments.equipment.entrance.ph')"
                                    :selected-option="selectedEntrance"
                                    :options="entrances"
                                    :type="'text'"
                                    @select="selectEntrance"
                                />
                            </div>
                        </div>
                        <div class="equipment-dc-form__row">
                            <div class="equipment-dc-form__details">
                                <app-base-input
                                    v-model="dc.serialNumber"
                                    :label="$t('equipments.equipment.serialNumber.label')"
                                    :place-holder="$t('equipments.equipment.serialNumber.ph')"
                                    :error-message="errors.serialNumber"
                                />
                            </div>
                            <div class="equipment-dc-form__details">
                                <app-base-select
                                    :open-direction="'top'"
                                    :label="$t('equipments.equipment.floor.label')"
                                    :place-holder="$t('equipments.equipment.floor.ph')"
                                    :selected-option="selectedFloor"
                                    :options="floors"
                                    :type="'text'"
                                    :error-message="errors.floor"
                                    @select="selectFloor"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-equipment-dc__control">
                <app-base-btn v-if="isUpdate"
                    :variation="'delete'"
                    :text="$t('common.buttons.delete')"
                    @action="openModalConfirm"
                />
                <app-base-btn
                    :variation="'cancel'"
                    :text="$t('common.buttons.cancel')"
                    @action="closeModalEquipmentDC"
                />
                <app-base-btn
                    :type="'submit'"
                    :variation="'save'"
                    :text="$t('common.buttons.save')"
                />
            </div>
        </form>
    </app-modal-wrapper>
</template>

<script>
    import { mapGetters } from "vuex";
    import { mapEquipmentDCToSend } from "../../../api/mappers/equipments";
    import { validateEquipmentsDC } from "../../../validations/equipments";
    import { greenHubAPI } from "../../../config/api";
    import { equipmentsToastsMixin } from "../../../mixins/toasts/equipments";

    export default {
        name: "ModalEquipmentDC",

        props: {
            selectedDC: {
                type: Object
            }
        },

        data() {
            return {
                selectedBuilding: null,
                bcs: [],

                selectedBC: null,
                selectedEntrance: null,
                selectedFloor: null,

                dc: {
                    name: '',
                    serialNumber: '',
                    isInstalledSM: false
                },

                errors: {
                    bc: '',
                    name: '',
                    serialNumber: '',
                    floor: ''
                }
            }
        },

        mixins: [
            equipmentsToastsMixin
        ],

        created() {
            this.checkUpdateDC();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings'
            }),

            isUpdate() {
                return !!this.selectedDC?.id;
            },

            title() {
                return !this.isUpdate
                    ? this.$t('equipments.equipment.dc.title.create')
                    : this.$t('equipments.equipment.dc.title.edit');
            },

            entrances() {
                return this.selectedBuilding?.entrances || [];
            },

            floors() {
                return this.selectedBuilding?.floors || [];
            },
        },

        methods: {
            checkUpdateDC() {
                const buildingId = this.selectedDC.buildingId;
                this.selectedBuilding = this.buildings.find((building) => building.id === buildingId);

                this.bcs = this.selectedDC.bcs;

                if (this.isUpdate) {
                    this.dc = { ...this.selectedDC };

                    if (this.dc.entrance) {
                        this.selectedEntrance = this.entrances.find((entrance) => entrance.id === this.dc.entrance);
                    }

                    this.selectedBC = this.bcs.find((bc) => bc.id === this.dc.bcId);
                    this.selectedFloor = this.floors.find((entrance) => entrance.id === this.dc.floor);
                }
            },

            doActionEquipmentDC() {
                this.clearEquipmentDCErrors();
                const { isValid, errors } = validateEquipmentsDC(this.dc, this.selectedBC, this.selectedFloor);
                this.errors = errors;

                if (isValid) {
                    if (!this.isUpdate) {
                        this.createEquipmentDC();
                    } else {
                        this.changeEquipmentDC();
                    }
                }
            },

            createEquipmentDC() {
                this.prepareDataDCFromSelects();
                const dcToSend = mapEquipmentDCToSend(this.dc);

                greenHubAPI.post('/equipments/dispatching_controllers', dcToSend)
                    .then(() => {
                        this.updateEquipments();
                        this.showCreateEquipmentsDCToast();
                    })
            },

            changeEquipmentDC() {
                this.prepareDataDCFromSelects();
                const id = this.dc.id;
                const dcToSend = mapEquipmentDCToSend(this.dc);

                greenHubAPI.patch(`/equipments/dispatching_controllers/${id}`, dcToSend)
                    .then(() => {
                        this.updateEquipments();
                    })
            },

            prepareDataDCFromSelects() {
                this.dc.bcId = this.selectedBC.id;
                this.dc.floor = this.selectedFloor.id;
                //<--------OPTIONAL FIELD-------->//
                this.dc.entrance = this.selectedEntrance?.id || null;
            },

            updateEquipments() {
                this.closeModalEquipmentDC();
                this.emitter.emit('updateEquipments');
            },

            selectBC(bc) {
                this.selectedBC = bc;
            },

            selectEntrance(entrance) {
                this.selectedEntrance = entrance;
            },

            selectFloor(floor) {
                this.selectedFloor = floor;
            },

            updateIsInstalledSM() {
                this.dc.isInstalledSM = !this.dc.isInstalledSM;
            },

            clearEquipmentDCErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.selectedDC.id,
                    name: this.selectedDC.name,
                    type: 'equipmentDC'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            },

            closeModalEquipmentDC() {
                this.closeModal('modalEquipmentDC');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-equipment-dc {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;
        max-height: 80vh;
        overflow-y: auto;

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    .equipment-dc-form {
        &__info {
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            &:not(:last-child) {
                padding-bottom: 30px;
                border-bottom: 1px solid var(--app-border);
            }
        }

        &__row {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__sm {
            padding-top: 36px;
        }
    }
</style>