<template>
    <div class="list-item">
        <div class="list-item__text can-overflow"
             :class="{
                'list-item__text--is-disabled': isDisabled
             }"
        >
            <app-base-input
                v-model="itemText"
            />
        </div>
        <div class="list-item__control">
            <app-circle-btn
                @action="removeItem"
            >
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0833 3.739L10.2608 2.9165L6.99996 6.17734L3.73913 2.9165L2.91663 3.739L6.17746 6.99984L2.91663 10.2607L3.73913 11.0832L6.99996 7.82234L10.2608 11.0832L11.0833 10.2607L7.82246 6.99984L11.0833 3.739Z" fill="#3A3A3A"/>
                </svg>
            </app-circle-btn>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppListItem",

        props: {
            id: {
                type: [Number, String],
                required: true
            },
            text: {
                type: String,
                required: true
            },
            isDisabled: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                itemText: ''
            }
        },

        created() {
            this.initData();
        },

        watch: {
            itemText(text) {
                if (text) {
                    this.updateItem(text, this.id);
                }
            },
        },

        methods: {
            initData() {
                this.itemText = this.text;
            },

            removeItem() {
                this.$emit('remove', this.id);
            },

            updateItem(text, id) {
                this.$emit('update', text, id);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 12px;

        &__text {
            width: 100%;

            .data {
                margin-bottom: 0 !important;
            }

            &--is-disabled {
                pointer-events: none;
            }
        }

        &__control {
            margin-left: 30px;
        }
    }
</style>

<style lang="scss">
    .list-item__text {
        input {
            text-overflow: ellipsis;
        }
    }
</style>