<template>
    <div class="limit-row">
        <div class="limit"
             :class="{
                'limit--is-edited': isEditLimit,
                'limit--is-error': hasError
            }"
        >
            <div class="limit__value" ref="limit" tabindex="1"
                :contenteditable="isEditLimit"
                @click.stop=""
                @focus="clearLimitErrors"
            >
                {{ formattedConsumptionsValue }}
            </div>
            <div>{{ $t('common.units.power') }}</div>
            <div v-if="hasError" class="limit__warning">
                <div class="limit__description">
                    <div class="limit__error-icon">
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="7.5" cy="7.5" r="7.5" fill="#FF3B30"/>
                            <path d="M8.2998 9.1626H6.77637L6.56055 2.75781H8.51562L8.2998 9.1626ZM7.53809 10.1655C7.84701 10.1655 8.09456 10.2565 8.28076 10.4385C8.47119 10.6204 8.56641 10.8532 8.56641 11.1367C8.56641 11.416 8.47119 11.6466 8.28076 11.8286C8.09456 12.0106 7.84701 12.1016 7.53809 12.1016C7.2334 12.1016 6.98584 12.0106 6.79541 11.8286C6.60921 11.6466 6.51611 11.416 6.51611 11.1367C6.51611 10.8574 6.60921 10.6268 6.79541 10.4448C6.98584 10.2586 7.2334 10.1655 7.53809 10.1655Z" fill="white"/>
                        </svg>
                    </div>
                </div>
                <div class="limit__warning-info">
                    <div v-if="hasError" class="limit__warning-info-text">
                        {{ errors.limit }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { validateBalancerLimit } from "../../../validations/balancer";

    export default {
        name: "AppBaseLimit",

        props: {
            isEditLimit: {
                type: Boolean,
                required: true
            },
            type: {
                type: String,
                required: true,
                validator(type) {
                    return ['nominal', 'accident'].includes(type);
                }
            },
            value: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                newValue: 0,

                errors: {
                    limit: ''
                }
            }
        },

        created() {
            this.initLimitValue();
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        computed: {
            hasError() {
                return !!this.errors.limit;
            },

            formattedConsumptionsValue() {
                return this.newValue.toFixed(1);
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('validateAndUpdateLimits', this.validateAndUpdateLimits);
            },

            unsubscribeFromEvents() {
                this.emitter.off('validateAndUpdateLimits', this.validateAndUpdateLimits);
            },

            initLimitValue() {
                this.newValue = this.value;
            },

            validateAndUpdateLimits(type) {
                if (type === this.type) {
                    this.clearLimitErrors();
                    const newValue = this.$refs.limit.innerText;

                    const { isValid, errors } = validateBalancerLimit(newValue);
                    this.errors = errors;

                    this.$emit('update', type, isValid, newValue);
                }
            },

            clearLimitErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .limit-row {
        display: flex;
    }

    .limit {
        display: flex;
        align-items: center;
        position: relative;

        &__value {
            margin-right: 4px;
        }

        &__warning {
            position: absolute;
            right: -5px;
            top: -10px;
            font-style: normal;
            font-weight: normal;

            &:hover {
                cursor: pointer;
            }

            &:hover .limit__warning-info {
                visibility: visible;
                opacity: 1;
            }
        }

        &__warning-info {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: -8px;
            z-index: 10;
            padding-left: 25px;
        }

        &__warning-info-text {
            padding: 8px;
            background: var(--system-grey);
            border-radius: 8px;
            color: var(--system-white);
            font-size: 12px;
            letter-spacing: 0.3px;
            text-align: center;
            white-space: nowrap;
        }

        &__description {
            position: relative;
        }

        &__error-icon {
            position: absolute;
        }

        &--is-edited {
            border-bottom: 1px solid var(--app-base-primary);
            border-top: 1px solid transparent;
        }

        &--is-error {
            color: var(--system-red);
            border-bottom: 1px solid var(--system-red);
            border-top: 1px solid transparent;
        }
    }
</style>