function mapPremisesToView(premises) {
    return premises.map((premise) => {
        return {
            id: premise._id,
            buildingId: premise.building_id,
            buildingName: premise.building_name,
            name: premise.name,
            type: premise.type,
            floor: premise.floor,
            entrance: premise.entrance
        }
    })
}

function mapPremisesToSelect(premises) {
    return premises.map((premise) => {
        return {
            id: premise._id,
            name: premise.name,
            floor: premise.floor,
            entrance: premise.entrance
        }
    })
}


function mapPremiseToSend(premise) {
    return {
        building_id: premise.buildingId,
        type: premise.type,
        floor: premise.floor,
        name: premise.name,
        entrance: premise.entrance
    }
}

export {
    mapPremisesToView,
    mapPremisesToSelect,
    mapPremiseToSend
}