<template>
    <div class="system-state" :style="{'backgroundColor': typeColor}">
        <div class="system-state__content">
            <div class="system-state__icon">
                <svg v-if="element === 'security'" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25 2.08337L6.25 10.4167V22.9167C6.25 34.4792 14.25 45.2917 25 47.9167C35.75 45.2917 43.75 34.4792 43.75 22.9167V10.4167L25 2.08337ZM39.5833 22.9167C39.5833 32.3334 33.375 41.0209 25 43.6042C16.625 41.0209 10.4167 32.3334 10.4167 22.9167V13.125L25 6.64587L39.5833 13.125V22.9167ZM15.4375 24.1459L12.5 27.0834L20.8333 35.4167L37.5 18.75L34.5625 15.7917L20.8333 29.5209L15.4375 24.1459Z" :fill="iconColor"/>
                </svg>
                <svg v-else-if="element === 'elevator'" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M39.5833 10.4167V39.5833H10.4167V10.4167H39.5833ZM39.5833 6.25H10.4167C8.125 6.25 6.25 8.125 6.25 10.4167V39.5833C6.25 41.875 8.125 43.75 10.4167 43.75H39.5833C41.875 43.75 43.75 41.875 43.75 39.5833V10.4167C43.75 8.125 41.875 6.25 39.5833 6.25ZM20.8333 37.5V29.1667H22.9167V23.9583C22.9167 21.6667 21.0417 19.7917 18.75 19.7917H16.6667C14.375 19.7917 12.5 21.6667 12.5 23.9583V29.1667H14.5833V37.5H20.8333ZM17.7083 17.7083C19.1458 17.7083 20.3125 16.5417 20.3125 15.1042C20.3125 13.6667 19.1458 12.5 17.7083 12.5C16.2708 12.5 15.1042 13.6667 15.1042 15.1042C15.1042 16.5417 16.2708 17.7083 17.7083 17.7083ZM37.5 22.9167L32.2917 14.5833L27.0833 22.9167H37.5ZM27.0833 27.0833L32.2917 35.4167L37.5 27.0833H27.0833Z" :fill="iconColor"/>
                </svg>
                <svg v-else-if="element === 'electricity'" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.9073 43.75H20.824L22.9073 29.1667H15.6157C13.7823 29.1667 14.9282 27.6042 14.9698 27.5417C17.6573 22.7917 21.699 15.7083 27.0948 6.25H29.1782L27.0948 20.8333H34.4073C35.2407 20.8333 35.699 21.2292 35.2407 22.2083C27.0115 36.5625 22.9073 43.75 22.9073 43.75Z" :fill="iconColor"/>
                </svg>
                <svg v-else-if="element === 'water'" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.9999 4.16663C13.8958 13.6458 8.33325 21.8333 8.33325 28.75C8.33325 39.125 16.2499 45.8333 24.9999 45.8333C33.7499 45.8333 41.6666 39.125 41.6666 28.75C41.6666 21.8333 36.1041 13.6458 24.9999 4.16663ZM24.9999 41.6666C18.0208 41.6666 12.4999 36.3125 12.4999 28.75C12.4999 23.875 16.5624 17.4166 24.9999 9.70829C33.4374 17.4166 37.4999 23.8541 37.4999 28.75C37.4999 36.3125 31.9791 41.6666 24.9999 41.6666Z" :fill="iconColor"/>
                </svg>
                <svg v-else-if="element === 'warning'" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.9167 31.2498H27.0834V35.4165H22.9167V31.2498ZM22.9167 14.5832H27.0834V27.0832H22.9167V14.5832ZM24.9792 4.1665C13.4792 4.1665 4.16675 13.4998 4.16675 24.9998C4.16675 36.4998 13.4792 45.8332 24.9792 45.8332C36.5001 45.8332 45.8334 36.4998 45.8334 24.9998C45.8334 13.4998 36.5001 4.1665 24.9792 4.1665ZM25.0001 41.6665C15.7917 41.6665 8.33341 34.2082 8.33341 24.9998C8.33341 15.7915 15.7917 8.33317 25.0001 8.33317C34.2084 8.33317 41.6667 15.7915 41.6667 24.9998C41.6667 34.2082 34.2084 41.6665 25.0001 41.6665Z" :fill="iconColor"/>
                </svg>
            </div>
            <div class="system-state__info">
                <div class="system-state__details">
                    <div class="system-state__element">
                        {{ elementName }}
                    </div>
                    <div class="system-state__errors">
                        {{ errorMessage }}
                    </div>
                </div>
                <div class="system-state__control-btn">
                    <button type="button">
                        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.36987 21.573L8.21362 23.4167L18.6303 13L8.21362 2.58337L6.36987 4.42712L14.9428 13L6.36987 21.573Z" :fill="iconColor"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppSystemCardState",

        props: {
            id: {
                type: String,
                required: true
            },
            element: {
                type: String,
                required: true
            },
            type: {
                type: Number,
                required: true
            },
            errorsQty: {
                type: Number,
                required: true
            }
        },

        computed: {
            typeColor() {
                return this.type === 0
                    ? 'var(--system-green-15)'
                    : 'var(--system-orange-15)'
            },

            iconColor() {
                return this.type === 0
                    ? 'var(--system-green)'
                    : 'var(--system-orange)'
            },

            elementName() {
                return this.$t(`dashboard.elements.${this.element}`);
            },

            errorMessage() {
                return this.errorsQty
                    ? `${this.$t('common.states.errors')} ${this.errorsQty}`
                    : this.$t('common.states.notErrors');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .system-state {
        border-radius: 15px;

        &__icon {
            padding: 28px;
            border-radius: 13px;
            background: var(--system-white);
            width: 106px;
            height: 106px;
            margin-right: 15px;
        }

        &__content {
            padding: 15px;
            display: flex;
        }

        &__info {
            flex-grow: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__element {
            font-size: 28px;
            font-weight: 600;
            margin-bottom: 15px;

            @media screen and (max-width: 1600px) {
                font-size: 24px;
            }
        }

        &__errors {
            font-size: 20px;

            @media screen and (max-width: 1600px) {
                font-size: 18px;
            }
        }

        &__control-btn {
            width: 25px;
            height: 25px;
        }
    }
</style>