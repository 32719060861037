import { validatePositiveNumber } from "./common";
import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const waterPadErrors = {
    bc: t('validation.required'),
    counterId: t('validation.required'),
    premise: t('validation.required'),
    hwId: t('validation.required'),
    parent: t('validation.required'),
    name: t('validation.required'),
    readings: t('validation.positiveNumber'),
}

function validateWaterPad(pad, bc, premise, type, parent) {
    let isValid = false;

    const errors = {
        bc: '',
        counterId: '',
        premise: '',
        hwId: '',
        parent: '',
        name: ''
    }

    const isValidBC = !!bc;

    if (!isValidBC) {
        errors.bc = waterPadErrors.bc;
    }

    const isValidCounterId = !!pad.counterId;

    if (!isValidCounterId) {
        errors.counterId = waterPadErrors.counterId;
    }

    const isValidPremise = !!premise;

    if (!isValidPremise) {
        errors.premise = waterPadErrors.premise;
    }

    const isValidHwId = !!pad.hwId;

    if (!isValidHwId) {
        errors.hwId = waterPadErrors.hwId;
    }

    let isValidParent = true;

    if (type !== 1) {
        const isValidPadParent = !!parent;

        if (!isValidPadParent) {
            errors.parent = waterPadErrors.parent;
            isValidParent = false;
        }
    }

    let isValidName = true;

    if (type !== 3) {
        const isValidPadName = !!pad.name;

        if (!isValidPadName) {
            errors.name = waterPadErrors.name;
            isValidName = false;
        }
    }

    isValid = isValidBC && isValidCounterId && isValidPremise && isValidHwId && isValidParent && isValidName;

    return { isValid, errors };
}

function validateSetReadingsWaterPad(readings) {
    const errors = {
        readings: ''
    }

    const isValid = validatePositiveNumber(readings);

    if (!isValid) {
        errors.readings = waterPadErrors.readings
    }

    return { isValid, errors };
}

export {
    validateWaterPad,
    validateSetReadingsWaterPad
}