<template>
    <div class="table__row building-row" @click="handlerClick">
        <div class="table__column building__name">
            <app-text-wrapper
                :text="name"
                :sub-text="searchSubString"
                :is-match="true"
            />
        </div>
        <div class="table__column building__logo">
            <building-logo-wrapper
                :logo-url="logoUrl"
                :name="name"
            />
        </div>
        <div class="table__column building__entrances">
            {{ entrances }}
        </div>
        <div class="table__column building__floors">
            {{ maxFloor }}
        </div>
    </div>
</template>

<script>
    import BuildingLogoWrapper from "./BuildingLogoWrapper";

    export default {
        name: "BuildingRow",

        components: {
            BuildingLogoWrapper
        },

        props: {
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            logoUrl: {
                type: String,
                required: true
            },
            entrances: {
                type: Number,
                required: true
            },
            maxFloor: {
                type: Number,
                required: true
            },
            floors: {
                type: Number,
                required: true
            }
        },

        inject: {
            subString: {
                default: ''
            }
        },

        data() {
            return {
                searchSubString: this.subString
            }
        },

        methods: {
            handlerClick() {
                this.$emit('update', this.id);
            }
        }
    }
</script>

<style lang="scss">
    .building-row {
        &:hover {
            cursor: pointer;
        }
    }
</style>
