<template>
    <div class="security">
        <div class="security__panel">
            <div class="security__title">
                {{ $t('security.title') }}
            </div>
            <div class="security__actions">
                <app-add-btn
                    @open-modal="openModalSecurityObject"
                />
            </div>
        </div>
        <div class="security__control">
            <security-filters
                :qty="securityObjects.length"
                @update-query-params="updateQueryParams"
            />
        </div>
        <div class="security__content" ref="security">
            <div v-if="showTable" class="security__table">
                <security-table
                    :security-objects="securityObjects"
                    :max-height="maxHeightScroll"
                />
            </div>
            <div v-if="showEmpty" class="security__empty">
                <app-empty-content
                    :content-type="'security'"
                    @open-modal="openModalSecurityObject"
                />
            </div>
            <div v-if="showNoResults" class="security__empty">
                <app-no-results
                    @clear="clearQueryParams"
                />
            </div>
            <div v-if="isLoaded" class="elevators__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { computed } from "vue";
    import { greenHubAPI } from "../../config/api";
    import { maxHeightMixin } from "../../mixins/calcMaxHeight";
    import { securityToastsMixin } from "../../mixins/toasts/security";
    import { mapSecurityObjectsToView } from "../../api/mappers/security";
    import SecurityFilters from "./SecurityFilters";
    import SecurityTable from "./SecurityTable";

    export default {
        name: "SecurityControl",

        components: {
            SecurityTable,
            SecurityFilters
        },

        mixins: [
            maxHeightMixin,
            securityToastsMixin
        ],

        data() {
            return {
                isLoaded: false,
                timerId: '',
                securityObjects: [],

                query: {
                    params: null
                }
            }
        },

        provide() {
            return {
                subString: computed(() => this.searchSubString)
            }
        },

        created() {
            this.updateSecurityObjects();

            this.timerId = setInterval(() => {
                this.getSecurityObjects();
            }, 5000);
        },

        mounted() {
            this.subscribeToResize('security');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
            clearInterval(this.timerId);
        },

        computed: {
            isExistSecurityObjects() {
                return !!this.securityObjects.length;
            },

            showTable() {
                return this.isExistSecurityObjects && !this.isLoaded;
            },

            showEmpty() {
                return !this.isExistSecurityObjects && !this.isLoaded && !this.isExistQueryParams;
            },

            showNoResults() {
                return !this.isExistSecurityObjects && !this.isLoaded && this.isExistQueryParams;
            },

            isExistQueryParams() {
                return this.searchSubString || this.filterSubString;
            },

            searchSubString() {
                return this.query.params?.match ? this.query.params?.match : '';
            },

            filterSubString() {
                return this.query.params?.filter ? this.query.params?.filter : '';
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateSecurityObjects', this.updateSecurityObjects);
                this.emitter.on('deleteSecurityObject', this.deleteSecurityObject);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateSecurityObjects', this.updateSecurityObjects);
                this.emitter.off('deleteSecurityObject', this.deleteSecurityObject);
            },

            getSecurityObjects() {
                const params = {
                    params: this.query.params
                };

                greenHubAPI.get('/security', params)
                    .then((result) => {
                        this.securityObjects = mapSecurityObjectsToView(result.data);

                        this.emitter.emit('updateShowQty');
                        this.$nextTick(() => {
                            this.calcMaxHeight();
                        });
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            clearQueryParams() {
                this.emitter.emit('clearQueryParams');
            },

            deleteSecurityObject({ id, name }) {
                greenHubAPI.delete(`/security/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.closeModalSecurityObject();
                        this.showDeleteSecurityObjectToast(name);
                        this.updateSecurityObjects();
                    })
            },

            updateQueryParams(params) {
                this.query.params = params;
                this.updateSecurityObjects();
            },

            openModalSecurityObject() {
                this.openModal({
                    name: 'modalSecurityObject'
                })
            },

            closeModalSecurityObject() {
                this.closeModal('modalSecurityObject');
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            },

            updateSecurityObjects() {
                this.isLoaded = true;
                this.getSecurityObjects();
            }
        }
    }
</script>

<style lang="scss">
    .security-object {
        &__name {
            width: 22%;
        }

        &__building {
            width: 24%;
        }

        &__entrance {
            width: 10%;
        }

        &__floor {
            width: 10%;
        }

        &__channel {
            width: 16%;
        }

        &__state {
            width: 10%;
        }

        &__status {
            width: 8%;
        }
    }
</style>