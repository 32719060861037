import i18n from "../../../src/localization/i18n"
const { t } = i18n.global;
import { greenHubAPI } from "../../config/api";
import { createEntrancesList, createFloorsList } from "../../helpers/buildings";
import { mapBuildingsToView } from "../../api/mappers/buildings";
import { premiseTypes } from "../../constants/premises";
import {
    balancerStates
} from "../../constants/states";

import { defaultDeviceStatuses } from "../../constants/statuses";

const buildings = {
    namespaced: true,

    state: () => ({
        isLoaded: false,
        buildings: []
    }),

    getters: {
        isLoadedBuildings(state) {
            return state.isLoaded;
        },

        mappedBuildings(state) {
            return state.buildings.map((building) => {
                return {
                    id: building.id,
                    name: building.name,
                    entrances: createEntrancesList(parseInt(building.entrances)),
                    floors: createFloorsList(
                        building.minFloor,
                        building.maxFloor,
                        building.isZeroFloor
                    )
                }
            })
        },

        mappedEntrances(state) {
            if (state.buildings.length) {
                let maxEntrances = 1;

                state.buildings.forEach((building) => {
                    if (building.entrances > maxEntrances) {
                        maxEntrances = building.entrances;
                    }
                })

                return createEntrancesList(parseInt(maxEntrances))
                        .map((entrance) => {
                            return {
                                id: entrance.id,
                                name: `${entrance.name} ${t('filters.entrance')}`
                            }
                        });
            } else {
                return [];
            }
        },

        mappedFloors(state) {
            if (state.buildings.length) {
                const initBuilding =  state.buildings[0];
                let { minFloor, maxFloor, isZeroFloor } = { ...initBuilding };

                state.buildings.forEach((building) => {
                    if (building.minFloor < minFloor) {
                        minFloor = building.minFloor;
                    }

                    if (building.maxFloor > maxFloor) {
                        maxFloor = building.maxFloor;
                    }

                    if (!isZeroFloor && building.isZeroFloor) {
                        isZeroFloor = true;
                    }
                })

                return createFloorsList(minFloor, maxFloor, isZeroFloor)
                    .map((floor) => {
                        return {
                            id: floor.id,
                            name: `${floor.name} ${t('filters.floor')}`
                        }
                    });
            } else {
                return [];
            }
        }
    },

    mutations: {
        setBuildings(state, buildings) {
            state.buildings = buildings;
        },

        setIsLoaded(state) {
            state.isLoaded = state;
        }
    },

    actions: {
        getBuildings({ commit, getters }) {
            greenHubAPI.get('/buildings')
                .then((result) => {
                    const buildings = mapBuildingsToView(result.data.buildings);
                    commit('setBuildings', buildings);
                    commit('setIsLoaded', true);

                    const allFilters = {
                        buildings: getters.mappedBuildings,
                        entrances: getters.mappedEntrances,
                        floors: getters.mappedFloors,
                        types: premiseTypes,
                        premiseTypes: premiseTypes,
                        deviceStatuses: defaultDeviceStatuses,
                        balancerStates: balancerStates
                    };

                    commit('filters/setFilters', allFilters, { root: true });
                })
        }
    }
}

export {
    buildings
}


