<template>
    <div class="app__modals">
        <modal-recovery-password
            v-if="showModalRecoveryPassword"
            :is-logged-in="true"
        />
        <modal-logout
            v-if="showModalLogout"
        />
        <modal-upload-icon
            v-if="showModalUploadIcon"
            :selected-icon="selectedElement"
        />
        <modal-building
            v-if="showModalBuilding"
            :selected-building="selectedElement"
        />
        <modal-premise
            v-if="showModalPremise"
            :selected-premise="selectedElement"
        />
        <modal-equipment-b-c
            v-if="showModalEquipmentBC"
            :selected-b-c="selectedElement"
        />
        <modal-equipment-d-c
            v-if="showModalEquipmentDC"
            :selected-d-c="selectedElement"
        />
        <modal-water-pad
            v-if="showModalWaterPad"
            :selected-pad="selectedElement"
        />
        <modal-set-readings
            v-if="showModalSetReadings"
            :selected-pad="selectedElement"
        />
        <modal-elevator
            v-if="showModalElevator"
            :selected-elevator="selectedElement"
        />
        <modal-elevator-restart
            v-if="showModalElevatorRestart"
            :selected-elevator="selectedElement"
        />
        <modal-elevator-send
            v-if="showModalElevatorSend"
            :selected-elevator="selectedElement"
        />
<!--        <modal-elevator-turn-off-alarm-->
<!--            v-if="showModalElevatorTurnOffAlarm"-->
<!--        />-->
        <modal-electricity-switchgear
            v-if="showModalElectricitySwitchgear"
            :selected-switchgear="selectedElement"
        />
        <modal-electricity-consumer
            v-if="showModalElectricityConsumer"
            :selected-consumer="selectedElement"
        />
        <modal-balancer-equipment
            v-if="showModalBalancerEquipment"
            :selected-equipment="selectedElement"
        />
        <modal-balancer-station
            v-if="showModalBalancerStation"
            :selected-station="selectedElement"
        />
        <modal-security-object
            v-if="showModalSecurityObject"
            :selected-security-object="selectedElement"
        />
        <modal-confirm
            v-if="showModalConfirm"
            :selected-element="selectedElement"
        />
    </div>
</template>

<script>
    import ModalRecoveryPassword from "../../components/modals/auth/ModalRecoveryPassword";
    import ModalLogout from "../../components/modals/auth/ModalLogout";
    import ModalUploadIcon from "../../components/modals/upload/ModalUploadIcon";
    import ModalBuilding from "../../components/modals/buildings/ModalBuilding";
    import ModalPremise from "./premises/ModalPremise";
    import ModalWaterPad from "./water/ModalWaterPad";
    import ModalSetReadings from "./water/ModalSetReadings";
    import ModalElectricitySwitchgear from "./electricity/ModalElectricitySwitchgear";
    import ModalElectricityConsumer from "./electricity/ModalElectricityConsumer";
    import ModalBalancerEquipment from "./balancer/ModalBalancerEquipment";
    import ModalBalancerStation from "./balancer/ModalBalancerStation";
    import ModalConfirm from "../../components/modals/ModalConfirm";
    import ModalEquipmentBC from "./equipments/ModalEquipmentBC";
    import ModalEquipmentDC from "./equipments/ModalEquipmentDC";
    import ModalElevator from "./elevators/ModalElevator";
    import ModalElevatorRestart from "./elevators/ModalElevatorRestart";
    import ModalElevatorTurnOffAlarm from "./elevators/ModalElevatorTurnOffAlarm";
    import ModalElevatorSend from "./elevators/ModalElevatorSend";
    import ModalSecurityObject from "./security/ModalSecurityObject";
    import { mapGetters } from "vuex";

    export default {
        name: "AppAllModals",

        components: {
            ModalPremise,
            ModalRecoveryPassword,
            ModalLogout,
            ModalUploadIcon,
            ModalBuilding,
            ModalWaterPad,
            ModalSetReadings,
            ModalElectricitySwitchgear,
            ModalElectricityConsumer,
            ModalBalancerEquipment,
            ModalBalancerStation,
            ModalEquipmentBC,
            ModalEquipmentDC,
            ModalElevator,
            ModalElevatorSend,
            ModalElevatorTurnOffAlarm,
            ModalElevatorRestart,
            ModalSecurityObject,
            ModalConfirm
        },

        computed: {
            ...mapGetters({
                selectedElement: 'modals/selectedElement',
                showModalRecoveryPassword: 'modals/showModalRecoveryPassword',
                showModalLogout: 'modals/showModalLogout',
                showModalUploadIcon: 'modals/showModalUploadIcon',
                showModalConfirm: 'modals/showModalConfirm',
                showModalBuilding: 'modals/showModalBuilding',
                showModalGroupBuildings: 'modals/showModalGroupBuildings',
                showModalPremise: 'modals/showModalPremise',
                showModalWaterPad: 'modals/showModalWaterPad',
                showModalSetReadings: 'modals/showModalSetReadings',
                showModalElectricitySwitchgear: 'modals/showModalElectricitySwitchgear',
                showModalElectricityConsumer: 'modals/showModalElectricityConsumer',
                showModalBalancerEquipment: 'modals/showModalBalancerEquipment',
                showModalBalancerStation: 'modals/showModalBalancerStation',
                showModalEquipmentBC: 'modals/showModalEquipmentBC',
                showModalEquipmentDC: 'modals/showModalEquipmentDC',
                showModalElevator: 'modals/showModalElevator',
                showModalElevatorRestart: 'modals/showModalElevatorRestart',
                showModalElevatorTurnOffAlarm: 'modals/showModalElevatorTurnOffAlarm',
                showModalElevatorSend: 'modals/showModalElevatorSend',
                showModalSecurityObject: 'modals/showModalSecurityObject'
            })
        }
    }
</script>