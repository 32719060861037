<template>
    <app-modal-wrapper>
        <form @submit.prevent="doActionWaterPad" class="modal-water-pad">
            <div class="modal-water-pad__content">
                <div class="water-pad-form">
                    <h4>{{ title }}</h4>
                    <div class="water-pad-form__info">
                        <div class="water-pad-form__row">
                            <div class="water-pad-form__details">
                                <app-base-select
                                    :is-disabled="isUpdate"
                                    :label="$t('water.pad.building.label')"
                                    :place-holder="$t('water.pad.building.ph')"
                                    :selected-option="selectedBuilding"
                                    :options="buildings"
                                    :type="'text'"
                                    @select="selectBuilding"
                                />
                            </div>
                            <div class="water-pad-form__details">
                                <app-base-select ref="floor"
                                    :is-clear="true"
                                    :label="$t('water.pad.floor.label')"
                                    :place-holder="$t('water.pad.floor.ph')"
                                    :selected-option="selectedFloor"
                                    :options="floors"
                                    :type="'text'"
                                    @select="selectFloor"
                                />
                            </div>
                        </div>
                        <div class="water-pad-form__row">
                            <div class="water-pad-form__details">
                                <app-base-select ref="entrance"
                                    :is-clear="true"
                                    :is-required="false"
                                    :label="$t('water.pad.entrance.label')"
                                    :place-holder="$t('water.pad.entrance.ph')"
                                    :selected-option="selectedEntrance"
                                    :options="entrances"
                                    :type="'text'"
                                    @select="selectEntrance"
                                />
                            </div>
                            <div class="water-pad-form__details">
                                <app-base-select ref="bc"
                                    :is-clear="true"
                                    :is-disabled="isUpdate"
                                    :label="$t('water.pad.bc.label')"
                                    :place-holder="$t('water.pad.bc.ph')"
                                    :selected-option="selectedBC"
                                    :options="bcs"
                                    :type="'text'"
                                    :error-message="errors.bc"
                                    @select="selectBC"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="water-pad-form__info">
                        <div class="water-pad-form__row">
                            <div class="water-pad-form__details">
                                <app-base-input
                                    v-model="pad.counterId"
                                    :label="$t('water.pad.counterId.label')"
                                    :place-holder="$t('water.pad.counterId.ph')"
                                    :error-message="errors.counterId"
                                />
                            </div>
                            <div class="water-pad-form__details">
                                <app-base-select ref="premise"
                                    :is-clear="true"
                                    :label="$t('water.pad.premise.label')"
                                    :place-holder="$t('water.pad.premise.ph')"
                                    :selected-option="selectedPremise"
                                    :options="premises"
                                    :type="'text'"
                                    :error-message="errors.premise"
                                    @select="selectPremise"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="water-pad-form__info">
                        <div class="water-pad-form__row">
                            <div class="water-pad-form__details">
                                <app-base-input
                                    v-model="pad.hwId"
                                    :label="$t('water.pad.hwId.label')"
                                    :place-holder="$t('water.pad.hwId.ph')"
                                    :error-message="errors.hwId"
                                />
                            </div>
                            <div class="water-pad-form__details">
                                <app-base-select
                                    :label="$t('water.pad.model.label')"
                                    :place-holder="$t('water.pad.model.ph')"
                                    :selected-option="selectedModel"
                                    :options="models"
                                    :type="'text'"
                                    @select="selectModel"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="water-pad-form__info">
                        <div class="water-pad-form__row">
                            <div class="water-pad-form__details">
                                <app-base-select
                                    :is-disabled="isUpdate"
                                    :label="$t('water.pad.type.label')"
                                    :place-holder="$t('water.pad.type.ph')"
                                    :selected-option="selectedType"
                                    :options="types"
                                    :type="'text'"
                                    @select="selectType"
                                />
                            </div>
                            <div class="water-pad-form__details">
                                <app-base-input v-if="isEnterTypePad"
                                    v-model="pad.name"
                                    :label="$t('water.pad.name.label')"
                                    :place-holder="$t('water.pad.name.ph')"
                                    :error-message="errors.name"
                                />
                                <app-base-select v-else
                                    :label="parentLabel"
                                    :place-holder="$t('water.pad.parent.ph')"
                                    :selected-option="selectedParent"
                                    :options="parents"
                                    :type="'text'"
                                    :error-message="errors.parent"
                                    @select="selectParent"
                                />
                            </div>
                        </div>
                        <div v-if="isNodeTypePad" class="water-pad-form__row">
                            <div class="water-pad-form__details">
                                <app-base-input
                                    v-model="pad.name"
                                    :label="$t('water.pad.name.label')"
                                    :place-holder="$t('water.pad.name.ph')"
                                    :error-message="errors.name"
                                />
                            </div>
                            <div class="water-pad-form__details"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-water-pad__control">
                <app-base-btn v-if="isUpdate"
                    :variation="'delete'"
                    :text="$t('common.buttons.delete')"
                    @action="openModalConfirm"
                />
                <app-base-btn
                    :variation="'cancel'"
                    :text="$t('common.buttons.cancel')"
                    @action="closeModalWaterPad"
                />
                <app-base-btn
                    :type="'submit'"
                    :variation="'save'"
                    :text="$t('common.buttons.save')"
                />
            </div>
        </form>
    </app-modal-wrapper>
</template>

<script>
    import { mapGetters } from "vuex";
    import { greenHubAPI } from "../../../config/api";
    import { waterPadTypes, waterPadModels } from "../../../constants/water";
    import { waterToastsMixin } from "../../../mixins/toasts/water";
    import cloneDeep from "lodash.clonedeep";
    import { mapEquipmentsBCToSelect } from "../../../api/mappers/equipments";
    import { mapPremisesToSelect } from "../../../api/mappers/premises";
    import { prepareQueryFilterParams } from "../../../helpers/api";
    import { clearDependentSelects } from "../../../helpers/selects";
    import { mapWaterPadsToSelect, mapWaterPadToSend} from "../../../api/mappers/water";
    import { validateWaterPad } from "../../../validations/water";

    export default {
        name: "ModalWaterPad",

        props: {
            selectedPad: {
                type: Object
            }
        },

        mixins: [
            waterToastsMixin
        ],

        data() {
            return {
                selectedBuilding: null,
                selectedFloor: null,
                selectedEntrance: null,

                selectedBC: null,
                bcs: [],

                selectedPremise: null,
                premises: [],

                selectedModel: null,

                selectedType: null,
                selectedParent: null,

                enterPads: [],
                nodePads: [],

                pad: {
                    hwId: '',
                    counterId: '',
                    name: ''
                },

                errors: {
                    bc: '',
                    counterId: '',
                    premise: '',
                    hwId: '',
                    parent: '',
                    name: ''
                }
            }
        },

        created() {
            this.checkUpdateWaterPad();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings'
            }),

            isUpdate() {
                return !!this.selectedPad?.id;
            },

            title() {
                return !this.isUpdate
                    ? this.$t('water.pad.title.create')
                    : this.$t('water.pad.title.edit');
            },

            floors() {
                return this.selectedBuilding?.floors || [];
            },

            entrances() {
                return this.selectedBuilding?.entrances || [];
            },

            models() {
                return waterPadModels;
            },

            isEnterTypePad() {
                return this.selectedType?.id === 1;
            },

            isNodeTypePad() {
                return this.selectedType?.id === 2;
            },

            isConsumerTypePad() {
                return this.selectedType?.id === 3;
            },

            parentLabel() {
                return this.isNodeTypePad
                    ? this.$t('water.pad.parent.enter.label')
                    : this.$t('water.pad.parent.enterOrNode.label');
            },

            parents() {
                return this.isNodeTypePad
                    ? this.enterPads
                    : this.isConsumerTypePad
                    ? this.enterPads.concat(this.nodePads)
                    : []
            },

            types() {
                return waterPadTypes;
            }
        },

        methods: {
            // <----------------------CRUD WATER PADS---------------------->
            doActionWaterPad() {
                this.clearWaterPadErrors();

                const { isValid, errors } = validateWaterPad(
                    this.pad,
                    this.selectedBC,
                    this.selectedPremise,
                    this.selectedType.id,
                    this.selectedParent
                );

                this.errors = errors;

                if (isValid) {
                    if (!this.isUpdate) {
                        this.createWaterPad();
                    } else {
                        this.changeWaterPad();
                    }
                }
            },

            createWaterPad() {
                this.prepareDataPadFromSelects();
                const padToSend = mapWaterPadToSend(this.pad);

                greenHubAPI.post('/water_supply', padToSend)
                    .then(() => {
                        this.updateWaterPads();
                        this.showCreateWaterPadToast(this.pad.counterId);
                    })
            },

            changeWaterPad() {
                this.prepareDataPadFromSelects();
                const id = this.pad.id;
                const padToSend = mapWaterPadToSend(this.pad, true);

                greenHubAPI.patch(`/water_supply/${id}`, padToSend)
                    .then(() => {
                        this.updateWaterPads();
                    })
            },

            prepareDataPadFromSelects() {
                this.pad.bcId = this.selectedBC.id;
                this.pad.floor = this.selectedPremise.floor;
                this.pad.premiseId = this.selectedPremise.id;
                this.pad.padModel = this.selectedModel.id;
                this.pad.type = this.selectedType.id;
                //<--------OPTIONAL FIELD-------->//
                this.pad.entrance = this.selectedPremise?.entrance || null;
                this.pad.parentId = this.selectedParent?.id || null;
            },

            updateWaterPads() {
                this.closeModalWaterPad();
                this.emitter.emit('updateWaterPads');
            },

            clearWaterPadErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },
            // <----------------------CRUD WATER PADS---------------------->

            // <----------------------GET WATER PADS DATA---------------------->
            checkUpdateWaterPad() {
                if (this.isUpdate) {
                    this.pad = cloneDeep(this.selectedPad);
                    this.selectedModel = this.models.find((model) => model.id === this.pad.model);
                    this.selectedType = this.types.find((type) => type.id === this.pad.type);
                } else {
                    this.selectedModel = this.models[0];
                    this.selectedType = this.types[0];
                }

                this.selectedBuilding = this.buildings.find((building) => building.id === this.selectedPad.buildingId);

                this.getAdditionalData();
            },

            getAdditionalData() {
                this.getBCs();
                this.getPremises();
                this.getWaterPads();
            },

            getWaterPads() {
                const buildingId = this.selectedBuilding.id;

                greenHubAPI.get(`/water_supply/${buildingId}`)
                    .then((result) => {
                        this.enterPads = mapWaterPadsToSelect(result.data);
                        this.nodePads = this.getNodePads(this.enterPads);

                        if (this.isUpdate) {
                            this.selectedParent = this.parents.find((parent) => parent.id === this.selectedPad.parentId);
                        }
                    })
            },

            getNodePads(pads) {
                return pads.reduce((nodePads, pad) => {
                    if (pad.nodePads.length) {
                        return pad.nodePads.reduce((acc, pad) => {
                            acc.push(pad);

                            return acc;
                        }, nodePads)
                    } else {
                        return nodePads;
                    }
                }, []);
            },

            getBCs() {
                const buildingId = this.selectedBuilding.id;

                greenHubAPI.get(`/equipments/${buildingId}`)
                    .then((result) => {
                        this.bcs = mapEquipmentsBCToSelect(result.data.buildingControllers);

                        if (this.isUpdate) {
                            this.selectedBC = this.bcs.find((bc) => bc.id === this.selectedPad.bcId);
                        }
                    })
            },

            getPremises() {
                const queryFilterParams = {
                    building_id: this.selectedBuilding.id
                }

                if (this.selectedFloor) {
                    queryFilterParams.floor = this.selectedFloor.id;
                }

                if (this.selectedEntrance) {
                    queryFilterParams.entrance = this.selectedEntrance.id;
                }

                const params = prepareQueryFilterParams(queryFilterParams);

                greenHubAPI.get('/premises', params)
                    .then((result) => {
                        const { qty, premises } = result.data;
                        this.premises = mapPremisesToSelect(premises);

                        if (this.isUpdate) {
                            this.selectedPremise = this.premises.find((premise) => premise.id === this.selectedPad.premiseId);
                        }
                    })
            },
            // <----------------------GET WATER PADS DATA---------------------->

            // <----------------------SELECTS WATER PADS---------------------->
            selectBuilding(building) {
                this.selectedBuilding = building;

                this.clearDependentFields();
                this.getAdditionalData();
            },

            selectFloor(floor) {
                this.selectedFloor = floor;

                if (floor) {
                    this.clearDependentPremiseField();
                }

                this.updatePremises();
            },

            selectEntrance(entrance) {
                this.selectedEntrance = entrance;
                this.updatePremises();
            },

            clearDependentFields() {
                clearDependentSelects(this.$refs);
                //TODO: WHY THIS CONDITION? WE DON'T HAVE REF "BUILDING"
                const refKeys = Object.keys(this.$refs).filter((key) => key !== 'building');

                this.$nextTick(() => {
                    refKeys.forEach((key) => this.$refs[key].initSelect());
                })
            },

            clearDependentPremiseField() {
                const dependentSelects = {
                    premise: this.$refs.premise
                };

                clearDependentSelects(dependentSelects);

                this.$nextTick(() => {
                    this.$refs.premise.initSelect();
                })
            },

            selectBC(bc) {
                this.selectedBC = bc;
            },

            selectPremise(premise) {
                this.selectedPremise = premise;
            },

            updatePremises() {
                this.clearDependentPremiseField();
                this.getPremises();
            },

            selectModel(model) {
                this.selectedModel = model;
            },

            selectType(type) {
                this.selectedType = type;

                if (this.isConsumerTypePad) {
                    this.pad.name = '';
                }
            },

            selectParent(parent) {
                this.selectedParent = parent;
            },
            // <----------------------SELECTS WATER PADS---------------------->

            // <----------------------MODALS WATER PADS---------------------->
            openModalConfirm() {
                const selectedElement = {
                    id: this.pad.id,
                    name: this.pad.hwId,
                    type: 'water'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            },

            closeModalWaterPad() {
                this.closeModal('modalWaterPad');
            }
            // <----------------------MODALS WATER PADS---------------------->
        }
    }
</script>

<style lang="scss" scoped>
    .modal-water-pad {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;
        max-height: 84vh;
        overflow-y: auto;

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    .water-pad-form {
        &__info {
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            &:not(:last-child) {
                padding-bottom: 30px;
                border-bottom: 1px solid var(--app-border);
            }
        }

        &__row {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }
    }
</style>