<template>
    <div class="equipment-row" @click.stop="handlerClickEquipment">
        <div class="table__row"
             :class="{
                'equipment-row--is-module': level === 2
             }"
        >
            <div class="table__column equipment__name"
                :class="{
                    'equipment__name--is-bc': !level
                }"
                :style="{
                    'paddingLeft': `${40 * level}px`
                }"
            >
                <div class="equipment__collapse-btn">
                    <app-collapse-btn v-if="isExistChildren"
                        :default-is-active="true"
                        @collapse="collapseChildren"
                    />
                </div>
                <app-text-wrapper
                    :is-parent="true"
                    :text="name"
                />
            </div>
            <div class="table__column equipment__serial-number">
                <app-text-wrapper
                    :text="serialNumber"
                />
            </div>
            <div class="table__column equipment__entrance">
                {{ entrance }}
            </div>
            <div class="table__column equipment__floor">
                {{ floor }}
            </div>
            <div class="table__column equipment__state">
                <app-base-status
                    :status="status"
                    :errors="errors"
                />
            </div>
        </div>
        <div v-if="isExistChildren && showChildren">
            <equipment-row v-for="equipment in children" :key="equipment.id"
                :equipments="equipments"
                :query-id="queryId"
                :level="level + 1"
                :id="equipment.id"
                :name="equipment.name"
                :serial-number="equipment.serialNumber"
                :entrance="equipment.entrance"
                :floor="equipment.floor"
                :status="equipment.status"
                :errors="equipment.errors || []"
                :children="equipment.children || []"
                @update-dispatching-controller="updateDC"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "EquipmentRow",

        props: {
            equipments: {
                type: Array,
                required: true
            },
            queryId: {
                type: String,
                required: true
            },
            level: {
                type: Number,
                required: true
            },
            id: {
                type: [Number, String],
                required: true
            },
            name: {
                type: String,
                required: true
            },
            serialNumber: {
                type: String,
                required: true
            },
            entrance: {
                type: Number,
                default: null
            },
            floor: {
                type: Number,
                required: true
            },
            status: {
                type: [ Boolean, Number ],
                required: true
            },
            errors: {
                type: Array,
                required: true
            },
            children: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                showChildren: true
            }
        },

        computed: {
            isExistChildren() {
                return !!this.children.length;
            }
        },

        methods: {
            collapseChildren() {
                this.showChildren = !this.showChildren;
            },

            handlerClickEquipment() {
                if (this.level === 0) {
                    this.$emit('updateBuildingController', this.id);
                } else if (this.level === 1) {
                    this.$emit('updateDispatchingController', this.id);
                }
            },

            updateDC(id) {
                const dc = this.children.find((dc) => dc.id === id);
                dc.bcs = this.equipments;

                this.openModal({
                    name: 'modalEquipmentDC',
                    selectedEl: dc
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .equipment-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: var(--system-white) !important;

        &:hover {
            cursor: pointer;
        }

        &--is-module {
            background: var(--app-table-background) !important;

            &:hover {
                cursor: default;
            }
        }
    }
</style>