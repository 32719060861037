<template>
    <div class="switchgear-counter-form">
        <div class="switchgear-counter-form__info">
            <div class="switchgear-counter-form__row">
                <div class="switchgear-counter-form__details">
                    <app-base-input
                        v-model="counter.hwId"
                        :label="counterHwIdLabel"
                        :place-holder="counterHwIdPlaceholder"
                        :error-message="errors.hwId"
                    />
                </div>
                <div class="switchgear-counter-form__details">
                    <app-base-input
                        v-model="counter.coefficient"
                        :label="$t('electricity.consumer.coefficient.label')"
                        :place-holder="$t('electricity.consumer.coefficient.ph')"
                        :error-message="errors.coefficient"
                    />
<!--                    <app-base-select-->
<!--                        :label="$t('electricity.consumer.model.label')"-->
<!--                        :place-holder="$t('electricity.consumer.model.ph')"-->
<!--                        :selected-option="selectedModel"-->
<!--                        :options="models"-->
<!--                        :type="'text'"-->
<!--                        @select="selectModel"-->
<!--                    />-->
                </div>
            </div>
            <div class="switchgear-counter-form__row">
                <div class="switchgear-counter-form__details">
                    <app-base-select
                        :label="$t('electricity.consumer.inputNumber.label')"
                        :place-holder="$t('electricity.consumer.inputNumber.ph')"
                        :selected-option="selectedInput"
                        :options="inputs"
                        :type="'text'"
                        :error-message="errors.inputNumber"
                        @select="selectInput"
                    />
                </div>
                <div class="switchgear-counter-form__details">
                    <app-base-input v-if="isOutputCounter"
                        v-model="counter.line"
                        :label="$t('electricity.consumer.line.label')"
                        :place-holder="$t('electricity.consumer.line.ph')"
                        :error-message="errors.line"
                    />
                </div>
            </div>
            <div v-if="isOutputCounter" class="switchgear-counter-form__row">
                <div class="switchgear-counter-form__details">
                    <app-base-input v-if="isOutputCounter"
                        v-model="counter.name"
                        :label="$t('electricity.consumer.additionalName.label')"
                        :place-holder="$t('electricity.consumer.additionalName.ph')"
                        :error-message="errors.name"
                    />
                </div>
                <div class="switchgear-counter-form__details"></div>
            </div>
<!--            <div v-if="isOutputCounter" class="switchgear-counter-form__row">-->
<!--                <div class="switchgear-counter-form__details">-->
<!--                    <app-base-input-->
<!--                        v-model="counter.line"-->
<!--                        :label="$t('electricity.consumer.line.label')"-->
<!--                        :place-holder="$t('electricity.consumer.line.ph')"-->
<!--                    />-->
<!--                </div>-->
<!--                <div class="switchgear-counter-form__details"></div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
    import { electricityCounterModels, electricityInputs } from "../../../constants/electricity";
    import { validateSwitchgearCounter } from "../../../validations/electricity";

    export default {
        name: "SwitchgearCounterForm",

        props: {
            switchgearId: {
                type: String,
                required: true
            },
            isUpdate: {
                type: Boolean,
                default: false
            },
            index: {
                type: Number,
                required: true
            },
            length: {
                type: Number,
                required: true
            },
            type: {
                type: String,
                required: true
            },
            id: {
                type: [Number, String]
            },
            name: {
                type: String,
                default: ''
            },
            hwId: {
                type: String,
                required: true
            },
            inputNumber: {
                type: Number
            },
            coefficient: {
                type: Number,
                required: true
            },
            line: {
                type: [Number, String]
            },
            lines: {
                type: Array,
                default: () => []
            }
        },

        data() {
            return {
                // selectedModel: null,
                selectedInput: null,

                counter: {
                    name: '',
                    hwId: '',
                    coefficient: '',
                    line: ''
                },

                errors: {
                    name: '',
                    hwId: '',
                    coefficient: '',
                    inputNumber: '',
                    line: ''
                }
            }
        },

        created() {
            this.checkUpdateSwitchgearCounter();
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        computed: {
            isOutputCounter() {
                return this.type === 'output';
            },

            counterHwIdLabel() {
                return !this.isOutputCounter
                    ? `${this.$t('electricity.switchgear.counters.input.hwId.label')} ${this.index + 1}`
                    : `${this.$t('electricity.switchgear.counters.output.hwId.label')} ${this.index + 1}`;
            },

            counterHwIdPlaceholder() {
                return !this.isOutputCounter
                    ? `${this.$t('electricity.switchgear.counters.input.hwId.label')}`
                    : `${this.$t('electricity.switchgear.counters.output.hwId.label')}`;
            },

            // models() {
            //     return electricityCounterModels;
            // },

            inputs() {
                return electricityInputs;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('validateSwitchgearCounter', this.validateSwitchgearCounter);
                this.emitter.on('showNotUniqueLineError', this.showNotUniqueLineError);
            },

            unsubscribeFromEvents() {
                this.emitter.off('validateSwitchgearCounter', this.validateSwitchgearCounter);
                this.emitter.off('showNotUniqueLineError', this.showNotUniqueLineError);
            },

            checkUpdateSwitchgearCounter() {
                this.counter.id = this.id;
                this.counter.type = this.type;

                if (this.isUpdate) {
                    this.counter.name = this.name;
                    this.counter.hwId = this.hwId;
                    this.counter.coefficient = this.coefficient;
                    this.counter.line = this.line;
                    this.selectedInput = this.inputs.find((input) => input.id === this.inputNumber);
                }
            },

            validateSwitchgearCounter() {
                this.clearSwitchgearCounterErrors();
                const lines = this.isUpdate ? this.lines.filter((line => line.switchgearId !== this.switchgearId)) : this.lines;

                const { isValid, errors } = validateSwitchgearCounter(this.counter, this.isOutputCounter, this.selectedInput, lines);
                this.counter.isValid = isValid;

                this.errors = errors;

                if (isValid) {
                    this.prepareDataSwitchgearCounterFromSelects();
                }

                this.$emit('updateCounter', this.counter);
            },

            showNotUniqueLineError(ids) {
                if (ids.includes(this.counter.id)) {
                    this.errors.line = this.$t('validation.uniqueLine');
                }
            },

            prepareDataSwitchgearCounterFromSelects() {
                this.counter.inputNumber = this.selectedInput.id;
            },

            clearSwitchgearCounterErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            selectInput(input) {
                this.selectedInput = input;
            },

            // selectModel() {
            //
            // },
        }
    }
</script>

<style lang="scss" scoped>
    .switchgear-counter-form {
        &__info {
            display: flex;
            flex-direction: column;
            padding: 30px 0;
        }

        &__row {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }
    }
</style>