function createCounterInputsList(numberInputCounters) {
    return [...Array(numberInputCounters).keys()].map(input => {
        return {
            id: input + 1,
            name: input + 1
        }
    })
}

export {
    createCounterInputsList
}