<template>
    <div class="security-object-row">
        <div class="table__row security-object-row__entry" @click.stop="handlerClickSecurityObject">
            <div class="table__column security-object__name">
                <app-text-wrapper
                    :text="name"
                    :sub-text="searchSubString"
                    :is-match="true"
                />
            </div>
            <div class="table__column security-object__building">
                <app-text-wrapper
                    :text="buildingName"
                    :sub-text="searchSubString"
                    :is-match="true"
                />
            </div>
            <div class="table__column security-object__entrance">
                {{ entrance }}
            </div>
            <div class="table__column security-object__floor">
                {{ floor }}
            </div>
            <div class="table__column security-object__channel">
                {{ channel }}
            </div>
            <div class="table__column security-object__state">
                <app-base-status
                    :type="'securityObject'"
                    :status="state"
                />
            </div>
            <div class="table__column security-object__status">
                <app-base-status
                    :show-errors="showErrors"
                    :type="'security'"
                    :status="status"
                    :errors="errors"
                    @show-errors="showErrorsSecurityObject"
                />
            </div>
        </div>
        <div v-if="showErrors" class="security-object-row__errors">
            <app-errors-row
                :type="'security'"
                :errors="errors"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "SecurityObjectRow",

        props: {
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            buildingName: {
                type: String,
                required: true
            },
            entrance: {
                type: Number,
                default: null
            },
            floor: {
                type: Number,
                required: true
            },
            channel: {
                type: Number,
                required: true
            },
            state: {
                type: Number,
                required: true
            },
            status: {
                type: Boolean,
                required: true
            },
            errors: {
                type: Array,
                required: true
            }
        },

        inject: {
            subString: {
                default: ''
            }
        },

        data() {
            return {
                showErrors: false,
                searchSubString: this.subString
            }
        },

        watch: {
            hasErrors: {
                handler(newValue) {
                    this.showErrors = newValue;
                },

                immediate: true
            }
        },

        computed: {
            hasErrors() {
                return !!this.errors.length;
            }
        },

        methods: {
            showErrorsSecurityObject() {
                this.showErrors = !this.showErrors;
            },

            handlerClickSecurityObject() {
                this.$emit('update', this.id);
            },
        }
    }
</script>

<style lang="scss">
    .security-object-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: var(--system-white);

        .table__row {
            background: unset;
        }

        &__entry {
            &:hover {
                cursor: pointer;
            }
        }

        &:nth-child(2n) {
            background: var(--app-table-background);
        }
    }
</style>