<template>
    <div class="view-item" @click="selectPeriod">
        <div>{{ name }}</div>
    </div>
</template>

<script>
    export default {
        name: "AppPeriodItem",

        props: {
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            }
        },

        methods: {
            selectPeriod() {
                this.$emit('select', {
                    id: this.id,
                    name: this.name
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .view-item {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        white-space: nowrap;
        position: relative;

        &__children {
            position: absolute;
            background: var(--system-white);
            box-shadow: 0 16px 32px rgba(35, 23, 5, 0.26);
            border-radius: 13px;
            padding: 8px 0;
            top: 0;
            right: -150px;
            z-index: 20;
            font-weight: 400;
            width: 160px;
        }

        &__children-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 16px;
            width: 16px;
            transition: transform .2s ease;
        }

        &:hover {
            cursor: pointer;
            background: var(--app-btn-secondary);
        }
    }
</style>