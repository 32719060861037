<template>
    <app-modal-wrapper>
        <form @submit.prevent="restartElevator" class="modal-elevator-restart">
            <div class="modal-elevator-restart__content">
                <h4>{{ $t('confirm.elevator.restart') }}</h4>
            </div>
            <div class="modal-elevator-restart__control">
                <app-base-btn
                    :size="'full'"
                    :variation="'cancel'"
                    :text="$t('common.buttons.cancel')"
                    @action="closeModalElevatorRestart"
                />
                <app-base-btn
                    :type="'submit'"
                    :size="'full'"
                    :variation="'save'"
                    :text="$t('common.buttons.restart')"
                />
            </div>
            <div v-if="isLoaded" class="modal-elevator-restart__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </form>
    </app-modal-wrapper>
</template>

<script>
    import { greenHubAPI } from "../../../config/api";
    import { elevatorsToastsMixin } from "../../../mixins/toasts/elevators";

    export default {
        name: "ModalElevatorRestart",

        props: {
            selectedElevator: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                isLoaded: false
            }
        },

        mixins: [ elevatorsToastsMixin ],

        methods: {
            restartElevator() {
                this.isLoaded = true;

                const id = this.selectedElevator.id;

                greenHubAPI.post(`/elevators/${id}/restart`)
                    .then(() => {
                        this.showElevatorRestartToast();
                        this.closeModalElevatorRestart();
                    })
                    .catch(() => {
                        this.showElevatorCommandError();
                    })
                    .finally(() => {
                        this.isLoaded = false;
                    })
            },

            closeModalElevatorRestart() {
                this.closeModal('modalElevatorRestart');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-elevator-restart {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 574px;
        width: 100%;

        &__control {
            display: flex;
            margin-top: 45px;

            button:first-child {
                margin-right: 30px;
            }
        }

        &__loader {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--app-base-primary-10);
        }
    }
</style>