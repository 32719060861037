<template>
    <div class="balancer-monitoring__table">
        <div class="table__head">
            <div class="table__column balancer-monitoring__name">
                <app-text-wrapper
                    :text="$t('balancer.table.name')"
                />
            </div>
            <div class="table__column balancer-monitoring__input">
                <app-text-wrapper
                    :text="$t('balancer.table.input')"
                />
            </div>
            <div class="table__column balancer-monitoring__consumption">
                <app-text-wrapper
                    :text="$t('balancer.table.consumption')"
                />
            </div>
            <div class="table__column balancer-monitoring__nominal-limit balancer-monitoring--is-edit">
                <app-text-wrapper
                    :text="$t('balancer.table.nominalLimit')"
                />
                <app-circle-btn v-if="!isEditAccidentLimits"
                    @action="editLimits('nominal')"
                >
                    <svg v-if="!isEditNominalLimits" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.24927 12.251H4.43677L10.8884 5.7993L8.70093 3.6118L2.24927 10.0635V12.251ZM3.41593 10.5476L8.70093 5.26264L9.2376 5.7993L3.9526 11.0843H3.41593V10.5476Z" fill="#3A3A3A"/>
                        <path d="M11.2151 1.92014C10.9876 1.69264 10.6201 1.69264 10.3926 1.92014L9.3251 2.98764L11.5126 5.17514L12.5801 4.10764C12.8076 3.88014 12.8076 3.51264 12.5801 3.28514L11.2151 1.92014Z" fill="#3A3A3A"/>
                    </svg>
                    <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.685 2.31488C16.1049 2.73473 16.1049 3.41543 15.685 3.83527L4.59935 14.9209L4.59612 14.9177L4.59489 14.9189L0.315126 10.6392C-0.104717 10.2193 -0.104717 9.53862 0.315126 9.11878C0.734969 8.69893 1.41567 8.69893 1.83551 9.11878L4.59813 11.8814L14.1646 2.31488C14.5845 1.89504 15.2652 1.89504 15.685 2.31488Z" fill="#3F3F3F" fill-opacity="0.7"/>
                    </svg>
                </app-circle-btn>
            </div>
            <div class="table__column balancer-monitoring__accident-limit balancer-monitoring--is-edit">
                <app-text-wrapper
                    :text="$t('balancer.table.accidentLimit')"
                />
                <app-circle-btn v-if="!isEditNominalLimits"
                    @action="editLimits('accident')"
                >
                    <svg v-if="!isEditAccidentLimits" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.24927 12.251H4.43677L10.8884 5.7993L8.70093 3.6118L2.24927 10.0635V12.251ZM3.41593 10.5476L8.70093 5.26264L9.2376 5.7993L3.9526 11.0843H3.41593V10.5476Z" fill="#3A3A3A"/>
                        <path d="M11.2151 1.92014C10.9876 1.69264 10.6201 1.69264 10.3926 1.92014L9.3251 2.98764L11.5126 5.17514L12.5801 4.10764C12.8076 3.88014 12.8076 3.51264 12.5801 3.28514L11.2151 1.92014Z" fill="#3A3A3A"/>
                    </svg>
                    <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.685 2.31488C16.1049 2.73473 16.1049 3.41543 15.685 3.83527L4.59935 14.9209L4.59612 14.9177L4.59489 14.9189L0.315126 10.6392C-0.104717 10.2193 -0.104717 9.53862 0.315126 9.11878C0.734969 8.69893 1.41567 8.69893 1.83551 9.11878L4.59813 11.8814L14.1646 2.31488C14.5845 1.89504 15.2652 1.89504 15.685 2.31488Z" fill="#3F3F3F" fill-opacity="0.7"/>
                    </svg>
                </app-circle-btn>
            </div>
            <div class="table__column balancer-monitoring__phase-imbalance">
                <app-text-wrapper
                    :text="$t('balancer.table.phaseImbalance')"
                />
            </div>
            <div class="table__column balancer-monitoring__input-status">
                <app-text-wrapper
                    :text="$t('balancer.table.inputStatus')"
                />
            </div>
            <div class="table__column balancer-monitoring__balancer-status">
                <app-text-wrapper
                    :text="$t('balancer.table.balancerStatus')"
                />
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }">
            <balancer-element-row v-for="station in stations" :key="station.id"
                :level="0"
                :id="station.id"
                :name="station.name"
                :inputs="station.inputs"
                :children="station.children"
                :is-edit-nominal-limits="isEditNominalLimits"
                :is-edit-accident-limits="isEditAccidentLimits"
                @update="updateStation"
            />
        </div>
    </div>
</template>

<script>
    import { greenHubAPI } from "../../../config/api";
    import { getAllBalancerInputsForValidation, mapBalancerInputLimitsToSend } from "../../../api/mappers/balancer";
    import BalancerElementRow from "../elements/BalancerElementRow";
    import BalancerInputRow from "../elements/BalancerInputRow";

    export default {
        name: "BalancerMonitoringTable",

        components: {
            BalancerElementRow,
            BalancerInputRow
        },

        props: {
            stations: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                isEditNominalLimits: false,
                isEditAccidentLimits: false
            }
        },

        methods: {
            updateStation(id) {
                const station = this.stations.find((station) => station.id === id);

                this.openModal({
                    name: 'modalBalancerStation',
                    selectedEl: station
                })
            },

            editLimits(type) {
                if (type === 'nominal') {
                    if (this.isEditNominalLimits) {
                        this.validateAndUpdateLimits(type);
                    } else {
                        this.actionNominalLimits();
                    }
                } else {
                    if (this.isEditAccidentLimits) {
                        this.validateAndUpdateLimits(type);
                    } else {
                        this.actionAccidentLimits();
                    }
                }
            },

            actionNominalLimits() {
                this.isEditNominalLimits = !this.isEditNominalLimits;
            },

            actionAccidentLimits() {
                this.isEditAccidentLimits = !this.isEditAccidentLimits;
            },

            validateAndUpdateLimits(type) {
                this.emitter.emit('validateAndUpdateLimits', type);

                const allBalancerLimitsInputs = getAllBalancerInputsForValidation(this.stations);
                const isNominalLimit = type === 'nominal';
                const isValidLimitTypeKey = isNominalLimit ? 'isValidNominalLimit' : 'isValidAccidentLimit';

                const isValid = allBalancerLimitsInputs.every((input) => input[isValidLimitTypeKey]);

                if (isValid) {
                    this.updateBalancerLimits(type);
                }
            },

            updateBalancerLimits(type) {
                const isNominalLimit = type === 'nominal';
                const limits = mapBalancerInputLimitsToSend(this.stations, isNominalLimit);

                greenHubAPI.post(`/balancer/limits/${type}`, limits)
                    .then(() => {
                        this.updateBalancerStations();
                    })
                    .finally(() => {
                        isNominalLimit ? this.actionNominalLimits() : this.actionAccidentLimits();
                    })
            },

            updateBalancerStations() {
                this.emitter.emit('updateBalancerStations');
            }
        }
    }
</script>

<style lang="scss">
    .balancer-monitoring {
        &__name {
            width: 19%;
        }

        &__panel-name {
            display: flex;
            align-items: center;

            button {
                margin-right: 30px;
            }

            &--is-station {
                font-weight: 600;
            }
        }

        &--is-edit {
            display: flex;
            align-items: center;

            button {
                margin-left: 5px;
            }
        }

        &__input {
            width: 11%;
        }

        &__consumption {
            width: 11%;
        }

        &__nominal-limit {
            width: 13%;
        }

        &__accident-limit {
            width: 13%;
        }

        &__phase-imbalance {
            width: 11%;
        }

        &__input-status {
            width: 10%;
        }

        &__balancer-status {
            width: 12%;
        }
    }
</style>