<template>
    <div class="equipments__table">
        <div class="table__head">
            <div class="table__column equipment__name">
                <app-text-wrapper
                    :text="$t('equipments.table.equipment')"
                />
            </div>
            <div class="table__column equipment__serial-number">
                <app-text-wrapper
                    :text="$t('equipments.table.serialNumber')"
                />
            </div>
            <div class="table__column equipment__entrance">
                <app-text-wrapper
                    :text="$t('equipments.table.entrance')"
                />
            </div>
            <div class="table__column equipment__floor">
                <app-text-wrapper
                    :text="$t('equipments.table.floor')"
                />
            </div>
            <div class="table__column equipment__state">
                <app-text-wrapper
                    :text="$t('equipments.table.state')"
                />
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }" @scroll="handlerScrollBodyTable">
            <equipment-row v-for="equipment in equipments" :key="equipment.id"
                :equipments="equipments"
                :query-id="queryId"
                :level="0"
                :id="equipment.id"
                :name="equipment.name"
                :serial-number="equipment.serialNumber"
                :entrance="equipment.entrance"
                :floor="equipment.floor"
                :status="equipment.status"
                :errors="equipment.errors"
                :children="equipment.children || []"
                @update-building-controller="updateBC"
            />
        </div>
    </div>
</template>

<script>
    import EquipmentRow from "./elements/EquipmentRow";

    export default {
        name: "EquipmentsTable",

        components: {
            EquipmentRow
        },

        props: {
            equipments: {
                type: Array,
                required: true
            },
            queryId: {
                type: String,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            }
        },

        methods: {
            updateBC(id) {
                const bc = this.equipments.find((equipment) => equipment.id === id);

                this.openModal({
                    name: 'modalEquipmentBC',
                    selectedEl: bc
                })
            }
        }
    }
</script>