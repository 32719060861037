<template>
    <div class="elevators">
        <div class="elevators__panel">
            <div class="elevators__title">
                {{ $t('elevators.title') }}
            </div>
            <div class="elevators__actions">
                <app-add-btn
                    @open-modal="openModalElevator"
                />
            </div>
        </div>
        <div class="elevators__control">
            <elevators-filters
                :qty="elevators.length"
                @update-query-params="updateQueryParams"
            />
        </div>
        <div class="elevators__content" ref="elevators">
            <div v-if="showTable" class="elevators__table">
                <elevators-table
                    :elevators="elevators"
                    :max-height="maxHeightScroll"
                />
            </div>
            <div v-if="showEmpty" class="elevators__empty">
                <app-empty-content
                    :content-type="'elevators'"
                    @open-modal="openModalElevator"
                />
            </div>
            <div v-if="showNoResults" class="elevators__empty">
                <app-no-results
                    @clear="clearQueryParams"
                />
            </div>
            <div v-if="isLoaded" class="elevators__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { computed } from "vue";
    import { greenHubAPI } from "../../config/api";
    import { maxHeightMixin } from "../../mixins/calcMaxHeight";
    import { elevatorsToastsMixin } from "../../mixins/toasts/elevators";
    import { mapElevatorsToView } from "../../api/mappers/elevators";
    import ElevatorsFilters from "./ElevatorsFilters";
    import ElevatorsTable from "./ElevatorsTable";

    export default {
        name: "ElevatorsControl",

        components: {
            ElevatorsTable,
            ElevatorsFilters
        },

        mixins: [
            maxHeightMixin,
            elevatorsToastsMixin
        ],

        data() {
            return {
                isLoaded: false,
                timerId: '',
                elevators: [],

                query: {
                    params: null
                }
            }
        },

        provide() {
            return {
                subString: computed(() => this.searchSubString)
            }
        },

        created() {
            this.updateElevators();

            this.timerId = setInterval(() => {
                this.getElevators();
            }, 5000);
        },

        mounted() {
            this.subscribeToResize('elevators');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
            clearInterval(this.timerId);
        },

        computed: {
            isExistElevators() {
                return !!this.elevators.length;
            },

            showTable() {
                return this.isExistElevators && !this.isLoaded;
            },

            showEmpty() {
                return !this.isExistElevators && !this.isLoaded && !this.isExistQueryParams;
            },

            showNoResults() {
                return !this.isExistElevators && !this.isLoaded && this.isExistQueryParams;
            },

            isExistQueryParams() {
                return this.searchSubString || this.filterSubString;
            },

            searchSubString() {
                return this.query.params?.match ? this.query.params?.match : '';
            },

            filterSubString() {
                return this.query.params?.filter ? this.query.params?.filter : '';
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateElevators', this.updateElevators);
                this.emitter.on('deleteElevator', this.deleteElevator);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateElevators', this.updateElevators);
                this.emitter.off('deleteElevator', this.deleteElevator);
            },

            getElevators() {
                const params = {
                    params: this.query.params
                };

                greenHubAPI.get('/elevators', params)
                    .then((result) => {
                        this.elevators = mapElevatorsToView(result.data);

                        this.emitter.emit('updateShowQty');
                        this.$nextTick(() => {
                            this.calcMaxHeight();
                        });
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            clearQueryParams() {
                this.emitter.emit('clearQueryParams');
            },

            deleteElevator({ id, name }) {
                greenHubAPI.delete(`/elevators/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.closeModalElevator();
                        this.showDeleteElevatorToast(name);
                        this.updateElevators();
                    })
            },

            updateQueryParams(params) {
                this.query.params = params;
                this.updateElevators();
            },

            openModalElevator() {
                this.openModal({
                    name: 'modalElevator'
                })
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            },

            closeModalElevator() {
                this.closeModal('modalElevator');
            },

            updateElevators() {
                this.isLoaded = true;
                this.getElevators();
            }
        }
    }
</script>

<style lang="scss">
    .elevators-table {
        height: 100%;
    }

    .elevator {
        &__name {
            width: 16%;
        }

        &__hwId {
            width: 17%;
        }

        &__premise {
            width: 18%;
        }

        &__building {
            width: 15%;
        }

        &__entrance {
            width: 11%;
        }

        &__floor {
            width: 11%;
        }

        &__status {
            width: 8%;
        }

        &__actions {
            width: 4%;
            position: relative;
        }

        &__action-control {
            width: 30px;
            height: 30px;
            position: relative;
        }

        &__action-wrapper {
            position: absolute;
            right: 35px;
            top: 15px;
            background: var(--system-white);
            box-shadow: 0 16px 32px rgba(35, 23, 5, 0.26);
            border-radius: 13px;
            padding: 7px 0;
            z-index: 10;
        }
    }
</style>