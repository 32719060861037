<template>
    <app-modal-wrapper>
        <form @submit.prevent="doActionReadings" class="modal-set-readings">
            <div class="modal-set-readings__content">
                <div class="set-readings-form">
                    <h4>{{ $t('water.pad.readings.title') }}</h4>
                    <div class="set-readings-form__info">
                        <app-base-input
                            v-model="pad.readings"
                            :label="label"
                            :place-holder="$t('water.pad.readings.ph')"
                        />
                    </div>
                </div>
            </div>
            <div class="modal-set-readings__control">
                <app-base-btn
                    :variation="'cancel'"
                    :text="$t('common.buttons.cancel')"
                    @action="closeModalSetReadings"
                />
                <app-base-btn
                    :is-disabled="isLoaded"
                    :type="'submit'"
                    :variation="'save'"
                    :text="$t('common.buttons.applyReadings')"
                />
            </div>
        </form>
    </app-modal-wrapper>
</template>

<script>
    import { validateSetReadingsWaterPad } from "../../../validations/water";
    import { greenHubAPI } from "../../../config/api";
    import { waterToastsMixin } from "../../../mixins/toasts/water";

    export default {
        name: "ModalSetReadings",

        props: {
            selectedPad: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                isLoaded: false,
                pad: {
                    readings: 0
                },

                errors: {
                    readings: ''
                }
            }
        },

        mixins: [
            waterToastsMixin
        ],

        created() {
            this.init();
        },

        computed: {
            label() {
                return `${this.$t('water.pad.readings.label')} №${this.pad.name}`;
            }
        },

        methods: {
            init() {
                this.pad = { ...this.selectedPad };
            },

            doActionReadings() {
                this.clearSetReadingsWaterPadErrors();

                const { isValid, errors } = validateSetReadingsWaterPad(this.pad.readings);

                this.errors = errors;

                if (isValid) {
                    this.setReadings();
                }
            },

            setReadings() {
                this.isLoaded = true;

                const id  = this.pad.id;

                const readingsToSend = {
                    readings: parseFloat((parseFloat(this.pad.readings)).toFixed(3))
                }

                greenHubAPI.patch(`water_supply/readings/${id}`, readingsToSend)
                    .then(() => {
                        this.updateWaterPads();
                        this.showSetReadingsSuccessToast();
                    })
                    .catch(() => {
                        this.showSetReadingsErrorToast();
                    })
                    .finally(() => {
                        this.isLoaded = false;
                    })
            },

            clearSetReadingsWaterPadErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            updateWaterPads() {
                this.closeModalSetReadings();
                this.emitter.emit('updateWaterPads');
            },

            closeModalSetReadings() {
                this.closeModal('modalSetReadings');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-set-readings {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 574px;
        width: 100%;

        h4 {
            margin-bottom: 30px;
        }

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    .set-readings-form {
        &__row {
            display: flex;
        }
    }
</style>