import { createStore } from 'vuex';
import { modals } from "./modules/modals"
import { buildings } from "./modules/buildings"
import { filters } from "./modules/filters";

export default createStore({
  modules: {
    modals,
    buildings,
    filters
  }
})
