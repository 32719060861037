<template>
    <app-modal-wrapper>
        <div class="modal-premise" ref="modalPremise">
            <form @submit.prevent="doActionPremise" class="premise-form">
                <h4>{{ title }}</h4>
                <div class="premise-form__info">
                    <div class="premise-form__row">
                        <div class="premise-form__details">
                            <div class="premise-form__building">
                                <app-base-select
                                    :label="$t('premises.premise.buildings.label')"
                                    :place-holder="$t('premises.premise.buildings.ph')"
                                    :is-searchable="true"
                                    :selected-option="selectedBuilding"
                                    :options="buildings"
                                    :type="'text'"
                                    :error-message="errors.building"
                                    @select="selectBuilding"
                                />
                            </div>
                        </div>
                        <div class="premise-form__details">
                            <div class="premise-form__floor">
                                <app-base-select ref="floor"
                                    :label="$t('premises.premise.floors.label')"
                                    :place-holder="$t('premises.premise.floors.ph')"
                                    :selected-option="selectedFloor"
                                    :options="floors"
                                    :type="'text'"
                                    :error-message="errors.floor"
                                    @select="selectFloor"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="premise-form__row">
                        <div class="premise-form__details">
                            <div class="premise-form__type">
                                <app-base-select
                                    :label="$t('premises.premise.premiseType.label')"
                                    :place-holder="$t('premises.premise.premiseType.ph')"
                                    :selected-option="selectedType"
                                    :options="types"
                                    :type="'text'"
                                    :error-message="errors.type"
                                    @select="selectType"
                                />
                            </div>
                        </div>
                        <div class="premise-form__details">
                            <div class="premise-form__name">
                                <app-base-input
                                    v-model="premise.name"
                                    :label="$t('premises.premise.name.label')"
                                    :place-holder="$t('premises.premise.name.ph')"
                                    :error-message="errors.name"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="premise-form__row">
                        <div class="premise-form__details">
                            <app-base-select ref="entrance"
                                :open-direction="'top'"
                                :label="$t('premises.premise.entrances.label')"
                                :place-holder="$t('premises.premise.entrances.ph')"
                                :selected-option="selectedEntrance"
                                :options="entrances"
                                :type="'text'"
                                :is-required="false"
                                :is-clear="true"
                                @select="selectEntrance"
                            />
                        </div>
                        <div class="premise-form__details"></div>
                    </div>
                </div>
                <div class="premise-form__control">
                    <app-base-btn v-if="isUpdate"
                        :variation="'delete'"
                        :text="$t('common.buttons.delete')"
                        @action="openModalConfirm"
                    />
                    <app-base-btn
                        :variation="'cancel'"
                        :text="$t('common.buttons.cancel')"
                        @action="closeModalPremise"
                    />
                    <app-base-btn
                        :type="'submit'"
                        :variation="'save'"
                        :text="saveBtnText"
                    />
                </div>
            </form>
        </div>
    </app-modal-wrapper>
</template>

<script>
    import { greenHubAPI } from "../../../config/api";
    import { premiseTypes } from "../../../constants/premises";
    import { clearDependentSelects } from "../../../helpers/selects";
    import { validatePremise } from "../../../validations/premise";
    import { mapPremiseToSend } from "../../../api/mappers/premises";
    import { premisesToastsMixin } from "../../../mixins/toasts/premises";
    import { mapGetters } from "vuex";

    export default {
        name: "ModalPremise",

        props: {
            selectedPremise: {
                type: Object
            }
        },

        mixins: [
            premisesToastsMixin
        ],

        data() {
            return {
                isLoaded: false,
                selectedBuilding: null,
                selectedType: null,
                selectedEntrance: null,
                selectedFloor: null,

                premise: {
                    name: ''
                },

                errors: {
                    building: '',
                    type: '',
                    floor: '',
                    name: ''
                }
            }
        },

        created() {
            this.checkUpdatePremise();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings'
            }),

            isUpdate() {
                return !!this.selectedPremise;
            },

            title() {
                return this.isUpdate
                    ? this.$t('premises.premise.title.edit')
                    : this.$t('premises.premise.title.create');
            },

            saveBtnText() {
                return this.isUpdate
                    ? this.$t('common.buttons.save')
                    : this.$t('common.buttons.addPremise');
            },

            types() {
                return premiseTypes;
            },

            entrances() {
                return this.selectedBuilding?.entrances || [];
            },

            floors() {
                return this.selectedBuilding?.floors || [];
            }
        },

        methods: {
            checkUpdatePremise() {
                if (this.isUpdate) {
                    this.premise = { ...this.selectedPremise };

                    this.selectedBuilding = this.buildings.find((building) => building.id === this.premise.buildingId);
                    this.selectedType = this.types.find((type) => type.id === this.premise.type);
                    this.selectedFloor = this.floors.find((floor) => floor.id === this.premise.floor);

                    if (this.premise.entrance) {
                        this.selectedEntrance = this.entrances.find((entrance) => entrance.id === this.premise.entrance);
                    }
                }
            },

            doActionPremise() {
                this.clearPremiseErrors();
                const { isValid, errors } = validatePremise(this.premise, this.selectedBuilding, this.selectedType, this.selectedFloor);
                this.errors = errors;

                if (isValid) {
                    if (!this.isUpdate) {
                        this.createPremise();
                    } else {
                        this.changePremise();
                    }
                }
            },

            createPremise() {
                this.prepareDataPremiseFromSelects();
                const premiseToSend = mapPremiseToSend(this.premise);

                greenHubAPI.post('/premises', premiseToSend)
                    .then(() => {
                        this.showCreatePremiseToast();
                        this.updatePremises();
                    })
            },

            changePremise() {
                this.prepareDataPremiseFromSelects();
                const id = this.premise.id;

                const premiseToSend = mapPremiseToSend(this.premise);

                greenHubAPI.patch(`/premises/${id}`, premiseToSend)
                    .then(() => {
                        this.updatePremises();
                    })
            },

            prepareDataPremiseFromSelects() {
                this.premise.buildingId = this.selectedBuilding.id;
                this.premise.type = this.selectedType.id;
                this.premise.floor = this.selectedFloor.id;
                //<--------OPTIONAL FIELD-------->//
                this.premise.entrance = this.selectedEntrance?.id || null;
            },

            updatePremises() {
                this.closeModalPremise();
                this.emitter.emit('updatePremises');
            },

            selectBuilding(building) {
                this.selectedBuilding = building;
                this.clearDependentFields();
            },

            selectFloor(floor) {
                this.selectedFloor = floor;
            },

            selectType(type) {
                this.selectedType = type;
            },

            selectEntrance(entrance) {
                this.selectedEntrance = entrance;
            },

            clearDependentFields() {
                const dependentSelects = {
                    entrance: this.$refs.entrance,
                    floor: this.$refs.floor
                };

                clearDependentSelects(dependentSelects);
            },

            closeModalPremise() {
                this.closeModal('modalPremise');
            },

            clearPremiseErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.selectedPremise.id,
                    name: this.selectedPremise.name,
                    type: 'premise'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-premise {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1118px;
        max-height: 80vh;
        overflow-y: auto;
        scrollbar-width: thin;
    }

    .premise-form {
        h4 {
            margin-bottom: 30px;
        }

        &__info {
            display: flex;
            flex-direction: column;
        }

        &__row {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }
</style>