import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const buildingElements = [
    {
        id: 'name:asc',
        name: t('sort.building')
    },
    {
        id: 'createdAt:desc',
        name: t('sort.createdAt')
    },
    {
        id: 'createdAt:asc',
        name: t('sort.revCreatedAt')
    },
]

const premisesElements = [
    {
        id: 'building_name:asc',
        name: t('sort.building')
    },
    {
        id: 'name:asc',
        name: t('sort.premise')
    },
    {
        id: 'entrance:asc',
        name: t('sort.entrance')
    },
    {
        id: 'entrance:desc',
        name: t('sort.revEntrance')
    },
    {
        id: 'floor:asc',
        name: t('sort.floor')
    },
    {
        id: 'floor:desc',
        name: t('sort.revFloor')
    },
    {
        id: 'createdAt:desc',
        name: t('sort.createdAt')
    },
    {
        id: 'createdAt:asc',
        name: t('sort.revCreatedAt')
    },
]

export {
    premisesElements,
    buildingElements
}