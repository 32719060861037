<template>
    <div class="elevators-table elevator">
        <div class="table__head">
            <div class="table__column elevator__name">
                <app-text-wrapper
                    :text="$t('elevators.table.name')"
                />
            </div>
            <div class="table__column elevator__hwId">
                <app-text-wrapper
                    :text="$t('elevators.table.hwId')"
                />
            </div>
            <div class="table__column elevator__premise">
                <app-text-wrapper
                    :text="$t('elevators.table.premise')"
                />
            </div>
            <div class="table__column elevator__building">
                <app-text-wrapper
                    :text="$t('elevators.table.building')"
                />
            </div>
            <div class="table__column elevator__entrance">
                <app-text-wrapper
                    :text="$t('elevators.table.entrance')"
                />
            </div>
            <div class="table__column elevator__floor">
                <app-text-wrapper
                    :text="$t('elevators.table.floor')"
                />
            </div>
            <div class="table__column elevator__actions">
                <app-text-wrapper
                    :text="$t('elevators.table.actions')"
                />
            </div>
            <div class="table__column elevator__status">
                <app-text-wrapper
                    :text="$t('elevators.table.status')"
                />
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }">
            <elevator-row v-for="elevator in elevators" :key="elevator.id"
                :id="elevator.id"
                :name="elevator.name"
                :hw-id="elevator.hwId"
                :building-name="elevator.buildingName"
                :premise-name="elevator.premiseName"
                :entrance="elevator.entrance"
                :floor="elevator.floor"
                :status="elevator.status"
                :errors="elevator.errors"
                @update="updateElevator"
                @open-modal-send="openModalElevatorSend"
                @open-modal-restart="openModalElevatorRestart"
            />
        </div>
    </div>
</template>

<script>
    import ElevatorRow from "./ElevatorRow";

    export default {
        name: "ElevatorsTable",

        components: {
            ElevatorRow
        },

        props: {
            elevators: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            }
        },

        methods: {
            updateElevator(id) {
                const elevator = this.elevators.find((elevator) => elevator.id === id);

                this.openModal({
                    name: 'modalElevator',
                    selectedEl: elevator
                })
            },

            openModalElevatorRestart(id) {
                const elevator = this.elevators.find((elevator) => elevator.id === id);

                this.openModal({
                    selectedEl: elevator,
                    name: 'modalElevatorRestart'
                });
            },

            openModalElevatorSend(id) {
                const elevator = this.elevators.find((elevator) => elevator.id === id);

                this.openModal({
                    selectedEl: elevator,
                    name: 'modalElevatorSend'
                });
            }
        }
    }
</script>