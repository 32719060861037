<template>
    <div class="premise-type"
        :style="{
            'backgroundColor': premiseType.background
        }"
    >
        <span>{{ premiseType.name }}</span>
    </div>
</template>

<script>
    import { premiseTypes } from "../../../constants/premises";

    export default {
        name: "AppItemType",

        props: {
            type: {
                type: Number,
                required: true,
                validator(type) {
                    return [
                        1,
                        2,
                        3
                    ].includes(type);
                }
            }
        },

        computed: {
            premiseType() {
                return premiseTypes.find((type) => type.id === this.type);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .premise-type {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        border-radius: 100px;
        font-size: 12px;
        line-height: 14px;
        padding: 5px 15px;
        color: var(--app-base-primary);
    }
</style>