import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const waterPadModels = [
    {
        id: 7427,
        name: t('water.pad.models.wp')
    },
    {
        id: 1558,
        name: t('water.pad.models.apator-na-1')
    },
    {
        id: 1559,
        name: t('water.pad.models.apator-na-2')
    },
    {
        id: 9750,
        name: t('water.pad.models.sensus')
    }
]

const waterPadTypes = [
    {
        id: 1,
        name: t('water.pad.types.enter')
    },
    {
        id: 2,
        name: t('water.pad.types.node')
    },
    {
        id: 3,
        name: t('water.pad.types.consumer')
    }
]

export {
    waterPadModels,
    waterPadTypes
}