import { showToast } from "../../helpers/toasts";

const elevatorsToastsMixin = {
    methods: {
        showDeleteElevatorToast(name) {
            const deleteToastTitle = this.$t('toasts.elevators.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.elevators.delete.details1`)}
                    ${name}
                    ${this.$t(`toasts.elevators.delete.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showCreateElevatorToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.elevators.create.details')
            );
        },

        showElevatorSendToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.elevators.send')
            );
        },

        showElevatorRestartToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.elevators.restart')
            );
        },
        
        showElevatorCommandError() {
            showToast('error',
                this.$t('toasts.error'),
                this.$t('toasts.tryAgain')
            );
        }
    }
}

export {
    elevatorsToastsMixin
}