<template>
    <div
        :class="{
            'building__logo--is-more-16' : isMore16,
            'building__logo--is-more-32': isMore32
        }"
    >
        <img v-if="logo" :src="logo" alt="logo">
        <div v-if="!logo && !logoIsLoaded" class="building__logo-name">
            <div>{{ firstPart }}</div>
            <div v-if="!!secondPart" class="building__logo--is-second-part">
                {{ secondPart }}
            </div>
        </div>
        <app-loader v-if="!logo && logoIsLoaded"
            :size="'small'"
        />
    </div>
</template>

<script>
    import { getImageFromUrl } from "../../api/requests/common";

    export default {
        name: "BuildingLogoWrapper",

        props: {
            logoUrl: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                logo: '',
                logoIsLoaded: false,
                isMore16: false,
                isMore32: false,
                firstPart: '',
                secondPart: '',
            }
        },

        created() {
            this.initBuildingLogo();
        },

        methods: {
            initBuildingLogo() {
                if (this.logoUrl) {
                    this.getBuildingLogo(this.logoUrl);
                } else {
                    this.logo = '';
                    this.modifyBuildingLogoName();
                }
            },

            async getBuildingLogo(imageUrl) {
                this.logoIsLoaded = true;

                await getImageFromUrl(imageUrl)
                    .then((image) => {
                        this.logo = image;
                    })
                    .finally(() => {
                        this.logoIsLoaded = false;
                    })
            },

            modifyBuildingLogoName() {
                if (this.name.length <= 16) {
                    this.firstPart = this.name;
                } else {
                    if (this.name.length >= 64) {
                        this.firstPart = this.name.substring(0, 32);
                        this.secondPart = `${this.name.substring(32, 62)}...`;
                        this.isMore32 = true;
                    } else if (this.name.length <= 32) {
                        this.firstPart = this.name;
                    } else {
                        this.firstPart = this.name.substring(0, 32);
                        this.secondPart = this.name.substring(32, this.name.length);
                        this.isMore32 = true;
                    }

                    this.isMore16 = true;
                }
            }
        }
    }
</script>