import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const emptyContentInfo = {
    welcome: t('empty.welcome'),
    buildings: {
        label: t('empty.buildings.label'),
        btn: t('empty.buildings.btn')
    },
    premises: {
        label: t('empty.premises.label'),
        btn: t('empty.premises.btn')
    },
    equipments: {
        label: t('empty.equipments.label'),
        btn: t('empty.equipments.btn')
    },
    water: {
        label: t('empty.water.label'),
        btn: t('empty.water.btn')
    },
    elevators: {
        label: t('empty.elevators.label'),
        btn: t('empty.elevators.btn')
    },
    switchgears: {
        label: t('empty.switchgears.label'),
        btn: t('empty.switchgears.btn')
    },
    balancerEquipment: {
        label: t('empty.boilers.label'),
        btn: t('empty.boilers.btn')
    },
    balancerStation: {
        label: t('empty.stations.label'),
        btn: t('empty.stations.btn')
    },
    security: {
        label: t('empty.security.label'),
        btn: t('empty.security.btn')
    }
}

const noResultsContentInfo = {
    title: t('filters.empty.title'),
    details: t('filters.empty.details'),
    btn: t('filters.empty.btn')
}

export {
    emptyContentInfo,
    noResultsContentInfo
}