<template>
    <div class="building-filters filters">
        <div class="filters__control">
            <div class="filters__sorting filters__element">
                <app-base-select
                    :selected-option="selectedSort"
                    :options="elements"
                    :place-holder="$t('sort.ph')"
                    :type="'filter'"
                    :is-clear="true"
                    @select="selectSort"
                />
            </div>
            <div class="filters__searching filters__element">
                <app-base-search
                    :qty="qty"
                    :show-qty="search.showQty"
                    v-model="search.currentValue"
                    @update="updateBySearch"
                    @clear="clearAndUpdateSearch"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { getResultText } from "../../constants/filters";
    import AppFilterActiveItem from "../filters/AppFilterActiveItem";
    import { buildingElements } from "../../constants/sorting";

    export default {
        name: "BuildingsFilters",

        components: {
            AppFilterActiveItem
        },

        props: {
            qty: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                selectedSort: null,
                search: {
                    currentValue: '',
                    updatedValue: '',
                    showQty: false
                }
            }
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        computed: {
            qtyResults() {
                return `${this.qty} ${getResultText(this.qty)}`;
            },

            elements() {
                return buildingElements;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateShowQty', this.checkShowQty);
                this.emitter.on('clearQueryParams', this.clearQueryParams);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateShowQty', this.checkShowQty);
                this.emitter.off('clearQueryParams', this.clearQueryParams);
            },

            updateBySearch() {
                this.search.updatedValue = this.search.currentValue;
                this.updateQueryParams();
            },

            selectSort(element) {
                this.selectedSort = element;
                this.updateQueryParams();
            },

            clearQueryParams() {
                this.clearSearch();
                this.updateQueryParams();
            },

            clearSearch() {
                this.search.updatedValue = '';
                this.search.currentValue = '';
                this.checkShowQty();
            },

            clearAndUpdateSearch() {
                this.clearSearch();
                this.updateQueryParams();
            },

            updateQueryParams() {
                let params = null;
                const match = this.search.updatedValue;
                const sortBy = this.selectedSort;

                if (match || sortBy) {
                    params = {};

                    if (sortBy) {
                        params.sortBy = sortBy.id;
                    }

                    if (match) {
                        params.match =  match;
                    }
                }

                this.$emit('update', params);
            },

            checkShowQty() {
                this.search.showQty = !!this.search.updatedValue;
            },
        }
    }
</script>