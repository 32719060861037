const modals = {
    namespaced: true,

    state: () => ({
        active: [],
        selectedEl: null
    }),

    getters: {
        showModalRecoveryPassword(state) {
            return state.active.includes('modalRecoveryPassword');
        },

        showModalLogout(state) {
            return state.active.includes('modalLogout');
        },

        showModalUploadIcon(state) {
            return state.active.includes('modalUploadIcon');
        },

        showModalBuilding(state) {
            return state.active.includes('modalBuilding');
        },

        showModalPremise(state) {
            return state.active.includes('modalPremise');
        },

        showModalWaterPad(state) {
            return state.active.includes('modalWaterPad');
        },

        showModalSetReadings(state) {
            return state.active.includes('modalSetReadings');
        },

        showModalElectricitySwitchgear(state) {
            return state.active.includes('modalElectricitySwitchgear');
        },

        showModalElectricityConsumer(state) {
            return state.active.includes('modalElectricityConsumer');
        },

        showModalBalancerEquipment(state) {
            return state.active.includes('modalBalancerEquipment');
        },

        showModalBalancerStation(state) {
            return state.active.includes('modalBalancerStation');
        },

        showModalEquipmentBC(state) {
            return state.active.includes('modalEquipmentBC');
        },

        showModalEquipmentDC(state) {
            return state.active.includes('modalEquipmentDC');
        },

        showModalElevator(state) {
            return state.active.includes('modalElevator');
        },

        showModalElevatorRestart(state) {
            return state.active.includes('modalElevatorRestart');
        },

        showModalElevatorTurnOffAlarm(state) {
            return state.active.includes('modalElevatorTurnOffAlarm');
        },

        showModalElevatorSend(state) {
            return state.active.includes('modalElevatorSend');
        },

        showModalSecurityObject(state) {
            return state.active.includes('modalSecurityObject');
        },

        showModalConfirm(state) {
            return state.active.includes('modalConfirm');
        },

        selectedElement(state) {
            return state.selectedEl;
        }
    },

    mutations: {
        open(state, name) {
            state.active.push(name);
        },

        close(state, name) {
            state.active = state.active.filter((modalName) => modalName !== name);
        },

        closeOne(state) {
            if (state.active.length) {
                const lastModalName = state.active[state.active.length - 1];
                state.active = state.active.filter((modalName) => modalName !== lastModalName);
            }
        },

        updateSelectedEl(state, selectedEl) {
            state.selectedEl = selectedEl;
        }
    },

    actions: {
        openModal({ commit }, { name, selectedEl }) {
            if (selectedEl) {
                commit('updateSelectedEl', selectedEl);
            }

            commit('open', name);
        },

        closeModal({ commit, state }, name) {
            //We need this condition, because we don't need clear selectedEl when close modalConfirm
            if (name !== 'modalConfirm') {
                commit('updateSelectedEl', null);
            } else {
                //Check condition if only modalConfirm has been opened
                if (state.active.length === 1) {
                    commit('updateSelectedEl', null);
                }
            }

            commit('close', name);
        },

        closeOneModal({ commit, state }) {
            const lastModalName = state.active[state.active.length - 1];

            //We need this condition, because we don't need clear selectedEl when close modalConfirm
            if (lastModalName !== 'modalConfirm') {
                commit('updateSelectedEl', null);
            } else {
                //Check condition if only modalConfirm has been opened
                if (state.active.length === 1) {
                    commit('updateSelectedEl', null);
                }
            }

            commit('closeOne');
        }
    }
}

export {
    modals
}