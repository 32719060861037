<template>
    <div class="premises-filters filters">
        <div class="filters__control">
            <div class="filters__sorting filters__element">
                <app-base-select
                    :selected-option="selectedSort"
                    :options="elements"
                    :place-holder="$t('sort.ph')"
                    :type="'filter'"
                    :is-clear="true"
                    @select="selectSort"
                />
            </div>
            <div class="filters__filtering filters__element">
                <app-base-filters
                    :qty="qty"
                    :filters="filters"
                    :active-filters="activeFilters"
                    @update="updateActiveFilters"
                />
            </div>
            <div class="filters__searching filters__element">
                <app-base-search
                    v-model="search.currentValue"
                    :qty="qty"
                    :show-qty="search.showQty"
                    @update="updateBySearch"
                    @clear="clearAndUpdateSearch"
                />
            </div>
        </div>
        <div v-if="showFilterPanel" class="filters__panel">
            <div class="filters__results">
                {{ qtyResults }}
            </div>
            <div v-for="filter in modActiveFilters" :key="filter.id">
                <app-filter-active-item class="filters__active-element"
                    :id="filter.id"
                    :name="filter.name"
                    :filter-key="filter.key"
                    @remove="removeActiveFilter"
                />
            </div>
            <div class="filters__reset-btn">
                <app-text-btn
                    :text="$t('filters.clear')"
                    @action="clearAndUpdateActiveFilters"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import AppFilterActiveItem from "../filters/AppFilterActiveItem";
    import { mapActiveFiltersToString, getResultText} from "../../constants/filters";
    import { premisesElements } from "../../constants/sorting";
    import { mapGetters } from "vuex";

    export default {
        name: "PremisesFilters",

        components: {
            AppFilterActiveItem
        },

        props: {
            qty: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                selectedSort: null,
                search: {
                    currentValue: '',
                    updatedValue: '',
                    showQty: false
                },
                activeFilters: {
                    buildings: [],
                    entrances: [],
                    floors: [],
                    types: []
                },
                modActiveFilters: [],
                showFilterPanel: false
            }
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        computed: {
            ...mapGetters({
                filters: 'filters/premiseFilters'
            }),

            elements() {
                return premisesElements;
            },

            qtyResults() {
                return `${this.qty} ${getResultText(this.qty)}`
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateShowQty', this.checkShowQty);
                this.emitter.on('clearQueryParams', this.clearQueryParams);
                this.emitter.on('updateFilters', this.updateFilters);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateShowQty', this.checkShowQty);
                this.emitter.off('clearQueryParams', this.clearQueryParams);
                this.emitter.off('updateFilters', this.updateFilters);
            },

            selectSort(element) {
                this.selectedSort = element;
                this.updateQueryParams();
            },

            updateBySearch() {
                this.search.updatedValue = this.search.currentValue;
                this.updateQueryParams();
            },

            clearQueryParams() {
                this.clearSearch();
                this.clearActiveFilters();
                this.updateQueryParams();
            },

            clearAndUpdateSearch() {
                this.clearSearch();
                this.updateQueryParams();
            },

            clearSearch() {
                this.search.updatedValue = '';
                this.search.currentValue = '';
                this.checkShowQty();
            },

            checkShowQty() {
                this.search.showQty = !!this.search.updatedValue;
            },

            updateFilters() {
                this.updateQueryParams();
            },

            clearActiveFilters() {
                const activeFiltersKeys = Object.keys(this.activeFilters);

                activeFiltersKeys.forEach((key) => {
                    this.activeFilters[key].length = 0;
                });

                // this.showFilterPanel = false;
                this.checkShowFiltersPanel();
            },

            clearAndUpdateActiveFilters() {
                this.clearActiveFilters();
                this.updateQueryParams();
            },

            removeActiveFilter(key, id) {
                this.activeFilters[key] = this.activeFilters[key].filter((elementId) => elementId !== id);
                this.checkShowFiltersPanel();
                this.updateQueryParams();
            },

            checkShowFiltersPanel() {
                const activeFiltersKeys = Object.keys(this.activeFilters);
                this.modActiveFilters = [];

                activeFiltersKeys.forEach((key) => {
                    this.activeFilters[key].forEach((id) => {
                        const targetFilter = this.filters[key].find((filter) => filter.id === id);
                        const filter = { ...targetFilter };
                        filter.key = key;
                        this.modActiveFilters.push(filter);
                    })
                })

                this.showFilterPanel = this.modActiveFilters.length;
            },

            updateActiveFilters(name, activeFilters) {
                this.activeFilters[name] = activeFilters;
                //TODO: SEVERAL TIMES I CHECK SHOW PANEL
                this.checkShowFiltersPanel();
            },

            updateQueryParams() {
                let params = null;
                const match = this.search.updatedValue;
                const sortBy = this.selectedSort;
                const filters = this.modActiveFilters.length;

                if (match || sortBy || filters) {
                    params = {};

                    if (sortBy) {
                        params.sortBy = sortBy.id;
                    }

                    if (filters) {
                        params.filter =  mapActiveFiltersToString(this.activeFilters);
                    }

                    if (match) {
                        params.match = match;
                    }
                }

                this.$emit('updateQueryParams', params);
            }
        }
    }
</script>