<template>
    <div class="security__table">
        <div class="table__head">
            <div class="table__column security-object__name">
                <app-text-wrapper
                    :text="$t('security.table.name')"
                />
            </div>
            <div class="table__column security-object__building">
                <app-text-wrapper
                    :text="$t('security.table.building')"
                />
            </div>
            <div class="table__column security-object__entrance">
                <app-text-wrapper
                    :text="$t('security.table.entrance')"
                />
            </div>
            <div class="table__column security-object__floor">
                <app-text-wrapper
                    :text="$t('security.table.floor')"
                />
            </div>
            <div class="table__column security-object__channel">
                <app-text-wrapper
                    :text="$t('security.table.channel')"
                />
            </div>
            <div class="table__column security-object__state">
                <app-text-wrapper
                    :text="$t('security.table.state')"
                />
            </div>
            <div class="table__column security-object__status">
                <app-text-wrapper
                    :text="$t('security.table.status')"
                />
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }">
            <security-object-row v-for="object in securityObjects" :key="object.id"
                :id="object.id"
                :name="object.name"
                :building-name="object.buildingName"
                :entrance="object.entrance"
                :floor="object.floor"
                :channel="object.channel"
                :state="object.state"
                :status="object.status"
                :errors="object.errors || []"
                @update="updateSecurityObject"
            />
        </div>
    </div>
</template>

<script>
    import SecurityObjectRow from "./elements/SecurityObjectRow";

    export default {
        name: "SecurityTable",

        components: {
            SecurityObjectRow
        },

        props: {
            securityObjects: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            }
        },

        methods: {
            updateSecurityObject(id) {
                const securityObject = this.securityObjects.find((object) => object.id === id);

                this.openModal({
                    name: 'modalSecurityObject',
                    selectedEl: securityObject
                })
            }
        }
    }
</script>