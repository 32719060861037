<template>
    <div class="table__row balancer-input">
        <div class="table__column balancer-input__station">
            {{ stationName }}
        </div>
        <div class="table__column balancer-input__name">
            {{ inputName }}
        </div>
        <div class="table__column balancer-input__power">
            {{ formattedPower }}
        </div>
        <div class="table__column balancer-input__unbalance">
            {{ formattedUnbalance }}
        </div>
        <div class="table__column balancer-input__state">
            <app-base-state
                :id="inputState.id"
                :name="inputState.name"
                :background="inputState.background"
            />
        </div>
    </div>
</template>

<script>
    import { balancerStates } from "../../../constants/states";

    export default {
        name: "BalancerInputStationRow",

        props: {
            id: {
                type: String,
                required: true
            },
            stationName: {
                type: String,
                required: true
            },
            inputName: {
                type: String,
                required: true
            },
            power: {
                type: Number,
                required: true
            },
            unbalance: {
                type: Number,
                required: true
            },
            state: {
                type: Number,
                required: true
            }
        },

        computed: {
            formattedPower() {
                return `${this.$t('balancer.table.power')} ${this.power} ${this.$t('common.units.power')}`;
            },

            formattedUnbalance() {
                return `${this.$t('balancer.table.phaseUnbalance')} < ${this.unbalance} ${this.$t('common.units.percent')}`;
            },

            inputState() {
                return balancerStates.find((state) => state.id === this.state);
            }
        }
    }
</script>