<template>
    <app-modal-wrapper>
        <form @submit.prevent="doActionBalancerEquipment" class="modal-balancer-equipment">
            <div class="modal-balancer-equipment__content">
                <div class="balancer-equipment-form">
                    <h4>{{ title }}</h4>
                    <div class="balancer-equipment-form__info">
                        <div class="balancer-equipment-form__row">
                            <div class="balancer-equipment-form__details">
                                <app-base-select
                                    :label="$t('balancer.equipment.building.label')"
                                    :place-holder="$t('balancer.equipment.building.ph')"
                                    :selected-option="selectedBuilding"
                                    :options="buildings"
                                    :type="'text'"
                                    :error-message="errors.building"
                                    @select="selectBuilding"
                                />
                            </div>
                            <div class="balancer-equipment-form__details">
                                <app-base-select ref="premise"
                                    :label="$t('balancer.equipment.premise.label')"
                                    :place-holder="$t('balancer.equipment.premise.ph')"
                                    :selected-option="selectedPremise"
                                    :options="premises"
                                    :type="'text'"
                                    :error-message="errors.premise"
                                    @select="selectPremise"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="balancer-equipment-form__info">
                        <div class="balancer-equipment-form__row">
                            <div class="balancer-equipment-form__details">
                                <app-base-select ref="switchgear"
                                    :label="$t('balancer.equipment.switchgear.label')"
                                    :place-holder="$t('balancer.equipment.switchgear.ph')"
                                    :selected-option="selectedSwitchgear"
                                    :options="switchgears"
                                    :type="'text'"
                                    :error-message="errors.switchgear"
                                    @select="selectSwitchgear"
                                />
                            </div>
                            <div class="balancer-equipment-form__details">
                                <app-base-select ref="inputNumber"
                                    :label="$t('balancer.equipment.inputNumber.label')"
                                    :place-holder="$t('balancer.equipment.inputNumber.ph')"
                                    :selected-option="selectedInputNumber"
                                    :options="inputs"
                                    :type="'text'"
                                    :error-message="errors.inputNumber"
                                    @select="selectInputNumber"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="balancer-equipment-form__info">
                        <div class="balancer-equipment-form__row">
                            <div class="balancer-equipment-form__details">
                                <app-base-input
                                    v-model="equipment.boiler.hwId"
                                    :label="$t('balancer.equipment.boiler.hwId.label')"
                                    :place-holder="$t('balancer.equipment.boiler.hwId.ph')"
                                    :error-message="errors.hwId"
                                />
                            </div>
                            <div class="balancer-equipment-form__details">
                                <app-base-select ref="controller"
                                    :open-direction="'top'"
                                    :label="$t('balancer.equipment.boiler.controller.label')"
                                    :place-holder="$t('balancer.equipment.boiler.controller.ph')"
                                    :selected-option="selectedController"
                                    :options="dcs"
                                    :type="'text'"
                                    :error-message="errors.controller"
                                    @select="selectController"
                                />
                            </div>
                        </div>
                        <div class="balancer-equipment-form__row">
                            <div class="balancer-equipment-form__details">
                                <app-base-input
                                    v-model="equipment.boiler.maxPower"
                                    :label="$t('balancer.equipment.boiler.maxPower.label')"
                                    :place-holder="$t('balancer.equipment.boiler.maxPower.ph')"
                                    :error-message="errors.maxPower"
                                />
                            </div>
                            <div class="balancer-equipment-form__details"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-balancer-equipment__control">
                <app-base-btn v-if="isUpdate"
                    :variation="'delete'"
                    :text="$t('common.buttons.delete')"
                    @action="openModalConfirm"
                />
                <app-base-btn
                    :variation="'cancel'"
                    :text="$t('common.buttons.cancel')"
                    @action="closeModalBalancerEquipment"
                />
                <app-base-btn
                    :type="'submit'"
                    :variation="'save'"
                    :text="$t('common.buttons.save')"
                />
            </div>
        </form>
    </app-modal-wrapper>
</template>

<script>
    import { mapGetters } from "vuex";
    import { greenHubAPI } from "../../../config/api";
    import { balancerToastsMixin } from "../../../mixins/toasts/balancer";
    import { electricityInputs } from "../../../constants/electricity";
    import cloneDeep from "lodash.clonedeep";
    import { prepareQueryFilterParams } from "../../../helpers/api";
    import { mapPremisesToSelect } from "../../../api/mappers/premises";
    import { mapEquipmentsDCToSelect } from "../../../api/mappers/equipments";
    import { clearDependentSelects } from "../../../helpers/selects";
    import { validateEquipment } from "../../../validations/balancer";
    import { mapBalancerEquipmentToSend } from "../../../api/mappers/balancer";
    import { mapSwitchgearsToSelect } from "../../../api/mappers/electricity";

    export default {
        name: "ModalBalancerEquipment",

        props: {
            selectedEquipment: {
                type: Object
            }
        },

        mixins: [
            balancerToastsMixin
        ],

        data() {
            return {
                selectedBuilding: null,
                selectedPremise: null,
                selectedSwitchgear: null,
                selectedInputNumber: null,
                selectedController: null,

                premises: [],
                switchgears: [],
                dcs: [],

                equipment: {
                    boiler: {
                        hwId: '',
                        maxPower: ''
                    }
                },

                errors: {
                    building: '',
                    premise: '',
                    switchgear: '',
                    inputNumber: '',
                    hwId: '',
                    controller: '',
                    maxPower: ''
                }
            }
        },

        created() {
            this.checkUpdateEquipment();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings'
            }),

            isUpdate() {
                return !!this.selectedEquipment;
            },

            title() {
                return !this.isUpdate
                    ? this.$t('balancer.equipment.title.create')
                    : this.$t('balancer.equipment.title.edit');
            },

            inputs() {
                return electricityInputs;
            }
        },

        methods: {
            checkUpdateEquipment() {
                if (this.isUpdate) {
                    this.equipment = cloneDeep(this.selectedEquipment);
                    this.selectedBuilding = this.buildings.find((building) => building.id === this.selectedEquipment.buildingId);
                    this.selectedInputNumber = this.inputs.find((input) => input.id === this.selectedEquipment.inputNumber);

                    this.getAdditionalData();
                }
            },

            getAdditionalData() {
                this.getPremises();
                this.getElectricitySwitchgears();
                this.getControllers();
            },

            getPremises() {
                const queryFilterParams = {
                    building_id: this.selectedBuilding.id
                };

                const params = prepareQueryFilterParams(queryFilterParams);

                greenHubAPI.get('/premises', params)
                    .then((result) => {
                        const { qty, premises } = result.data;
                        this.premises = mapPremisesToSelect(premises);

                        if (this.isUpdate) {
                            this.selectedPremise = this.premises.find((premise) => premise.id === this.selectedEquipment.premiseId);
                        }
                    })
            },

            getElectricitySwitchgears() {
                const id = this.selectedBuilding.id;

                greenHubAPI.get(`electricity/${id}`)
                    .then((result) => {
                        this.switchgears = mapSwitchgearsToSelect(result.data);

                        if (this.isUpdate) {
                            this.selectedSwitchgear = this.switchgears.find((switchgear) => switchgear.id === this.selectedEquipment.switchgearId);
                        }
                    })
            },

            getControllers() {
                const buildingId = this.selectedBuilding.id;

                greenHubAPI.get(`/equipments/${buildingId}`)
                    .then((result) => {
                        const controllers = result.data.buildingControllers;

                        this.dcs = mapEquipmentsDCToSelect(controllers);

                        if (this.isUpdate) {
                            this.selectedController = this.dcs.find((controller) => controller.id === this.selectedEquipment.parentSwId);
                        }
                    })
            },

            doActionBalancerEquipment() {
                this.clearEquipmentErrors();
                const { isValid, errors } = validateEquipment(
                    this.equipment,
                    this.selectedBuilding,
                    this.selectedPremise,
                    this.selectedSwitchgear,
                    this.selectedInputNumber,
                    this.selectedController
                );
                this.errors = errors;

                if (isValid) {
                    if (!this.isUpdate) {
                        this.createBalancerEquipment();
                    } else {
                        this.changeBalancerEquipment();
                    }
                }
            },

            createBalancerEquipment() {
                this.prepareDataEquipmentFromSelects();

                const equipmentToSend = mapBalancerEquipmentToSend(this.equipment);

                greenHubAPI.post('/balancer/equipment', equipmentToSend)
                    .then(() => {
                        this.updateBalancerEquipments();
                        this.showCreateBalancerEquipmentToast();
                    })
            },

            changeBalancerEquipment() {
                this.prepareDataEquipmentFromSelects();
                const id = this.equipment.id;

                const equipmentToSend = mapBalancerEquipmentToSend(this.equipment);

                greenHubAPI.patch(`/balancer/equipment/${id}`, equipmentToSend)
                    .then(() => {
                        this.updateBalancerEquipments();
                    })
            },

            prepareDataEquipmentFromSelects() {
                this.equipment.buildingId = this.selectedBuilding.id;
                this.equipment.premiseId = this.selectedPremise.id;
                this.equipment.switchgearId = this.selectedSwitchgear.id;
                this.equipment.inputNumber = this.selectedInputNumber.id;
                this.equipment.parentSwId = this.selectedController.id;
            },

            updateBalancerEquipments() {
                this.closeModalBalancerEquipment();
                this.emitter.emit('updateBalancerEquipments');
            },

            selectBuilding(building) {
                this.selectedBuilding = building;

                this.clearDependentFields(this.$refs);
                this.getAdditionalData();
            },

            selectPremise(premise) {
                this.selectedPremise = premise;
            },

            selectSwitchgear(switchgear) {
                this.selectedSwitchgear = switchgear;
            },

            selectInputNumber(inputNumber) {
                this.selectedInputNumber = inputNumber;
            },

            selectController(controller) {
                this.selectedController = controller;
            },

            clearDependentFields(selects) {
                clearDependentSelects(selects);
                const refKeys = Object.keys(selects);

                this.$nextTick(() => {
                    refKeys.forEach((key) => selects[key].initSelect());
                })
            },

            clearEquipmentErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.equipment.id,
                    name: this.equipment.boiler.hwId,
                    type: 'boiler'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            },

            closeModalBalancerEquipment() {
                this.closeModal('modalBalancerEquipment')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-balancer-equipment {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;
        max-height: 80vh;
        overflow-y: auto;

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    .balancer-equipment-form {
        &__info {
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            &:not(:last-child) {
                padding-bottom: 30px;
                border-bottom: 1px solid var(--app-border);
            }
        }

        &__row {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }
    }
</style>