<template>
    <app-modal-wrapper>
        <form @submit.prevent="doActionBalancerStation" class="modal-balancer-station">
            <div class="modal-balancer-station__content">
                <div class="balancer-station-form">
                    <h4>{{ title }}</h4>
                    <div class="balancer-station-form__info">
                        <div class="balancer-station-form__name">
                            <app-base-input
                                v-model="station.name"
                                :label="$t('balancer.station.label')"
                                :place-holder="$t('balancer.station.ph')"
                                :error-message="errors.name"
                            />
                        </div>
                        <div class="station-form__buildings">
                            <div class="app__label">
                                {{ $t('balancer.station.buildings.label') }}
                            </div>
                            <div class="station-form__buildings-list">
                                <app-list-item v-for="building in station.buildings" :key="building.id"
                                    :is-disabled="true"
                                    :id="building.id"
                                    :text="building.name"
                                    @remove="removeBuilding"
                                />
                            </div>
                            <div class="station-form__building">
                                <app-base-select ref="building"
                                    :open-direction="'top'"
                                    :place-holder="$t('balancer.station.buildings.ph')"
                                    :selected-option="selectedBuilding"
                                    :options="filteredBuildings"
                                    :type="'text'"
                                    :error-message="errors.buildings"
                                    @select="selectBuilding"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-balancer-station__control">
                <app-base-btn v-if="isUpdate"
                    :variation="'delete'"
                    :text="$t('common.buttons.delete')"
                    @action="openModalConfirm"
                />
                <app-base-btn v-if="!isUpdate"
                    :variation="'cancel'"
                    :text="$t('common.buttons.cancel')"
                    @action="closeModalBalancerStation"
                />
                <app-base-btn
                    :type="'submit'"
                    :variation="'save'"
                    :text="$t('common.buttons.save')"
                />
            </div>
        </form>
    </app-modal-wrapper>
</template>

<script>
    import { greenHubAPI } from "../../../config/api";
    import { mapGetters } from "vuex";
    import { balancerToastsMixin } from "../../../mixins/toasts/balancer";
    import cloneDeep from "lodash.clonedeep";
    import { clearAndInitDependentSelects } from "../../../helpers/selects";
    import { validateStation } from "../../../validations/balancer";
    import { mapBalancerStationToSend } from "../../../api/mappers/balancer";
    import AppListItem from "../../common/local/AppListItem";

    export default {
        name: "ModalBalancerStation",

        components: {
            AppListItem
        },

        props: {
            selectedStation: {
                type: Object
            }
        },

        mixins: [
            balancerToastsMixin
        ],

        data() {
            return {
                selectedBuilding: null,

                station: {
                    name: '',
                    buildings: []
                },

                errors: {
                    name: '',
                    buildings: ''
                }
            }
        },

        created() {
            this.checkUpdateStation();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings'
            }),

            title() {
                return !this.isUpdate
                    ? this.$t('balancer.station.title.create')
                    : this.$t('balancer.station.title.edit');
            },

            isUpdate() {
                return !!this.selectedStation;
            },

            selectedBuildingIds() {
                return this.station.buildings.map((building) => building.id);
            },

            filteredBuildings() {
                return this.buildings.filter((building) => !this.selectedBuildingIds.includes(building.id));
            }
        },

        methods: {
            checkUpdateStation() {
                if (this.isUpdate) {
                    this.station = cloneDeep(this.selectedStation);
                    this.station.buildings = this.station.children;
                }
            },

            doActionBalancerStation() {
                this.clearStationErrors();
                const { isValid, errors } = validateStation(this.station);
                this.errors = errors;

                if (isValid) {
                    if (!this.isUpdate) {
                        this.createBalancerStation();
                    } else {
                        this.changeBalancerStation();
                    }
                }
            },

            createBalancerStation() {
                const stationToSend = mapBalancerStationToSend(this.station);

                greenHubAPI.post('/balancer/transformer', stationToSend)
                    .then(() => {
                        this.updateBalancerStations();
                        this.showCreateBalancerStationToast();
                    })
            },

            changeBalancerStation() {
                const stationToSend = mapBalancerStationToSend(this.station);
                const id = this.station.id;

                greenHubAPI.patch(`/balancer/transformer/${id}`, stationToSend)
                    .then(() => {
                        this.updateBalancerStations();
                    })
            },

            updateBalancerStations() {
                this.closeModalBalancerStation();
                this.emitter.emit('updateBalancerStations');
            },

            selectBuilding(building) {
                if (building) {
                    this.station.buildings.push(building);

                    this.$nextTick(() => {
                        clearAndInitDependentSelects(this.$refs);
                    })
                }
            },

            removeBuilding(id) {
                this.station.buildings = this.station.buildings.filter((building) => building.id !== id);
            },

            clearStationErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            closeModalBalancerStation() {
                this.closeModal('modalBalancerStation');
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.station.id,
                    name: this.station.name,
                    type: 'station'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-balancer-station {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 574px;
        width: 100%;

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    .balancer-station-form {
        &__info {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
        }

        &__name {
            margin-bottom: 15px;
        }
    }
</style>