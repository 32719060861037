<template>
    <div class="premises-table premise">
        <div class="table__head">
            <div class="table__column premise__building">
                <app-text-wrapper
                    :text="$t('premises.table.building')"
                />
            </div>
            <div class="table__column premise__entrance">
                <app-text-wrapper
                    :text="$t('premises.table.entrance')"
                />
            </div>
            <div class="table__column premise__floor">
                <app-text-wrapper
                    :text="$t('premises.table.floor')"
                />
            </div>
            <div class="table__column premise__type">
                <app-text-wrapper
                    :text="$t('premises.table.type')"
                />
            </div>
            <div class="table__column premise__name">
                <app-text-wrapper
                    :text="$t('premises.table.name')"
                />
            </div>
        </div>
        <div class="table__body" :style="{ 'maxHeight': maxHeight }" @scroll="handlerBodyTableScroll">
            <premise-row v-for="premise in premises" :key="premise.id"
                :id="premise.id"
                :building-name="premise.buildingName"
                :entrance="premise.entrance"
                :floor="premise.floor"
                :type="premise.type"
                :name="premise.name"
                @update="updatePremise"
            />
        </div>
    </div>
</template>

<script>
    import PremiseRow from "./PremiseRow";

    export default {
        name: "PremisesTable",

        components: {
            PremiseRow
        },

        props: {
            premises: {
                type: Array,
                required: true
            },
            maxHeight: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                isLoadedMore: false,
                isNoMore: false
            }
        },

        mounted() {
            this.emitter.on('isLoadedMore', this.isLoadedMorePremises);
            this.emitter.on('isNoMore', this.isNoMorePremises);
            this.emitter.on('clearNoMore', this.clearNoMorePremises);
        },

        unmounted() {
            this.emitter.off('isLoadedMore', this.isLoadedMorePremises);
            this.emitter.off('isNoMore', this.isNoMorePremises);
            this.emitter.off('clearNoMore', this.clearNoMorePremises);
        },

        methods: {
            updatePremise(id) {
                const premise = this.premises.find((premise) => premise.id === id);

                this.openModal({
                    name: 'modalPremise',
                    selectedEl: premise
                })
            },

            handlerBodyTableScroll(event) {
                //WE NEED ADDITIONAL OFFSET FOR SCALE WINDOWS(DEVIATION < 1px)
                const OFFSET = 4;

                const scrollHeight = event.target.scrollHeight;
                const clientHeight = event.target.clientHeight;
                const scrollPosition = event.target.scrollTop;

                const canLoadMore = !this.isLoadedMore && !this.isNoMore;

                if (scrollPosition >= scrollHeight - clientHeight - OFFSET && canLoadMore) {
                    this.isLoadedMore = true;
                    this.$emit('loadByScroll');
                }
            },

            isLoadedMorePremises() {
                this.isLoadedMore = false;
            },

            isNoMorePremises() {
                this.isNoMore = true;
            },

            clearNoMorePremises() {
                this.isNoMore = false;
            }
        }
    }
</script>

<style lang="scss">
    .premise {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__building {
            width: 20%;
        }

        &__entrance {
            width: 15%;
        }

        &__floor {
            width: 20%;
        }

        &__type {
            width: 25%;
        }

        &__name {
            width: 20%;
        }
    }
</style>