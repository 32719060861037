import { showToast } from "../../helpers/toasts";

const waterToastsMixin = {
    methods: {
        showDeleteWaterPadToast(name) {
            const deleteToastTitle = this.$t('toasts.water.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.water.delete.details1`)}
                    ${name}
                    ${this.$t(`toasts.water.delete.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showCreateWaterPadToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.water.create.details')
            );
        },

        showSetReadingsSuccessToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.waterReadings.success.details')
            );
        },

        showSetReadingsErrorToast() {
            showToast('error',
                this.$t('toasts.error'),
                this.$t('toasts.waterReadings.error.details')
            );
        },

        showClearErrorsToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.water.clearErrors.details')
            );
        }
    }
}

export {
    waterToastsMixin
}