import i18n from "../../../src/localization/i18n"
const { t } = i18n.global;

function mapSwitchgearsToView(switchgears) {
    let qty = 0;

    const mappedSwitchgears = switchgears.map((switchgear) => {
        qty += 1;

        const floor = switchgear.floor;
        const entrance = switchgear.entrance;


        const mappedInputCounters = switchgear.inputMeters.map((counter, index) => {
            qty += 1;

            return mapSwitchgearCounterToView(counter, index, 'input', floor, entrance);
        });


        const mappedOutputCounters = switchgear.outputMeters.map((counter, index) => {
            qty += 1 + counter.consumerMeters.length;

            return mapSwitchgearCounterToView(counter, index, 'output', floor, entrance);
        });

        return {
            id: switchgear._id,
            name: switchgear.name,
            buildingId: switchgear.building_id,
            premiseId: switchgear.premise_id,
            premiseName: switchgear.premise_name || '',
            floor: floor,
            entrance: entrance,
            parentType: switchgear.parent_type,
            parentSwId: switchgear.parent_sw_id,
            inputCounters: mappedInputCounters,
            outputCounters: mappedOutputCounters,
            children: mappedInputCounters.concat(mappedOutputCounters)
        }
    })

    return { qty, mappedSwitchgears };
}

function mapSwitchgearCounterToView(counter, index, type, floor, entrance) {
    const counterTypeText = t(`electricity.switchgear.counters.${type}.name`);

    return {
        name: type === 'output' ? counter.name : `${counterTypeText} ${index + 1}`,
        id: counter._id,
        floor: floor,
        entrance: entrance,
        hwId: counter.hw_id,
        type: type,
        premiseName: counter.premise_name || '',
        inputNumber: counter.input_no,
        model: counter.model,
        coefficient: counter.koefficient,
        line: counter.line_no || '',
        readings: counter.readings || 0,
        consumptions: counter.consumptions || 0,
        consumpted: counter.consumpted || 0,
        imbalance: counter.imbalance || 0,
        status: counter.status,
        errors: counter.devErrors,
        children: counter.consumerMeters ? mapSwitchgearConsumersToView(counter.consumerMeters) : []
    }
}

function mapSwitchgearConsumersToView(consumers) {
    return consumers.map((consumer) => {
        return {
            id: consumer._id,
            buildingId: consumer.building_id,
            floor: consumer.floor,
            entrance: consumer.entrance,
            hwId: consumer.hw_id,
            premiseId: consumer.premise_id,
            premiseName: consumer.premise_name,
            inputNumber: consumer.input_no,
            model: consumer.model,
            line: consumer.line_no,
            parentType: consumer.parent_type,
            parentSwId: consumer.dc_sw_id,
            readings: consumer.readings || 0,
            consumptions: consumer.consumptions || 0,
            consumpted: consumer.consumpted || 0,
            imbalance: consumer.imbalance || 0,
            status: consumer.status,
            errors: consumer.devErrors
        }
    })
}

function mapSwitchgearsToSelect(switchgears) {
    return switchgears.map((switchgear) => {
        return {
            id: switchgear._id,
            name: switchgear.name
        }
    })
}

function getLinesFromSwitchgears(switchgears) {
    const lines = [];

    switchgears.forEach((switchgear) => {
        switchgear.outputMeters.forEach((outputCounter) => {
            const line = outputCounter.line_no;

            lines.push({
                id: line,
                switchgearId : switchgear._id,
                name: line.toString()
            });
        })
    });

    return lines;
}


function mapSwitchgearToSend(switchgear, isUpdate) {
    return {
        name: switchgear.name,
        floor: switchgear.floor,
        entrance: switchgear.entrance,
        premise_id: switchgear.premiseId,
        building_id: switchgear.buildingId,
        parent_type: 1,
        parent_sw_id: switchgear.parentSwId,
        input_meters: mapSwitchgearCountersToSend(switchgear.inputCounters, isUpdate),
        output_meters: mapSwitchgearCountersToSend(switchgear.outputCounters, isUpdate),
    }
}

function mapSwitchgearCountersToSend(counters, isUpdate) {
    return counters.map((counter) => {
        const counterToSend = {
            hw_id: counter.hwId,
            input_no: counter.inputNumber,
            koefficient: Number(counter.coefficient)
        }

        if (isUpdate) {
            counterToSend._id = counter.id;
        }

        if (counter.type === 'output') {
            counterToSend.line_no = Number(counter.line);
            counterToSend.name = counter.name;
        }

        return counterToSend;
    })
}

function mapConsumerToSend(consumer) {
    return {
        building_id: consumer.buildingId,
        premise_id: consumer.premiseId,
        line_no: consumer.lineId,
        hw_id: consumer.hwId,
        parent_type: 2,
        parent_sw_id: consumer.parentSwId
    }
}

export {
    mapSwitchgearsToView,
    mapSwitchgearsToSelect,
    getLinesFromSwitchgears,
    mapSwitchgearToSend,
    mapConsumerToSend
}