import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const viewPeriods = [
    {
        id: 'online',
        name: t('period.view.online'),
    },
    {
        id: 'currentMonth',
        name: t('period.view.month.current'),
    },
    {
        id: 'previousMonth',
        name: t('period.view.month.previous')
    }
]

export {
    viewPeriods
}