<template>
    <div class="system-statistics">
        <div class="system-statistics__panel">
            <div class="system-statistics__title">
                <h4>{{ title }}</h4>
            </div>
            <div class="system-statistics__control">
                <app-base-select
                    :selected-option="selectedMonth"
                    :options="months"
                    :type="'text'"
                    @select="selectMonth"
                />
            </div>
        </div>
        <div class="system-statistics__table">
            <div class="system-statistics__building" v-for="building in buildings" :key="building.id">
                <building-statistics-row
                    :id="building.id"
                    :type="type"
                    :group="building.group"
                    :building="building.building"
                    :value="building.parameters[monthId].value"
                    :difference="building.parameters[monthId].difference"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import BuildingStatisticsRow from "./BuildingStatisticsRow";
    import { dashboardStatisticMonths } from "../../../constants/dashboard";

    export default {
        name: "SystemStatisticsRow",

        components: {
            BuildingStatisticsRow
        },

        props: {
            id: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true
            },
            buildings: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                selectedMonth: null
            }
        },

        created() {
            this.selectedMonth = this.months[0];
        },

        computed: {
            title() {
                return this.$t(`${this.type}.title`);
            },

            months() {
                return dashboardStatisticMonths;
            },

            monthId() {
                return this.selectedMonth.id;
            }
        },

        methods: {
            selectMonth(month) {
                this.selectedMonth = month;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .system-statistics {
        padding: 15px 0 0 15px;
        background: var(--app-table-background);
        border-radius: 15px;

        &__panel {
            display: flex;
            justify-content: space-between;
            padding-right: 15px;

            h4 {
                font-weight: 400;
            }
        }

        &__control {
            width: 120px;
        }

        &__table {
            max-height: calc(100vh - 720px);
            overflow-y: auto;
            scrollbar-width: thin;
            padding-right: 15px;
        }

        &__building {
            padding: 15px 0;
            background: var(--app-table-background);
            border-top: 1px solid var(--app-border);
        }
    }
</style>

<style lang="scss">
    .system-statistics {
        .multiselect__tags {
            min-height: 24px !important;
            border-radius: 7px !important;
            padding-top: 4px !important;
        }

        .multiselect__input,
        .multiselect__single,
        .multiselect__element,
        .multiselect__option {
            font-size: 12px !important;
            line-height: 14px !important;
        }

        .multiselect__single {
            margin-bottom: unset !important;
        }

        .multiselect__select {
            height: 24px !important;
            padding: 10px 4px 4px 4px !important;
            width: 24px;
        }

        .multiselect__option {
            min-height: 24px !important;
            padding: 8px !important;
        }
    }
</style>