function validateNumber(value) {
    return value && Number(value);
}

function validatePositiveNumber(value) {
    return value && Number(value) && Number(value) > 0;
}

function validateHex(serialNumber) {
    return /\b[0-9A-F]{16}\b/gi.test(serialNumber);
}

function validateNumberIsInteger(value) {
    return value && Number(value) && Number.isInteger(Number(value));
}

function validateRangeNumber(value, min, max) {
    return !isNaN(value) && value >= min && value <= max;
}

export {
    validateNumber,
    validatePositiveNumber,
    validateHex,
    validateNumberIsInteger,
    validateRangeNumber
}