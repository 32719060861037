import { validateNumber, validateNumberIsInteger } from "./common";
import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const switchgearErrors = {
    name: t('validation.required'),
    controller: t('validation.required')
}

const switchgearCounterErrors = {
    name: t('validation.required'),
    hwId: t('validation.required'),
    coefficient: t('validation.number'),
    inputNumber: t('validation.required'),
    line: t('validation.integer'),
    isExistLine: t('validation.isExistLine'),
}

const consumerErrors = {
    premise: t('validation.required'),
    hwId: t('validation.required'),
    line: t('validation.required'),
    controller: t('validation.required')
}

function validateSwitchgear(switchgear, controller) {
    let isValid = false;

    const errors = {
        name: '',
        controller: ''
    }

    const isValidName = !!switchgear.name;

    if (!isValidName) {
        errors.name = switchgearErrors.name;
    }

    const isValidController = !!controller;

    if (!isValidController) {
        errors.controller = switchgearErrors.controller;
    }

    isValid = isValidName && isValidController;

    return { isValid, errors };
}

function validateSwitchgearCounter(counter, isOutputCounter, input, lines) {
    let isValid = false;

    const errors = {
        name: '',
        hwId: '',
        coefficient: '',
        inputNumber: '',
        line: ''
    }

    let isValidName = !isOutputCounter || !!counter.name;

    if (!isValidName) {
        errors.name = switchgearCounterErrors.name;
    }

    const isValidHwId = !!counter.hwId;

    if (!isValidHwId) {
        errors.hwId = switchgearCounterErrors.hwId;
    }

    const isValidCoefficient = !!validateNumber(counter.coefficient);

    if (!isValidCoefficient) {
        errors.coefficient = switchgearCounterErrors.coefficient;
    }

    const isValidInputNumber = !!input;

    if (!isValidInputNumber) {
        errors.inputNumber = switchgearCounterErrors.inputNumber;
    }

    let isValidLine = !isOutputCounter || !!validateNumberIsInteger(counter.line);

    if (!isValidLine) {
        errors.line = switchgearCounterErrors.line;
    }

    let isValidUniqueLine = true;

    if (isOutputCounter && isValidLine) {
        const line = lines.find((line) => line.id === Number(counter.line));

        if (line) {
            isValidUniqueLine = false;
            errors.line = switchgearCounterErrors.isExistLine;
        }
    }

    isValid = isValidName && isValidHwId && isValidCoefficient && isValidInputNumber && isValidLine && isValidUniqueLine;

    return { isValid, errors };
}

function validateConsumer(consumer, premise, line, controller) {
    let isValid = false;

    const errors = {
        premise: '',
        hwId: '',
        line: '',
        controller: ''
    }

    const isValidHwId = !!consumer.hwId;

    if (!isValidHwId) {
        errors.hwId = consumerErrors.hwId;
    }

    const isValidPremise = !!premise;

    if (!isValidPremise) {
        errors.premise = consumerErrors.premise;
    }

    const isValidLine = !!line;

    if (!isValidLine) {
        errors.line = consumerErrors.line;
    }

    const isValidController = !!controller;

    if (!isValidController) {
        errors.controller = consumerErrors.controller;
    }

    isValid = isValidHwId && isValidPremise && isValidLine && isValidController;

    return { isValid, errors };
}

export {
    validateSwitchgear,
    validateSwitchgearCounter,
    validateConsumer
}