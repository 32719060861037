<template>
    <div class="water">
        <div class="water__panel">
            <div class="water__title">
                {{ $t('water.title') }}
            </div>
            <div class="water__actions">
                <app-add-btn
                    @open-modal="openModalWaterPad"
                />
            </div>
        </div>
        <div class="water__control">
            <water-filters
                :qty="qty"
                @update-query-params="updateQueryParams"
                @update-query-id="updateQueryId"
                @update-query-type="updateQueryType"
            />
        </div>
        <div class="water__content" ref="water">
            <div v-if="showTable" class="water__table">
                <water-table
                    :pads="pads"
                    :is-online="isOnline"
                    :max-height="maxHeightScroll"
                />
            </div>
            <div v-if="showEmpty" class="water__empty">
                <app-empty-content
                    :content-type="'water'"
                    @open-modal="openModalWaterPad"
                />
            </div>
            <div v-if="showNoResults" class="water__empty">
                <app-no-results
                    @clear="clearQueryParams"
                />
            </div>
            <div v-if="isLoaded" class="water__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { computed } from "vue";
    import { greenHubAPI } from "../../config/api";
    import { maxHeightMixin } from "../../mixins/calcMaxHeight";
    import { waterToastsMixin } from "../../mixins/toasts/water";
    import { mapWaterSystemToView } from "../../api/mappers/water";
    import WaterFilters from "./WaterFilters";
    import WaterTable from "./WaterTable";

    export default {
        name: "WaterControl",

        components: {
            WaterFilters,
            WaterTable
        },

        mixins: [
            maxHeightMixin,
            waterToastsMixin
        ],

        data() {
            return {
                isLoaded: false,
                timerId: '',
                timeout: 5000,
                pads: [],
                qty: 0,

                query: {
                    type: 'online',
                    id: '',
                    params: null
                }
            }
        },

        provide() {
            return {
                subString: computed(() => this.query?.params?.match || '')
            }
        },

        created() {
            this.setMonitoringInterval(5000);
        },

        mounted() {
            this.subscribeToResize('water');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
            clearInterval(this.timerId);
        },

        computed: {
            isPadsExist() {
                return !!this.pads.length;
            },

            showTable() {
                return this.isPadsExist && !this.isLoaded;
            },

            showEmpty() {
                return !this.isPadsExist && !this.isLoaded && !this.isExistQueryParams;
            },

            showNoResults() {
                return !this.isPadsExist && !this.isLoaded && this.isExistQueryParams;
            },

            isExistQueryParams() {
                return this.query.params;
            },

            isOnline() {
                return this.query.type === 'online';
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateWaterPads', this.updateWaterPads);
                this.emitter.on('deleteWaterPad', this.deleteWaterPad);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateWaterPads', this.updateWaterPads);
                this.emitter.off('deleteWaterPad', this.deleteWaterPad);
            },

            setMonitoringInterval(timeout) {
                clearInterval(this.timerId);

                this.timerId = setInterval(() => {
                    this.getWaterPads();
                }, timeout);
            },

            getWaterPads() {
                const id = this.query.id;

                const params = {
                    params: this.query.params
                };

                const url = this.isOnline ? 'water_supply' : 'water_supply/period';
                const timeout = this.isOnline ? 5000 : 30000;
                this.setMonitoringInterval(timeout);

                greenHubAPI.get(`${url}/${id}`, params)
                    .then((result) => {
                        const { qty, pads } = mapWaterSystemToView(result.data);

                        this.qty = qty;
                        this.pads = pads;

                        this.emitter.emit('updateShowQty');
                        this.$nextTick(() => {
                            this.calcMaxHeight();
                        });
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            deleteWaterPad({ id, name }) {
                greenHubAPI.delete(`/water_supply/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.closeModalWaterPad();
                        this.showDeleteWaterPadToast(name);
                        this.updateWaterPads();
                    })
            },

            //<----------------------------.......................>
            //TODO: ONE UPDATE FOR QUERY
            updateQueryParams(params) {
                this.query.params = params;
                this.updateWaterPads();
            },

            updateQueryId(id) {
                this.query.id = id;
                this.clearQueryParams();
                this.updateWaterPads();
            },

            updateQueryType(type) {
                this.query.type = type;
                // this.updateWaterPads();
            },

            updateWaterPads() {
                this.isLoaded = true;
                this.getWaterPads();
            },

            clearQueryParams() {
                this.emitter.emit('clearQueryParams');
            },

            //<----------------------------.......................>

            openModalWaterPad() {
                const selectedElement = {
                    buildingId: this.query.id
                };

                this.openModal({
                    name: 'modalWaterPad',
                    selectedEl: selectedElement
                })
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            },

            closeModalWaterPad() {
                this.closeModal('modalWaterPad');
            },
        }
    }
</script>

<style lang="scss">
    .water-pad {
        &__name {
            width: 14%;
            display: flex;
            align-items: center;

            button {
                margin-right: 30px;
            }

            &--is-enter {
                font-weight: 600;
            }
        }

        &__entrance {
            width: 6%;
        }

        &__floor {
            width: 6%;
        }

        &__premise {
            width: 12%;
        }

        &__counterId {
            width: 11%;
        }

        &__hwId {
            width: 11%;
        }

        &__model {
            width: 12%;
        }

        &__data {
            width: 20%;
            padding-right: unset !important;
        }

        &__data-view {
            display: flex;
            align-items: center;
        }

        &__readings {
            width: 50%;
            display: flex;
            align-items: center;

            button {
                margin-left: 10px;
            }
        }

        &__readings-wrapper {
            min-width: 80px;
        }

        &__consumptions {
            width: 50%;
        }

        &__consumpted {
            width: 50%;
        }

        &__imbalance {
            width: 50%;
        }

        &__status {
            width: 8%;
        }
    }
</style>