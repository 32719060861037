import { greenHubAPI } from "../../config/api";

function getImageFromUrl(imageUrl) {
    const parts = imageUrl.split('/');
    const url = parts[parts.length - 1];

    return greenHubAPI.get(`${url}`, {
        responseType: 'blob'
    })
        .then((result) => {
            if (result.data) {
                const blob = new Blob([result.data], {type: `${result.data.type}`});

                return window.URL.createObjectURL(blob);
            }
        })
}

function getAndDownloadFile(link, name) {
    const parts = link.split('/');
    const url = parts[parts.length - 1];
    const part = link.split('.');
    const extension = part[part.length - 1];

    greenHubAPI.get(`${url}`, {
        responseType: 'blob'
    })
        .then((result) => {
            if (result.data) {
                downloadFile(result.data, extension, name);
            }
        })
}

function downloadFile(data, extension, name) {
    const blob = new Blob([data], {type: `${data.type}`});

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${name}.${extension}`;
    document.body.appendChild(link);
    link.click();

    link.remove();
}

export {
    getImageFromUrl,
    getAndDownloadFile
}