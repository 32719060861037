import i18n from "../../../src/localization/i18n"
const { t } = i18n.global;
import sortBy from "lodash.sortby";

function mapEquipmentsToView(equipments) {
    return mapEquipmentsBCToView(equipments.buildingControllers);
}

function mapEquipmentsBCToView(bcs) {
    return bcs.map((bc) => {
        return {
            id: bc._id,
            name: bc.name,
            floor: bc.floor,
            entrance: bc.entrance,
            buildingId: bc.building_id,
            buildingName: bc.building_name,
            serialNumber: bc.hw_id,
            status: bc.status,
            errors: bc.devErrors || [],
            children: mapEquipmentsDCToView(bc.dispatchingControllers)
        }
    })
}

function mapEquipmentsDCToView(dcs) {
    return dcs.map((dc) => {
        return {
            bcId: dc.bc_sw_id,
            id: dc._id,
            name: dc.name,
            floor: dc.floor,
            entrance: dc.entrance,
            buildingId: dc.building_id,
            buildingName: dc.building_name,
            serialNumber: dc.hw_id,
            status: dc.status,
            isInstalledSM: dc.security_module,
            children: mapEquipmentsSMToView(dc.securityModules)
        }
    })
}

function mapEquipmentsSMToView(sms) {
    return sms.map((sm) => {
        return {
            dcID: sm.dc_sw_id,
            bcId: sm.bc_sw_id,
            id: sm._id,
            name: t('equipments.equipment.sm.name'),
            floor: sm.floor,
            entrance: sm.entrance,
            buildingId: sm.building_id,
            buildingName: sm.building_name,
            serialNumber: sm.hw_id,
            status: sm.status
        }
    })
}

function mapEquipmentsBCToSelect(bcs) {
    const bcsToSelect = bcs.map((bc) => {
        return {
            id: bc._id,
            name: bc.name
        }
    });

    return sortBy(bcsToSelect, (bc) => bc.name);
}

function mapEquipmentsDCToSelect(bcs) {
    const dcsToSelect = bcs.reduce((dcs, bc) => {
        return bc.dispatchingControllers.reduce((acc, dc) => {
            acc.push({
                id: dc._id,
                name: dc.name
            });

            return acc;
        }, dcs)
    }, []);

    return sortBy(dcsToSelect, (dc) => dc.name);
}

function mapEquipmentBCToSend(bc) {
    return {
        building_id: bc.buildingId,
        name: bc.name,
        hw_id: bc.serialNumber,
        floor: bc.floor,
        entrance: bc.entrance
    }
}

function mapEquipmentDCToSend(dc) {
    return {
        bc_sw_id: dc.bcId,
        name: dc.name,
        hw_id: dc.serialNumber,
        floor: dc.floor,
        entrance: dc.entrance,
        security_module: dc.isInstalledSM
    }
}

export {
    mapEquipmentsToView,
    mapEquipmentBCToSend,
    mapEquipmentDCToSend,
    mapEquipmentsBCToSelect,
    mapEquipmentsDCToSelect
}