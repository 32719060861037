<template>
    <app-modal-wrapper>
        <form @submit.prevent="doActionElectricityConsumer" class="modal-electricity-consumer">
            <div class="modal-electricity-consumer__content">
                <div class="electricity-consumer-form">
                    <h4>{{ title }}</h4>
                    <div class="electricity-consumer-form__info">
                        <div class="electricity-consumer-form__row">
                            <div class="electricity-consumer-form__details">
                                <app-base-select
                                    :is-disabled="isUpdate"
                                    :label="$t('electricity.switchgear.building.label')"
                                    :place-holder="$t('electricity.switchgear.building.ph')"
                                    :selected-option="selectedBuilding"
                                    :options="buildings"
                                    :type="'text'"
                                    @select="selectBuilding"
                                />
                            </div>
                            <div class="electricity-consumer-form__details">
                                <app-base-select ref="premise"
                                    :label="$t('electricity.switchgear.premise.label')"
                                    :place-holder="$t('electricity.switchgear.premise.ph')"
                                    :selected-option="selectedPremise"
                                    :options="premises"
                                    :type="'text'"
                                    :error-message="errors.premise"
                                    @select="selectPremise"
                                />
                            </div>
                        </div>
                        <div class="electricity-consumer-form__row">
                            <div class="electricity-consumer-form__details">
                                <app-base-input
                                    v-model="consumer.hwId"
                                    :label="$t('electricity.consumer.hwId.label')"
                                    :place-holder="$t('electricity.consumer.hwId.ph')"
                                    :error-message="errors.hwId"
                                />
                            </div>
                            <div class="electricity-consumer-form__details">
                                <app-base-select ref="line"
                                    :label="$t('electricity.consumer.line.label')"
                                    :place-holder="$t('electricity.consumer.line.ph')"
                                    :selected-option="selectedLine"
                                    :options="lines"
                                    :type="'text'"
                                    :error-message="errors.line"
                                    @select="selectLine"
                                />
                            </div>
                        </div>
                        <div class="electricity-consumer-form__row">
                            <div class="electricity-consumer-form__details">
                                <app-base-select ref="controller"
                                    :open-direction="'top'"
                                    :label="$t('electricity.consumer.controller.label')"
                                    :place-holder="$t('electricity.consumer.controller.ph')"
                                    :selected-option="selectedController"
                                    :options="dcs"
                                    :type="'text'"
                                    :error-message="errors.controller"
                                    @select="selectController"
                                />
                            </div>
                            <div class="electricity-consumer-form__details"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-electricity-consumer__control">
                <app-base-btn v-if="isUpdate"
                    :variation="'delete'"
                    :text="$t('common.buttons.delete')"
                    @action="openModalConfirm"
                />
                <app-base-btn
                    :variation="'cancel'"
                    :text="$t('common.buttons.cancel')"
                    @action="closeModalElectricityConsumer"
                />
                <app-base-btn
                    :type="'submit'"
                    :variation="'save'"
                    :text="$t('common.buttons.save')"
                />
            </div>
        </form>
    </app-modal-wrapper>
</template>

<script>
    import { mapGetters } from "vuex";
    import { greenHubAPI } from "../../../config/api";
    import { electricityToastsMixin } from "../../../mixins/toasts/electricity";
    import { prepareQueryFilterParams } from "../../../helpers/api";
    import { mapPremisesToSelect } from "../../../api/mappers/premises";
    import { mapEquipmentsDCToSelect } from "../../../api/mappers/equipments";
    import {getLinesFromSwitchgears, mapConsumerToSend } from "../../../api/mappers/electricity";
    import { clearDependentSelects } from "../../../helpers/selects";
    import { validateConsumer } from "../../../validations/electricity";

    export default {
        name: "ModalElectricityConsumer",

        props: {
            selectedConsumer: {
                type: Object
            }
        },

        mixins: [
            electricityToastsMixin
        ],

        data() {
            return {
                selectedBuilding: null,
                selectedPremise: null,
                selectedLine: null,
                selectedController: null,

                lines: [],
                dcs: [],
                premises: [],

                consumer: {
                    hwId: ''
                },

                errors: {
                    premise: '',
                    hwId: '',
                    line: '',
                    controller: ''
                }
            }
        },

        created() {
            this.checkUpdateConsumer();
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings'
            }),

            isUpdate() {
                return !!this.selectedConsumer?.id;
            },

            title() {
                return !this.isUpdate
                    ? this.$t('electricity.consumer.title.create')
                    : this.$t('electricity.consumer.title.edit');
            }
        },

        methods: {
            checkUpdateConsumer() {
                if (this.isUpdate) {
                    this.consumer = { ... this.selectedConsumer };
                }

                this.selectedBuilding = this.buildings.find((building) => building.id === this.selectedConsumer.buildingId);
                this.getAdditionalData();
            },

            getAdditionalData() {
                this.getPremises();
                this.getControllers();
                this.getLines();
            },

            getPremises() {
                const queryFilterParams = {
                    building_id: this.selectedBuilding.id
                };

                const params = prepareQueryFilterParams(queryFilterParams);

                greenHubAPI.get('/premises', params)
                    .then((result) => {
                        const { qty, premises } = result.data;
                        this.premises = mapPremisesToSelect(premises);

                        if (this.isUpdate) {
                            this.selectedPremise = this.premises.find((premise) => premise.id === this.selectedConsumer.premiseId);
                        }
                    })
            },

            getControllers() {
                const buildingId = this.selectedBuilding.id;

                greenHubAPI.get(`/equipments/${buildingId}`)
                    .then((result) => {
                        const controllers = result.data.buildingControllers;

                        this.dcs = mapEquipmentsDCToSelect(controllers);

                        if (this.isUpdate) {
                            this.selectedController = this.dcs.find((controller) => controller.id === this.selectedConsumer.parentSwId);
                        }
                    })
            },

            getLines() {
                const id = this.selectedBuilding.id;

                greenHubAPI.get(`electricity/${id}`)
                    .then((result) => {
                        const switchgears = result.data;
                        this.lines = getLinesFromSwitchgears(switchgears);

                        if (this.isUpdate) {
                            this.selectedLine = this.lines.find((line) => line.id === this.selectedConsumer.line);
                        }
                    })
            },

            doActionElectricityConsumer() {
                this.clearConsumerErrors();
                const { isValid, errors } = validateConsumer(
                    this.consumer,
                    this.selectedPremise,
                    this.selectedLine,
                    this.selectedController
                );
                this.errors = errors;

                if (isValid) {
                    if (!this.isUpdate) {
                        this.createElectricityConsumer();
                    } else {
                        this.changeElectricityConsumer();
                    }
                }
            },

            createElectricityConsumer() {
                this.prepareDataConsumerFromSelects();

                const consumerToSend = mapConsumerToSend(this.consumer);

                greenHubAPI.post('/electricity/consumer', consumerToSend)
                    .then(() => {
                        this.updateElectricitySwitchgears();
                        this.showCreateConsumerToast();
                    })
            },

            changeElectricityConsumer() {
                this.prepareDataConsumerFromSelects();
                const id = this.consumer.id;

                const consumerToSend = mapConsumerToSend(this.consumer);

                greenHubAPI.patch(`/electricity/consumer/${id}`, consumerToSend)
                    .then(() => {
                        this.updateElectricitySwitchgears();
                    })
            },

            prepareDataConsumerFromSelects() {
                this.consumer.buildingId = this.selectedBuilding.id;
                this.consumer.premiseId = this.selectedPremise.id;
                this.consumer.lineId = this.selectedLine.id;
                this.consumer.parentSwId = this.selectedController.id;
            },

            updateElectricitySwitchgears() {
                this.closeModalElectricityConsumer();
                this.emitter.emit('updateElectricitySwitchgears');
            },

            selectBuilding(building) {
                this.selectedBuilding = building;

                this.clearDependentFields(this.$refs);
                this.getAdditionalData();
            },

            selectPremise(premise) {
                this.selectedPremise = premise;
            },

            selectLine(line) {
                this.selectedLine = line;
            },

            selectController(controller) {
                this.selectedController = controller;
            },

            clearDependentFields(selects) {
                clearDependentSelects(selects);
                const refKeys = Object.keys(selects);

                this.$nextTick(() => {
                    refKeys.forEach((key) => selects[key].initSelect());
                })
            },

            clearConsumerErrors() {
                for (let key in this.errors) {
                    this.errors[key] = '';
                }
            },

            closeModalElectricityConsumer() {
                this.closeModal('modalElectricityConsumer');
            },

            openModalConfirm() {
                const selectedElement = {
                    id: this.consumer.id,
                    name: this.consumer.hwId,
                    type: 'consumer'
                };

                this.openModal({
                    name: 'modalConfirm',
                    selectedEl: selectedElement
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-electricity-consumer {
        background: var(--system-white);
        padding: 30px;
        display: flex;
        flex-direction: column;
        max-width: 1118px;
        width: 100%;
        max-height: 80vh;
        overflow-y: auto;

        &__control {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;

            button:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    .electricity-consumer-form {
        &__info {
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            &:not(:last-child) {
                padding-bottom: 30px;
                border-bottom: 1px solid var(--app-border);
            }
        }

        &__row {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__details {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }
    }
</style>