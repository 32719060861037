import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const securityObjectErrors = {
    name: t('validation.required'),
    module: t('validation.required'),
    channel: t('validation.required')
}

function validateSecurityObject(securityObject, module, channel) {
    let isValid = false;

    const errors = {
        name: '',
        module: '',
        channel: ''
    }

    const isValidName = !!securityObject.name;

    if (!isValidName) {
        errors.name = securityObjectErrors.name;
    }

    const isValidModule = !!module;

    if (!isValidModule) {
        errors.module = securityObjectErrors.module;
    }

    const isValidChannel = !!channel;

    if (!isValidChannel) {
        errors.channel = securityObjectErrors.channel;
    }

    isValid = isValidName && isValidModule && isValidChannel;

    return { isValid, errors };
}

export {
    validateSecurityObject
}