import i18n from "../localization/i18n"
const { t } = i18n.global;

const menuIconParams = {
    logo: {
        name: '',
        file: ''
    },
    // dashboard: {
    //     route: '/dashboard',
    //     name: t('menu.dashboard'),
    //     file: ''
    // },
    buildings: {
        route: '/buildings',
        name: t('menu.buildings'),
        file: ''
    },
    premises: {
        route: '/premises',
        name: t('menu.premises'),
        file: ''
    },
    equipments: {
        route: '/equipments',
        name: t('menu.equipments'),
    },
    electricity: {
        route: '/electricity',
        name: t('menu.electricity'),
    },
    balancer: {
        route: '/balancer/system',
        name: t('menu.balancer'),
    },
    water: {
        route: '/water',
        name: t('menu.water'),
    },
    security: {
        route: '/security',
        name: t('menu.security'),
    },
    elevators: {
        route: '/elevators',
        name: t('menu.elevators')
    },
    // logs: {
    //     route: '/logs',
    //     name: t('menu.logs')
    // },
    // personalization: {
    //     route: '/personalization',
    //     name: t('menu.personalization')
    // }
}

const balancerMenuItems = [
    {
        id: '1',
        name: t('submenu.system'),
        route: '/balancer/system'
    },
    {
        id: '2',
        name: t('submenu.monitoring'),
        route: '/balancer/monitoring'
    }
]


export {
    menuIconParams,
    balancerMenuItems
}