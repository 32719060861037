import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const types = {
    logout: {
        text: t('confirm.logout.text'),
        btn: t('confirm.logout.btn')
    },
    leave: {
        text: t('confirm.leave.text'),
        btn: t('confirm.leave.btn'),
        cancelBtn: t('confirm.leave.cancelBtn'),
        event: 'leaveRoute'
    },
    settings: {
        text: t('confirm.settings.text'),
        btn: t('confirm.settings.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteSettings'
    },
    avatar: {
        text: t('confirm.avatar.text'),
        btn: t('confirm.avatar.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteAvatar'
    },
    building: {
        text1: t('confirm.building.text1'),
        text2: t('confirm.building.text2'),
        btn: t('confirm.building.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteBuilding'
    },
    premise: {
        text1: t('confirm.premise.text1'),
        text2: t('confirm.premise.text2'),
        btn: t('confirm.premise.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deletePremise'
    },
    equipmentBC: {
        text1: t('confirm.equipmentBC.text1'),
        text2: t('confirm.equipmentBC.text2'),
        btn: t('confirm.equipmentBC.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteEquipmentBC'
    },
    equipmentDC: {
        text1: t('confirm.equipmentDC.text1'),
        text2: t('confirm.equipmentDC.text2'),
        btn: t('confirm.equipmentDC.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteEquipmentDC'
    },
    water: {
        text1: t('confirm.water.text1'),
        text2: t('confirm.water.text2'),
        btn: t('confirm.water.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteWaterPad'
    },
    elevator: {
        text1: t('confirm.elevator.text1'),
        text2: t('confirm.elevator.text2'),
        btn: t('confirm.elevator.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteElevator'
    },
    switchgear: {
        text1: t('confirm.switchgear.text1'),
        text2: t('confirm.switchgear.text2'),
        btn: t('confirm.switchgear.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteSwitchgear'
    },
    consumer: {
        text1: t('confirm.consumer.text1'),
        text2: t('confirm.consumer.text2'),
        btn: t('confirm.consumer.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteConsumer'
    },
    boiler: {
        text1: t('confirm.boiler.text1'),
        text2: t('confirm.boiler.text2'),
        btn: t('confirm.boiler.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteBalancerEquipment'
    },
    station: {
        text1: t('confirm.station.text1'),
        text2: t('confirm.station.text2'),
        btn: t('confirm.station.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteBalancerStation'
    },
    security: {
        text1: t('confirm.security.text1'),
        text2: t('confirm.security.text2'),
        btn: t('confirm.security.btn'),
        cancelBtn: t('common.buttons.cancel'),
        event: 'deleteSecurityObject'
    }
}

export {
    types
}