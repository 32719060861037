<template>
    <div class="balancer-system">
        <div class="balancer-system__filters">
            <balancer-system-filters
                :qty="qtyResults"
                @update-query-params="updateQueryParams"
            />
        </div>
        <div class="balancer-system__content" ref="balancerSystem">
            <div v-if="showTable" class="balancer-system__table">
                <balancer-system-table
                    :equipments="equipments"
                    :max-height="maxHeightScroll"
                />
            </div>
            <div v-if="showEmpty" class="balancer-system__empty">
                <app-empty-content
                    :content-type="'balancerEquipment'"
                    @open-modal="openModalBalancerEquipment"
                />
            </div>
            <div v-if="showNoResults" class="balancer-system__empty">
                <app-no-results
                    @clear="clearQueryParams"
                />
            </div>
            <div v-if="isLoaded" class="balancer-system__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { greenHubAPI } from "../../../config/api";
    import { computed } from "vue";
    import { maxHeightMixin } from "../../../mixins/calcMaxHeight";
    import { balancerToastsMixin } from "../../../mixins/toasts/balancer";
    import { mapBalancerEquipmentsToView } from "../../../api/mappers/balancer";
    import BalancerSystemFilters from "./BalancerSystemFilters";
    import BalancerSystemTable from "./BalancerSystemTable";

    export default {
        name: "BalancerSystemControl",

        components: {
            BalancerSystemTable,
            BalancerSystemFilters
        },

        mixins: [
            maxHeightMixin,
            balancerToastsMixin
        ],

        data() {
            return {
                isLoaded: true,
                timerId: '',
                equipments: [],

                query: {
                    params: null
                }
            }
        },

        provide() {
            return {
                subString: computed(() => this.query?.params?.match || '')
            }
        },

        created() {
            this.updateBalancerEquipments();

            this.timerId = setInterval(() => {
                this.getBalancerEquipments();
            }, 5000);
        },

        mounted() {
            this.subscribeToResize('balancerSystem');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
            clearInterval(this.timerId);
        },

        computed: {
            qtyResults() {
                return this.equipments.length;
            },

            isEquipmentsExist() {
                return !!this.equipments.length;
            },

            showTable() {
                return this.isEquipmentsExist && !this.isLoaded;
            },

            showEmpty() {
                return !this.isEquipmentsExist && !this.isLoaded && !this.isExistQueryParams;
            },

            showNoResults() {
                return !this.isEquipmentsExist && !this.isLoaded && this.isExistQueryParams;
            },

            isExistQueryParams() {
                return this.query.params;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateBalancerEquipments', this.updateBalancerEquipments);
                this.emitter.on('deleteBalancerEquipment', this.deleteBalancerEquipment);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateBalancerEquipments', this.updateBalancerEquipments);
                this.emitter.off('deleteBalancerEquipment', this.deleteBalancerEquipment);
            },

            getBalancerEquipments() {
                const params = {
                    params: this.query.params
                };

                greenHubAPI.get('balancer/equipment', params)
                    .then((result) => {
                        this.equipments = mapBalancerEquipmentsToView(result.data);

                        this.emitter.emit('updateShowQty');
                        this.$nextTick(() => {
                            this.calcMaxHeight();
                        });
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            deleteBalancerEquipment({ id, name }) {
                greenHubAPI.delete(`/balancer/equipment/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.closeModalBalancerEquipment();
                        this.showDeleteBalancerEquipmentToast(name);
                        this.updateBalancerEquipments();
                    })
            },

            updateBalancerEquipments() {
                this.isLoaded = true;
                this.getBalancerEquipments();
            },

            updateQueryParams(params) {
                this.query.params = params;
                this.updateBalancerEquipments();
            },

            clearQueryParams() {
                this.emitter.emit('clearQueryParams');
            },

            openModalBalancerEquipment() {
                this.openModal({
                    name: 'modalBalancerEquipment'
                })
            },

            closeModalBalancerEquipment() {
                this.closeModal('modalBalancerEquipment');
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .balancer-system {
        display: flex;
        flex-direction: column;
        height: 100%;

        &__content {
            flex-grow: 1;
        }

        &__filters {
            display: flex;
            justify-content: space-between;
            padding: 15px 30px 15px 30px;
        }
    }
</style>