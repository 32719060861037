import i18n from "../../src/localization/i18n"
import { createFloorsList } from "../helpers/buildings";
const { t } = i18n.global;

const elevatorFloors = createFloorsList(1, 4, false);

const elevatorChannels = [
    {
        id: 'inputs',
        title: t('elevators.elevator.ports.inputs.title'),
        ports: [
            {
                id: 'rbf',
                name: t('elevators.elevator.ports.inputs.rbf')
            },
            {
                id: 'rea',
                name: t('elevators.elevator.ports.inputs.rea')
            },
            {
                id: 'rib',
                name: t('elevators.elevator.ports.inputs.rib')
            },
            {
                id: 'rsm',
                name: t('elevators.elevator.ports.inputs.rsm')
            },
            {
                id: 'rab',
                name: t('elevators.elevator.ports.inputs.rab')
            },
            {
                id: 'refe',
                name: t('elevators.elevator.ports.inputs.refe')
            }
        ]
    },
    {
        id: 'outputs',
        title: t('elevators.elevator.ports.outputs.title'),
        ports: [
            {
                id: 'floor1',
                name: t('elevators.elevator.ports.outputs.floor1')
            },
            {
                id: 'floor2',
                name: t('elevators.elevator.ports.outputs.floor2')
            },
            {
                id: 'floor3',
                name: t('elevators.elevator.ports.outputs.floor3')
            },
            {
                id: 'floor4',
                name: t('elevators.elevator.ports.outputs.floor4')
            },
            {
                id: 'restart',
                name: t('elevators.elevator.ports.outputs.restart')
            },
            {
                id: 'ctl',
                name: t('elevators.elevator.ports.outputs.ctl')
            }
        ]
    }
]

function generatePinsForChannelType(maxPin) {
    const pins = [];

    for (let i = 0; i <= maxPin; i++) {
        pins.push({
            id: i,
            name: i.toString()
        })
    }

    return pins;
}

export {
    elevatorFloors,
    elevatorChannels,
    generatePinsForChannelType
}