import { showToast } from "../../helpers/toasts";

const equipmentsToastsMixin = {
    methods: {
        showDeleteEquipmentsBCToast(name) {
            const deleteToastTitle = this.$t('toasts.equipmentsBC.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.equipmentsBC.delete.details1`)}
                    ${name}
                    ${this.$t(`toasts.equipmentsBC.delete.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showCreateEquipmentsBCToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.equipmentsBC.create.details')
            );
        },

        showDeleteEquipmentsDCToast(name) {
            const deleteToastTitle = this.$t('toasts.equipmentsDC.delete.title');

            const deleteToastDetails = `
                    ${this.$t(`toasts.equipmentsDC.delete.details1`)}
                    ${name}
                    ${this.$t(`toasts.equipmentsDC.delete.details2`)}`
            ;

            showToast('success',
                deleteToastTitle,
                deleteToastDetails
            );
        },

        showCreateEquipmentsDCToast() {
            showToast('success',
                this.$t('toasts.success'),
                this.$t('toasts.equipmentsDC.create.details')
            );
        }
    }
}

export {
    equipmentsToastsMixin
}