<template>
    <div class="buildings">
        <div class="buildings__panel">
            <div class="buildings__title">
                {{ $t('buildings.title') }}
            </div>
            <div class="buildings__actions">
                <app-add-btn
                    @open-modal="openModalBuilding"
                />
            </div>
        </div>
        <div class="buildings__control">
            <div class="buildings__filters">
                <buildings-filters
                    :qty="qty"
                    @update="updateQueryParams"
                />
            </div>
        </div>
        <div class="buildings__content" ref="buildings">
            <div v-if="showTable" class="buildings__table">
                <buildings-table
                    :buildings="buildings"
                    :max-height="maxHeightScroll"
                    @load-by-scroll="loadMoreBuildingsByScroll"
                />
            </div>
            <div v-if="showEmpty" class="buildings__empty">
                <app-empty-content
                    :content-type="'buildings'"
                    @open-modal="openModalBuilding"
                />
            </div>
            <div v-if="showNoResults" class="buildings__empty">
                <app-no-results
                    @clear="clearQueryParams"
                />
            </div>
            <div v-if="isLoaded" class="buildings__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import BuildingsTable from "./BuildingsTable";
    import BuildingsFilters from "./BuildingsFilters";
    import cloneDeep from "lodash.clonedeep";
    import { computed } from "vue";
    import { greenHubAPI } from "../../config/api";
    import { maxHeightMixin } from "../../mixins/calcMaxHeight";
    import { buildingsToastsMixin } from "../../mixins/toasts/buildings";
    import { mapActions } from "vuex";
    import { mapBuildingsToView } from "../../api/mappers/buildings";
    import { defaultQuery } from "../../helpers/api";

    export default {
        name: "BuildingsControl",

        components: {
            BuildingsFilters,
            BuildingsTable
        },

        mixins: [
            maxHeightMixin,
            buildingsToastsMixin
        ],

        data() {
            return {
                isLoaded: false,
                buildings: [],
                qty: 0,

                query: null
            }
        },

        provide() {
            return {
                subString: computed(() => this.searchSubString)
            }
        },

        created() {
            this.updateQueryParamsByDefault();
            this.getBuildings(false);
        },

        mounted() {
            this.subscribeToResize('buildings');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
        },

        computed: {
            isExistBuildings() {
                return this.buildings.length;
            },

            showTable() {
                return this.isExistBuildings && !this.isLoaded;
            },

            showEmpty() {
                return !this.isExistBuildings && !this.isLoaded && !this.isExistQueryParams;
            },

            showNoResults() {
                return !this.isExistBuildings && !this.isLoaded && this.isExistQueryParams;
            },

            isExistQueryParams() {
                return !!this.searchSubString;
            },

            searchSubString() {
                return this.query.params.match ? this.query.params.match : '';
            }
        },

        methods: {
            ...mapActions({
                updateBuildingsToStore: 'buildings/getBuildings'
            }),

            subscribeToEvents() {
                this.emitter.on('updateBuildings', this.updateBuildings);
                this.emitter.on('deleteBuilding', this.deleteBuilding);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateBuildings', this.updateBuildings);
                this.emitter.off('deleteBuilding', this.deleteBuilding);
            },

            getBuildings(isLoadedMore) {
                if (!isLoadedMore) {
                    this.isLoaded = true;
                }

                const params = {
                    params: this.query.params
                };

                greenHubAPI.get('/buildings', params)
                    .then((result) => {
                        const { qty, buildings } = result.data;

                        this.qty = qty;
                        const mappedBuildings = mapBuildingsToView(buildings);

                        if (isLoadedMore) {
                            this.buildings = [...this.buildings, ...mappedBuildings];

                            if (this.qty <= this.buildings.length) {
                                this.emitter.emit('isNoMore');
                            }
                        } else {
                            this.buildings = mappedBuildings;
                        }

                        this.emitter.emit('isLoadedMore');
                        this.emitter.emit('updateShowQty');
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            loadMoreBuildingsByScroll() {
                this.updateQueryParamsByScroll();
                this.getBuildings(true);
            },

            updateQueryParams(params) {
                this.updateQueryParamsByDefault();
                this.query.params = { ...this.query.params, ...params };
                this.emitter.emit('clearNoMore');
                this.getBuildings(false);
            },

            updateQueryParamsByScroll() {
                this.query.page += 1;
                this.query.params.skip = this.query.page * this.query.params.limit;
            },

            updateQueryParamsByDefault() {
                this.query = cloneDeep(defaultQuery);
            },

            updateQueryParamsByDefaultManual() {
                this.query.page = 0;
                this.query.params.skip = 0;
                this.query.params.limit = 15;
            },

            clearQueryParams() {
                this.emitter.emit('clearQueryParams');
            },

            updateBuildings() {
                this.updateQueryParamsByDefaultManual();
                this.emitter.emit('clearNoMore');
                this.getBuildings(false);
                this.updateBuildingsToStore();
            },

            deleteBuilding({ id, name }) {
                greenHubAPI.delete(`/buildings/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.closeModalBuilding();
                        this.showDeleteBuildingToast(name);
                        this.updateBuildings();
                    })
            },

            openModalBuilding() {
                this.openModal({
                    name: 'modalBuilding'
                });
            },

            closeModalBuilding() {
                this.closeModal('modalBuilding');
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            }
        }
    }
</script>