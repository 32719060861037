<template>
    <div class="balancer">
        <div class="balancer__panel">
            <div class="balancer__title">
                {{ $t('balancer.title') }}
            </div>
            <div class="balancer__actions">
                <app-add-btn
                    @open-modal="openModalBalancerEquipment"
                />
            </div>
        </div>
        <div class="balancer__control">
            <div class="balancer__menu">
                <app-menu-item v-for="item in items" :key="item.id"
                    :id="item.id"
                    :name="item.name"
                    :route="item.route"
                />
            </div>
        </div>
        <div class="balancer__content">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import AppMenuItem from "../common/local/AppMenuItem";
    import { balancerMenuItems } from "../../constants/menu";

    export default {
        name: "BalancerControl",

        components: {
            AppMenuItem
        },

        computed: {
            items() {
                return balancerMenuItems;
            }
        },

        methods: {
            openModalBalancerEquipment() {
                const modalName = this.$route.path.includes('/monitoring')
                    ? 'modalBalancerStation'
                    : 'modalBalancerEquipment';


                this.openModal({
                    name: modalName
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .balancer {
        &__control {
            padding: 0 30px;
        }
    }
</style>