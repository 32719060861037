import i18n from "../../../src/localization/i18n"
const { t } = i18n.global;

function mapSecurityObjectsToView(objects) {
    return objects.map((object) => {
        return {
            id: object._id,
            name: object.name,
            buildingId: object.building_id,
            buildingName: object.building_name,
            floor: object.floor,
            entrance: object.entrance,
            moduleId: object.module_id,
            channel: object.channel,
            state: object.state,
            status: object.status,
            errors: object.devErrors
        }
    })
}

function mapSecurityModulesToSelect(modules) {
    return modules.map((module, index) => {
        return {
            id: module._id,
            name: `${t('security.module')} ${index + 1}, ${module.hw_id}`
        }
    })
}

function mapSecurityObjectToSend(securityObject) {
    return {
        name: securityObject.name,
        module_id: securityObject.moduleId,
        channel: securityObject.channelId,
    }
}

export {
    mapSecurityObjectsToView,
    mapSecurityModulesToSelect,
    mapSecurityObjectToSend
}