<template>
    <div class="balancer-monitoring">
        <div class="balancer-monitoring__content" ref="balancerMonitoring">
            <div v-if="showTable">
                <balancer-monitoring-table
                    :stations="stations"
                    :max-height="maxHeightScroll"
                />
            </div>
            <div v-if="showEmpty" class="balancer-monitoring__empty">
                <app-empty-content
                    :content-type="'balancerStation'"
                    @open-modal="openModalBalancerStation"
                />
            </div>
            <div v-if="isLoaded" class="balancer-monitoring__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { greenHubAPI } from "../../../config/api";
    import { maxHeightMixin } from "../../../mixins/calcMaxHeight";
    import { balancerToastsMixin } from "../../../mixins/toasts/balancer";
    import { mapBalancerStationsToView } from "../../../api/mappers/balancer";
    import BalancerMonitoringTable from "./BalancerMonitoringTable";

    export default {
        name: "BalancerMonitoringControl",

        components: {
            BalancerMonitoringTable
        },

        data() {
            return {
                isLoaded: true,
                timerId: '',
                stations: []
            }
        },

        mixins: [
            maxHeightMixin,
            balancerToastsMixin
        ],

        created() {
            this.updateBalancerStations();

            this.timerId = setInterval(() => {
                this.getBalancerStations();
            }, 5000);
        },

        mounted() {
            this.subscribeToResize('balancerMonitoring');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
            clearInterval(this.timerId);
        },

        computed: {
            isStationsExist() {
                return !!this.stations.length;
            },

            showTable() {
                return this.isStationsExist && !this.isLoaded;
            },

            showEmpty() {
                return !this.isStationsExist && !this.isLoaded;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateBalancerStations', this.updateBalancerStations);
                this.emitter.on('deleteBalancerStation', this.deleteBalancerStation);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateBalancerStations', this.updateBalancerStations);
                this.emitter.off('deleteBalancerStation', this.deleteBalancerStation);
            },

            getBalancerStations() {
                greenHubAPI.get('balancer/transformer')
                    .then((result) => {
                        this.stations = mapBalancerStationsToView(result.data);

                        this.$nextTick(() => {
                            this.calcMaxHeight();
                        });
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            deleteBalancerStation({ id, name }) {
                greenHubAPI.delete(`/balancer/transformer/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.closeModalBalancerStation();
                        this.showDeleteBalancerStationToast(name);
                        this.updateBalancerStations();
                    })
            },

            updateBalancerStations() {
                this.isLoaded = true;
                this.getBalancerStations();
            },

            openModalBalancerStation() {
                this.openModal({
                    name: 'modalBalancerStation'
                })
            },

            closeModalBalancerStation() {
                this.closeModal('modalBalancerStation');
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .balancer-monitoring {
        display: flex;
        flex-direction: column;
        height: 100%;

        &__content {
            flex-grow: 1;
        }
    }
</style>