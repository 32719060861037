<template>
    <div class="period" ref="period">
        <div class="period__select" @click="togglePanel"
             :class="{
                'period__select--is-open': showPanel
            }"
        >
            <div class="period__name">
                {{ name }}
            </div>
            <div class="period__control">
                <button v-if="!isOnline" type="button" class="period__clear-btn" @click.stop="clearPeriod">
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.875 4.50625L10.9938 3.625L7.5 7.11875L4.00625 3.625L3.125 4.50625L6.61875 8L3.125 11.4938L4.00625 12.375L7.5 8.88125L10.9938 12.375L11.875 11.4938L8.38125 8L11.875 4.50625Z" fill="#A4AFBE"/>
                    </svg>
                </button>
                <button type="button" class="period__toggle-btn">
                    <svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.17467 4.98304L0.143745 0.172908L10.2335 0.202285L5.17467 4.98304Z" fill="#999999"/>
                    </svg>
                </button>
            </div>
        </div>
        <div v-if="showPanel" class="period__panel">
            <div v-for="period in periods" :key="period.id">
                <app-view-item
                    :id="period.id"
                    :name="period.name"
                    @select="selectPeriod"
                />
            </div>
            <div class="period__date">
                <div>{{ $t('period.date') }}</div>
                <button type="button" @click="toggleDatePeriod">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.8333 2.37435H15.0416V0.791016H13.4583V2.37435H5.54159V0.791016H3.95825V2.37435H3.16659C2.29575 2.37435 1.58325 3.08685 1.58325 3.95768V16.6243C1.58325 17.4952 2.29575 18.2077 3.16659 18.2077H15.8333C16.7041 18.2077 17.4166 17.4952 17.4166 16.6243V3.95768C17.4166 3.08685 16.7041 2.37435 15.8333 2.37435ZM15.8333 16.6243H3.16659V7.91602H15.8333V16.6243ZM15.8333 6.33268H3.16659V3.95768H15.8333V6.33268Z" fill="#A4AFBE"/>
                    </svg>
                </button>
                <div v-if="showDatePicker" class="period__picker">
                    <div class="period__dates">
                        <div class="period__start-date" @click="setState(0)"
                             :class="{
                                'period__start-date--is-active': state === 0
                             }"
                        >
                            {{ formattedStartDate }}
                        </div>
                        <div class="period__arrow">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 11H17.17L13.59 7.41L15 6L21 12L15 18L13.59 16.59L17.17 13H3V11Z" fill="#2B3037"/>
                            </svg>
                        </div>
                        <div class="period__end-date" @click="setState(1)"
                             :class="{
                                'period__end-date--is-active': state === 1
                             }"
                        >
                            {{ formattedEndDate }}
                        </div>
                    </div>
                    <div class="pickers">
                        <app-period-date-picker
                            v-if="!state"
                            :init-date="startDate"
                            @update="selectStartDate"
                        />
                        <app-period-date-picker
                            v-else
                            :init-date="endDate"
                            :is-start-date="false"
                            @update="selectEndDate"
                        />
                    </div>
                    <div class="period__picker-control">
                        <app-base-btn
                            :variation="'cancel'"
                            :text="$t('common.buttons.cancel')"
                            @action="closeDatePicker"
                        />
                        <app-base-btn
                            :variation="'save'"
                            :text="$t('common.buttons.save')"
                            :is-disabled="startDate > endDate"
                            @action="saveDates"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AppViewItem from "../local/AppViewItem";
    import AppPeriodDatePicker from "../local/AppPeriodDatePicker";
    import { viewPeriods } from "../../../constants/period";
    import {formatTimestampToDate, formatDateToUTCToSend, prepareMonthPeriod} from "../../../helpers/date";

    export default {
        name: "AppBaseSelectView",

        components: {
            AppPeriodDatePicker,
            AppViewItem
        },

        data() {
            return {
                selectedPeriod: null,

                showPanel: false,
                showDatePicker: false,
                startDate: '',
                endDate: '',
                showEndDate: false,
                state: 0
            }
        },

        created() {
            this.init();
        },

        mounted() {
            document.addEventListener("mousedown", this.handlerClickOutside);
        },

        unmounted() {
            document.removeEventListener("mousedown", this.handlerClickOutside);
        },

        computed: {
            isOnline() {
                return this.selectedPeriod.id === 'online';
            },

            name() {
                return this.selectedPeriod.name;
            },

            periods() {
                return viewPeriods;
            },

            formattedStartDate() {
                return formatTimestampToDate(this.startDate);
            },

            formattedEndDate() {
                return formatTimestampToDate(this.endDate);
            }
        },

        methods: {
            init() {
                this.startDate = this.endDate = new Date().getTime();
                this.selectedPeriod = this.periods[0];
            },

            togglePanel() {
                this.showPanel = !this.showPanel;
            },

            selectPeriod(period) {
                this.selectedPeriod = period;

                if (this.selectedPeriod.id === 'currentMonth' || this.selectedPeriod.id === 'previousMonth') {
                    const { newStartDate, newEndDate } = prepareMonthPeriod(this.selectedPeriod.id);

                    this.selectedPeriod = { ...this.selectedPeriod, ...{
                        startPeriod: newStartDate.getTime(),
                        endPeriod:  newEndDate.getTime()
                    }};
                }

                this.closePanel();
                this.updatePeriod();
            },

            handlerClickOutside(event) {
                if (!this.$refs.period.contains(event.target)) {
                    this.showPanel = false;
                }
            },

            clearPeriod() {
                this.selectedPeriod = this.periods[0];
                this.updatePeriod();
            },

            toggleDatePeriod() {
                this.showDatePicker = !this.showDatePicker;
            },

            closeDatePicker() {
                this.showDatePicker = false;
            },

            closePanel() {
                this.showPanel = false;
            },

            setState(state) {
                this.state = state;
            },

            selectStartDate(date) {
                this.startDate = date;
            },

            selectEndDate(date) {
                this.endDate = date;
            },

            saveDates() {
                const { newStartDate, newEndDate } = { ...formatDateToUTCToSend(this.startDate, this.endDate) };

                this.selectedPeriod = {
                    id: 'date',
                    name: `${this.formattedStartDate} - ${this.formattedEndDate}`,
                    startPeriod: newStartDate.getTime(),
                    endPeriod: newEndDate.getTime()
                };

                this.closeDatePicker();
                this.closePanel();
                this.updatePeriod();
            },

            updatePeriod() {
                this.$emit('update', this.selectedPeriod);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .period {
        position: relative;
        font-size: 16px;
        color: var(--app-base-primary);

        &__select {
            width: 100%;
            padding: 14px;
            line-height: 16px;
            border-radius: 13px;
            border: 1px solid var(--app-border);
            transition: border 0.3s ease;
            height: 48px;
            position: relative;

            &--is-open {
                border: 1px solid var(--brand-main);

                .period__toggle-btn {
                    transform: rotate(180deg);
                }
            }
        }

        &__panel {
            width: 100%;
            max-width: 282px;
            background: var(--system-white);
            box-shadow: 0 16px 32px rgba(35, 23, 5, 0.26);
            border-radius: 13px;
            padding: 8px 0;
            position: absolute;
            top: 54px;
            left: 0;
            z-index: 10;
            font-weight: 400;
        }

        &__control {
            position: absolute;
            top: 14px;
            right: 12px;
            display: flex;
        }

        &__tag {
            display: flex;
            justify-content: space-between;
        }

        &__toggle-btn {
            transition: transform .2s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 16px;
            width: 16px;
        }

        &__clear-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 16px;
            width: 24px;
            border-right: 1px solid var(--app-border);
            margin-right: 4px;
        }

        &__date {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding: 10px 15px;
            white-space: nowrap;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 19px;
                width: 19px;
            }
        }

        &__picker {
            background: var(--system-white);
            box-shadow: 0 16px 32px rgba(35, 23, 5, 0.26);
            border-radius: 13px;
            z-index: 20;
            position: absolute;
            right: -240px;
            top: -140px;
        }

        &__dates {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 0px;
            font-size: 14px;
        }

        &__picker-control {
            display: flex;
            padding: 10px;
            max-width: 252px;

            button:not(:last-child) {
                margin-right: 10px;
            }
        }

        &__start-date, &__end-date {
            border: 1px solid var(--app-border);
            border-radius: 13px;
            padding: 15px 10px;

            &:hover {
                cursor: pointer;
            }

            &--is-active {
                border: 1px solid var(--brand-main);
            }
        }

        &__arrow {
            margin: 0 4px;
        }
    }
</style>