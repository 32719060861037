<template>
    <div class="equipments-filters filters">
        <div class="filters__control">
            <div class="filters__building filters__element">
                <app-base-select ref="building"
                    :selected-option="selectedBuilding"
                    :options="buildings"
                    :place-holder="$t('filters.buildings.ph')"
                    :type="'filter'"
                    @select="selectBuilding"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import AppFilterActiveItem from "../filters/AppFilterActiveItem";
    import { mapGetters } from "vuex";

    export default {
        name: "EquipmentsFilters",

        components: {
            AppFilterActiveItem
        },

        data() {
            return {
                selectedBuilding: null
            }
        },

        mounted() {
            this.selectBuilding(this.buildings[0]);
        },

        watch: {
            isLoaded: {
                immediate: false,
                handler() {
                    this.selectBuilding(this.buildings[0]);
                    this.$refs.building.initSelect();
                },
            },
        },

        computed: {
            ...mapGetters({
                buildings: 'buildings/mappedBuildings',
                isLoaded: 'buildings/isLoadedBuildings'
            }),
        },

        methods: {
            selectBuilding(building) {
                this.selectedBuilding = building;

                if (this.selectedBuilding) {
                    this.updateQueryId();
                }
            },

            updateQueryId() {
                this.$emit('updateQueryId', this.selectedBuilding.id);
            }
        }
    }
</script>