<template>
    <div class="admin-panel" :class="{ 'admin-panel--is-adaptive': isAdaptive }">
        <nav class="admin-panel__navigation" @mouseenter="isAdaptiveHover = true" @mouseleave="isAdaptiveHover = false"
            :class="{
                'admin-panel__navigation--is-hover': isAdaptive && isAdaptiveHover
            }"
        >
            <div class="admin-panel__panel">
                <div v-if="!isAdaptive || isAdaptive && isAdaptiveHover" class="admin-panel__logo">
                    <admin-logo
                        :logo-url="logo.link"
                    />
                </div>
<!--                <div class="admin-panel__language">-->
<!--                    <admin-select-lang/>-->
<!--                </div>-->
            </div>
            <div class="admin-panel__menu" ref="menu">
                <div v-if="showAdminMenu" class="admin-panel__menu-items" :style="{ 'maxHeight': maxHeightScroll }">
                    <router-link v-for="item in menuItems" :key="item.id" :to="item.route" class="admin-panel__menu-item">
                        <admin-menu-item
                            :in-active-color="'var(--app-base-secondary)'"
                            :active-color="'var(--brand-main)'"
                            :is-active="currentRoute.split('/')[1] === item.route.split('/')[1]"
                            :icon="item.link"
                            :name="item.name"
                            :is-not-adaptive="!isAdaptive || isAdaptive && isAdaptiveHover"
                        />
                    </router-link>
                </div>
            </div>
            <div class="admin-panel__logout">
                <button type="button" class="admin-panel__logout-btn" @click="openModalLogout">
                    <admin-menu-item
                        :in-active-color="'var(--app-base-secondary)'"
                        :active-color="'var(--brand-main)'"
                        :is-active="false"
                        :icon="'/icons/logout.svg'"
                        :name="$t('common.buttons.logout')"
                        :is-not-adaptive="!isAdaptive || isAdaptive && isAdaptiveHover"
                    />
                </button>
            </div>
        </nav>
        <div class="admin-panel__manage">
            <main class="admin-panel__content">
                <router-view/>
            </main>
        </div>
        <app-all-modals/>
    </div>
</template>

<script>
    import AdminLogo from "../components/logo/AdminLogo";
    import AdminMenuItem from "../components/common/local/AdminMenuItem";
    import AdminSelectLang from "../components/common/local/AdminSelectLang";
    import AppAllModals from "../components/modals/AppAllModals";
    import { menuIconParams } from "../constants/menu";
    import { defaultColors, hexToRGBA } from "../helpers/colors";
    import { maxHeightMixin } from "../mixins/calcMaxHeight";
    import { mapActions } from "vuex";
    import { logout } from "../services/checkAccess";

    export default {
        name: "AdminPanel",

        components: {
            AppAllModals,
            AdminSelectLang,
            AdminLogo,
            AdminMenuItem
        },

        data() {
            return {
                isLoaded: false,
                isAdaptive: false,
                isAdaptiveHover: false,
                menuItems: [],
                logo: {
                    link: ''
                },
                showAdminMenu: false
            }
        },

        mixins: [
            maxHeightMixin
        ],

        beforeRouteEnter (to, from, next) {
            if (to.path === '/') {
                next(vm => {
                    vm.$router.push({ path: '/buildings' });
                })
            } else {
                next();
            }
        },

        created() {
            this.getBuildings();
            this.getAppSettings();
            this.handlerOnResize();
        },

        mounted() {
            this.subscribeToResize('menu');
            this.showAdminMenu = true;
            window.addEventListener('resize', this.handlerOnResize);
            this.emitter.on('logout', this.logoutUser);
        },

        unmounted() {
            this.unsubscribeFromResize();
            window.removeEventListener('resize', this.handlerOnResize);
            this.emitter.off('logout', this.logoutUser);
        },

        computed: {
            currentRoute() {
                return this.$route.path;
            }
        },

        methods: {
            ...mapActions({
                getBuildings: 'buildings/getBuildings'
            }),

            handlerOnResize() {
                this.isAdaptive = window.screen.availWidth <= 1600;
            },

            getAppSettings() {
                this.createShadeColors(defaultColors);
                this.mapDefaultAppIcons();
            },

            mapDefaultAppIcons() {
                this.menuItems = [];

                this.logo.link = this.logo.defaultLink = '/icons/logo.svg';
                const iconKeys = Object.keys(menuIconParams);
                iconKeys.shift();

                iconKeys.forEach((key) => {
                    const modifiedIcon = { ...menuIconParams[key] };
                    modifiedIcon.link = modifiedIcon.defaultLink = `/icons/${key}.svg`;

                    modifiedIcon.isDefault = true;
                    this.menuItems.push(modifiedIcon);
                })
            },

            createShadeColors(colors) {
                const baseColors = Object.entries(colors);
                const shadeColors = [];

                baseColors.forEach((color) => {
                    const key = color[0];
                    const value = color[1];

                    if (key === '--app-base-primary' || key === '--brand-main' || key.includes('system')) {
                        for (let i = 10; i < 75; i+=5) {
                            const shadeKey = `${key}-${i}`;
                            const shadeValue = hexToRGBA(value, i);

                            shadeColors.push([ shadeKey, shadeValue ]);
                        }
                    }
                })

                const allColors = baseColors.concat(shadeColors);

                allColors.forEach((color) => {
                    const propertyName = color[0];
                    const value = color[1];
                    document.documentElement.style.setProperty(`${propertyName}`, `${value}`);
                });
            },

            openModalLogout() {
                this.openModal({
                    name: 'modalLogout'
                });
            },

            async logoutUser() {
                await logout()
                    .then(isSuccess => {
                        if (isSuccess) {
                            this.$router.push({ name: 'Login' });
                        }
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .admin-panel {
        width: 100%;
        min-height: 100vh;
        display: flex;
        background: var(--app-menu-background);

        &__navigation {
            /*background: var(--system-white);*/
            /*box-shadow: 0px 0px 40px var(--grey07);*/
            z-index: 1;
            width: 260px;
            display: flex;
            flex-direction: column;
        }

        &__manage {
            display: flex;
            flex-direction: column;

            width: calc(100% - 260px);
        }

        &__content {
            background: var(--system-white);
            flex-grow: 1;
        }

        &__panel {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 80px;
            padding: 0 15px 0 30px;
            border-bottom: 1px solid var(--app-border);
        }

        &__logout {
            display: flex;
            align-items: center;
            padding: 15px 30px;
            border-top: 1px solid var(--app-border);
        }

        &__menu {
            flex-grow: 1;
            padding: 30px;
        }

        &__menu-items {
            overflow-y: auto;
            scrollbar-width: none;
            scroll-behavior: smooth;
        }

        &__menu-item {
            display: flex;

            &:not(:last-child) {
                margin-bottom: 15px;
            }

            /*&--is-disabled {*/
            /*    pointer-events: none;*/
            /*}*/
        }

        &__menu-items::-webkit-scrollbar {
            width: 0;
            display: none;
        }

        &__logout-btn {

        }

        &--is-adaptive {
            .admin-panel__panel {
                padding-left: 10px;
                padding-right: 0;
            }

            .admin-panel__navigation {
                position: fixed;
                min-height: 100vh;
                width: unset;
            }

            .admin-panel__navigation--is-hover {
                width: 260px;
                background: var(--app-menu-background);
                cursor: pointer;

                .admin-panel__panel {
                    padding: 0 15px 0 30px;
                }
            }

            .admin-panel__manage {
                width: 100%;
                padding-left: 85px;
            }

            .admin-panel__profile {
                padding: 0 15px;
            }
        }
    }
</style>