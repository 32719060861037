import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const errors = {
    water: [
        {
            id: 1,
            name: t('water.pad.errors.1')
        },
        {
            id: 2,
            name: t('water.pad.errors.2')
        },
        {
            id: 3,
            name: t('water.pad.errors.3')
        },
        {
            id: 4,
            name: t('water.pad.errors.4')
        },
        {
            id: 5,
            name: t('water.pad.errors.5')
        },
        {
            id: 6,
            name: t('water.pad.errors.6')
        },
        {
            id: 7,
            name: t('water.pad.errors.7')
        },
        {
            id: 8,
            name: t('water.pad.errors.8')
        },
        {
            id: 9,
            name: t('water.pad.errors.9')
        },
        {
            id: 10,
            name: t('water.pad.errors.10')
        },
        {
            id: 11,
            name: t('water.pad.errors.11')
        },
        {
            id: 17,
            name: t('water.pad.errors.1')
        },
        {
            id: 18,
            name: t('water.pad.errors.2')
        },
        {
            id: 19,
            name: t('water.pad.errors.3')
        },
        {
            id: 20,
            name: t('water.pad.errors.4')
        },
        {
            id: 21,
            name: t('water.pad.errors.5')
        },
        {
            id: 22,
            name: t('water.pad.errors.6')
        },
        {
            id: 23,
            name: t('water.pad.errors.7')
        },
        {
            id: 24,
            name: t('water.pad.errors.8')
        }
    ],
    elevator: [
        {
            id: 1,
            name: t('elevators.errors.1')
        },
        {
            id: 2,
            name: t('elevators.errors.2')
        },
        {
            id: 3,
            name: t('elevators.errors.3')
        },
        {
            id: 4,
            name: t('elevators.errors.4')
        },
        {
            id: 5,
            name: t('elevators.errors.5')
        },
        {
            id: 6,
            name: t('elevators.errors.6')
        },
        {
            id: 7,
            name: t('elevators.errors.7')
        }
    ],
    electricity: [
        {
            id: 1,
            name: t('electricity.errors.1')
        },
        {
            id: 2,
            name: t('electricity.errors.2')
        },
        {
            id: 3,
            name: t('electricity.errors.3')
        },
        {
            id: 4,
            name: t('electricity.errors.4')
        },
        {
            id: 5,
            name: t('electricity.errors.5')
        },
        {
            id: 6,
            name: t('electricity.errors.6')
        },
        {
            id: 7,
            name: t('electricity.errors.7')
        },
        {
            id: 8,
            name: t('electricity.errors.8')
        },
        {
            id: 9,
            name: t('electricity.errors.9')
        },
        {
            id: 10,
            name: t('electricity.errors.10')
        },
        {
            id: 11,
            name: t('electricity.errors.11')
        },
        {
            id: 12,
            name: t('electricity.errors.12')
        },
        {
            id: 13,
            name: t('electricity.errors.13')
        },
        {
            id: 14,
            name: t('electricity.errors.14')
        },
        {
            id: 15,
            name: t('electricity.errors.15')
        },
        {
            id: 16,
            name: t('electricity.errors.16')
        },
        {
            id: 17,
            name: t('electricity.errors.17')
        },
        {
            id: 18,
            name: t('electricity.errors.18')
        },
        {
            id: 19,
            name: t('electricity.errors.19')
        },
        {
            id: 20,
            name: t('electricity.errors.20')
        },
        {
            id: 21,
            name: t('electricity.errors.21')
        },
        {
            id: 22,
            name: t('electricity.errors.22')
        },
        {
            id: 23,
            name: t('electricity.errors.23')
        },
        {
            id: 24,
            name: t('electricity.errors.24')
        },
        {
            id: 25,
            name: t('electricity.errors.25')
        }
    ],
    boiler: [
        {
            id: 1,
            name: t('balancer.equipment.boiler.errors.1')
        },
        {
            id: 2,
            name: t('balancer.equipment.boiler.errors.2')
        },
        {
            id: 4,
            name: t('balancer.equipment.boiler.errors.4')
        },
        {
            id: 5,
            name: t('balancer.equipment.boiler.errors.5')
        },
        {
            id: 6,
            name: t('balancer.equipment.boiler.errors.6')
        },
        {
            id: 7,
            name: t('balancer.equipment.boiler.errors.7')
        },
        {
            id: 8,
            name: t('balancer.equipment.boiler.errors.8')
        },
        {
            id: 9,
            name: t('balancer.equipment.boiler.errors.9')
        }
    ],
    security: [
        {
            id: 1,
            name: t('security.errors.1')
        }
    ]
}

const unknownError = {
    id: 'unknown',
    name: t('common.errors.unknown')
}

export {
    errors,
    unknownError
}