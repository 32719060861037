function generateTemporaryId() {
    return Date.now();
}

// function getUniqueList(list) {
//     return Array.from(new Set(list.map(JSON.stringify))).map(JSON.parse);
// }

export {
    generateTemporaryId,
    // getUniqueList
}