import { getFiltersByType } from "../../constants/filters";

const filters = {
    namespaced: true,

    state: () => ({
        filters: null
    }),

    getters: {
        premiseFilters(state) {
            return getFiltersByType('premises', state.filters);
        },

        waterFilters(state) {
            return getFiltersByType('water', state.filters);
        },

        electricityFilters(state) {
            return getFiltersByType('electricity', state.filters);
        },

        balancerSystemFilters(state) {
            return getFiltersByType('balancerSystem', state.filters);
        },

        elevatorsFilters(state) {
            return getFiltersByType('elevators', state.filters);
        },

        securityFilters(state) {
            return getFiltersByType('security', state.filters);
        },

        // balancerMonitoringFilters(state) {
        //     return getFiltersByType('balancerMonitoring', state.filters);
        // },

        //
        // securityLogsFilters(state) {
        //     return getFiltersByType('securityLogs', state.filters);
        // },
    },

    mutations: {
        setFilters(state, filters) {
            state.filters = filters;
        },

        updateFilters(state, filter) {
            state.filters = { ...state.filters, ...filter };
        }
    },

    actions: {
        addFilter({ commit }, filter) {
            commit('updateFilters', filter);
        }
    }
}

export {
    filters
}