function clearDependentSelects(dependentSelects) {
    //TODO: USE clearAndInitDependentSelects AND CHECK!!!
    Object.keys(dependentSelects).forEach((key) => {
        dependentSelects[key].clearSelectedOption();
    })
}

function clearAndInitDependentSelects(selects) {
    Object.keys(selects).forEach((key) => {
        const select = selects[key];
        select.clearSelectedOption();
        select.initSelect();
    });
}

export {
    clearDependentSelects,
    clearAndInitDependentSelects
}