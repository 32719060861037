<template>
    <div class="base-filters" ref="filters">
        <button type="button" class="base-filters__btn" @click="toggleShowFilters">
            <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.29687 6.75033H17.7135L12.4948 13.3128L7.29687 6.75033ZM4.43229 6.34408C6.53646 9.04199 10.4219 14.042 10.4219 14.042V20.292C10.4219 20.8649 10.8906 21.3337 11.4635 21.3337H13.5469C14.1198 21.3337 14.5885 20.8649 14.5885 20.292V14.042C14.5885 14.042 18.4635 9.04199 20.5677 6.34408C21.099 5.65658 20.6094 4.66699 19.7448 4.66699H5.25521C4.39063 4.66699 3.90104 5.65658 4.43229 6.34408Z" fill="#A4AFBE"/>
            </svg>
            <span>{{ $t('filters.title') }}</span>
        </button>
        <div v-if="showFilters" class="base-filters__panel">
            <div class="base-filters__control">
                <div class="base-filters__title">
                    {{ $t('filters.title') }}
                </div>
                <app-text-btn
                    :text="$t('filters.save')"
                    @action="saveActiveFilters"
                >
                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4583 2.875H3.95833C3.07958 2.875 2.375 3.5875 2.375 4.45833V15.5417C2.375 16.4125 3.07958 17.125 3.95833 17.125H15.0417C15.9125 17.125 16.625 16.4125 16.625 15.5417V6.04167L13.4583 2.875ZM15.0417 15.5417H3.95833V4.45833H12.8013L15.0417 6.69875V15.5417ZM9.5 10C8.18583 10 7.125 11.0608 7.125 12.375C7.125 13.6892 8.18583 14.75 9.5 14.75C10.8142 14.75 11.875 13.6892 11.875 12.375C11.875 11.0608 10.8142 10 9.5 10ZM4.75 5.25H11.875V8.41667H4.75V5.25Z" fill="var(--brand-purple)"/>
                    </svg>
                </app-text-btn>
            </div>
            <div class="base-filters__elements">
                <div v-for="(elements, name) in filters">
                    <app-filter-element v-if="name !== 'states'"
                        :name="name"
                        :elements="elements"
                        :active-elements="activeFilters[name]"
                        @update="updateActiveFilters"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AppFilterElement from "../../filters/AppFilterElement";

    export default {
        name: "AppBaseFilters",

        components: {
            AppFilterElement
        },

        props: {
            filters: {
                type: Object,
                required: true
            },
            activeFilters: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                showFilters: false
            }
        },

        mounted() {
            document.addEventListener("mousedown", this.handlerClickOutside);
        },

        unmounted() {
            document.removeEventListener("mousedown", this.handlerClickOutside);
        },

        methods: {
            toggleShowFilters() {
                this.showFilters = !this.showFilters;
            },

            handlerClickOutside(event) {
                const element = this.$refs.filters;

                if (!element.contains(event.target)) {
                    this.showFilters = false;
                }
            },

            saveActiveFilters() {
                this.emitter.emit('updateFilterActiveElements');

                setTimeout(() => {
                    this.emitter.emit('updateFilters');
                }, 100);
            },

            updateActiveFilters(name, activeFilters) {
                this.$emit('update', name, activeFilters);
                this.showFilters = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .base-filters {
        width: 100%;
        position: relative;

        &__btn {
            height: 48px;
            border-radius: 10px;
            background: var(--app-table-background);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-size: 16px;
            color: var(--app-base-primary);

            span {
                margin-left: 15px;
            }
        }

        &__panel {
            position: absolute;
            width: 294px;
            padding: 30px 0;
            box-shadow: 0px 16px 32px rgba(35, 23, 5, 0.26);
            background: var(--system-white);
            margin-top: 10px;
            border-radius: 13px;
            z-index: 1;
        }

        &__control {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
            padding: 0px 30px;
        }

        &__title {
            font-size: 24px;
            font-weight: 500;
        }

        &__elements {
            max-height: 40vh;
            overflow-y: auto;
            scrollbar-width: thin;
        }
    }
</style>