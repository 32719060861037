<template>
    <div class="equipments">
        <div class="equipments__panel">
            <div class="equipments__title">
                {{ $t('equipments.title') }}
            </div>
            <div class="equipments__actions">
                <div class="equipments__action-btn">
                    <app-base-btn
                        :size="'padding'"
                        :variation="'base'"
                        :text="$t('common.buttons.dc')"
                        @action="openModalEquipmentDC"
                    >
                        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.7917 14.0413H13.5417V20.2913H11.4584V14.0413H5.20837V11.958H11.4584V5.70801H13.5417V11.958H19.7917V14.0413Z" fill="#2B3037"/>
                        </svg>
                    </app-base-btn>
                </div>
                <div class="equipments__action-btn">
                    <app-base-btn
                        :size="'padding'"
                        :variation="'save'"
                        :text="$t('common.buttons.bc')"
                        @action="openModalEquipmentBC"
                    >
                        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.7917 14.0413H13.5417V20.2913H11.4584V14.0413H5.20837V11.958H11.4584V5.70801H13.5417V11.958H19.7917V14.0413Z" fill="#FFFFFF"/>
                        </svg>
                    </app-base-btn>
                </div>
            </div>
        </div>
        <div class="equipments__control">
            <div class="equipments__filters">
                <equipments-filters
                    @update-query-id="updateQueryId"
                />
            </div>
        </div>
        <div class="equipments__content" ref="equipments">
            <div v-if="showTable">
                <equipments-table
                    :equipments="equipments"
                    :query-id="queryId"
                    :max-height="maxHeightScroll"
                />
            </div>
            <div v-if="showEmpty" class="equipments__empty">
                <app-empty-content
                    :content-type="'equipments'"
                    @open-modal="openModalEquipmentBC"
                />
            </div>
            <div v-if="isLoaded" class="equipments__loader">
                <app-loader
                    :size="'large'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import EquipmentsFilters from "./EquipmentsFilters";
    import EquipmentsTable from "./EquipmentsTable";
    import { maxHeightMixin } from "../../mixins/calcMaxHeight";
    import { equipmentsToastsMixin } from "../../mixins/toasts/equipments";
    import { greenHubAPI } from "../../config/api";
    import { mapEquipmentsToView } from "../../api/mappers/equipments";

    export default {
        name: "EquipmentsControl",

        components: {
            EquipmentsFilters,
            EquipmentsTable
        },

        data() {
            return {
                isLoaded: false,
                timerId: '',
                queryId: '',
                equipments: []
            }
        },

        mixins: [
            maxHeightMixin,
            equipmentsToastsMixin
        ],

        created() {
            this.timerId = setInterval(() => {
                this.getEquipments(this.queryId);
            }, 5000);
        },

        mounted() {
            this.subscribeToResize('equipments');
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromResize();
            this.unsubscribeFromEvents();
            clearInterval(this.timerId);
        },

        computed: {
            isExistEquipments() {
                return this.equipments.length;
            },

            showTable() {
                return this.isExistEquipments && !this.isLoaded;
            },

            showEmpty() {
                return !this.isExistEquipments && !this.isLoaded;
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('updateEquipments', this.updateEquipments);
                this.emitter.on('deleteEquipmentBC', this.deleteEquipmentBC);
                this.emitter.on('deleteEquipmentDC', this.deleteEquipmentDC);
            },

            unsubscribeFromEvents() {
                this.emitter.off('updateEquipments', this.updateEquipments);
                this.emitter.off('deleteEquipmentBC', this.deleteEquipmentBC);
                this.emitter.off('deleteEquipmentDC', this.deleteEquipmentDC);
            },

            getEquipments(id) {
                greenHubAPI.get(`/equipments/${id}`)
                    .then((result) => {
                        this.equipments = mapEquipmentsToView(result.data);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isLoaded = false;
                        }, 500)
                    })
            },

            deleteEquipmentBC({ id, name }) {
                greenHubAPI.delete(`/equipments/building_controllers/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.closeModalEquipmentBC();
                        this.showDeleteEquipmentsBCToast(name);
                        this.updateEquipments();
                    })
            },

            deleteEquipmentDC({ id, name }) {
                greenHubAPI.delete(`/equipments/dispatching_controllers/${id}`)
                    .then(() => {
                        this.closeModalConfirm();
                        this.closeModalEquipmentDC();
                        this.showDeleteEquipmentsDCToast(name);
                        this.updateEquipments();
                    })
            },

            openModalEquipmentBC() {
                const selectedElement = {
                    buildingId: this.queryId,
                };

                this.openModal({
                    name: 'modalEquipmentBC',
                    selectedEl: selectedElement
                })
            },

            openModalEquipmentDC() {
                const selectedElement = {
                    buildingId: this.queryId,
                    bcs: this.equipments
                };

                this.openModal({
                    name: 'modalEquipmentDC',
                    selectedEl: selectedElement
                })
            },

            closeModalConfirm() {
                this.closeModal('modalConfirm');
            },

            closeModalEquipmentBC() {
                this.closeModal('modalEquipmentBC');
            },

            closeModalEquipmentDC() {
                this.closeModal('modalEquipmentDC');
            },

            updateQueryId(id) {
                this.queryId = id;
                this.updateEquipments();
            },

            updateEquipments() {
                this.isLoaded = true;
                this.getEquipments(this.queryId);
            }
        }
    }
</script>

<style lang="scss">
    .equipment {
        &__name {
            width: 30%;

            display: flex;
            align-items: center;

            button {
                margin-right: 30px;
            }

            &--is-bc {
                font-weight: 600;
            }
        }

        &__serial-number {
            width: 32%;
        }

        &__entrance {
            width: 15%;
        }

        &__floor {
            width: 15%;
        }

        &__state {
            width: 8%;
        }
    }
</style>