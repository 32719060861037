import { validateHex } from "./common";
import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const elevatorErrors = {
    name: t('validation.required'),
    hwId: t('validation.hex'),
    building: t('validation.required'),
    floor: t('validation.required'),
    dc: t('validation.required')
}

function validateElevator(elevator, building, floor, dc) {
    let isValid = false;

    const errors = {
        name: '',
        hwId: '',
        building: '',
        floor: '',
        dc: ''
    }

    const isValidName = !!elevator.name;

    if (!isValidName) {
        errors.name = elevatorErrors.name;
    }

    const isValidHWId = validateHex(elevator.hwId);

    if (!isValidHWId) {
        errors.hwId = elevatorErrors.hwId;
    }

    const isValidBuilding = !!building;

    if (!isValidBuilding) {
        errors.building = elevatorErrors.building;
    }

    const isValidFloor = !!floor;

    if (!isValidFloor) {
        errors.floor = elevatorErrors.floor;
    }

    const isValidDC = !!dc;

    if (!isValidDC) {
        errors.dc = elevatorErrors.dc;
    }

    const inputs = elevator.ports.inputs;
    const outputs = elevator.ports.outputs

    const isValidInputPorts = !!inputs && checkElevatorChannelPorts(inputs);
    const isValidOutputPorts = !!outputs && checkElevatorChannelPorts(outputs);

    isValid = isValidName && isValidHWId && isValidBuilding && isValidFloor && isValidDC && isValidInputPorts && isValidOutputPorts;

    return { isValid, errors };
}

function checkElevatorChannelPorts(ports) {
    return Object.values(ports).every((value) => value !== null);
}

export {
    validateElevator
}