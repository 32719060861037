import { createRouter, createWebHistory } from 'vue-router'
import { checkAccess } from '../services/checkAccess'
import UserLogin from '../views/UserLogin.vue'
import AdminPanel from '../views/AdminPanel.vue'
import BuildingsControl from '../components/buildings/BuildingsControl.vue'
import PremisesControl from '../components/premises/PremisesControl'
import WaterControl from "../components/water/WaterControl";
import ElectricityControl from "../components/electricity/ElectricityControl";
import BalancerControl from "../components/balancer/BalancerControl";
import BalancerSystemControl from "../components/balancer/system/BalancerSystemControl";
import BalancerMonitoringControl from "../components/balancer/monitoring/BalancerMonitoringControl";
import EquipmentsControl from "../components/equipments/EquipmentsControl";
import DashboardControl from "../components/dashboard/DashboardControl";
import ElevatorsControl from "../components/elevators/ElevatorsControl";
import SecurityControl from "../components/security/SecurityControl";

const routes = [
  {
    path: '/',
    name: 'Admin',
    component: AdminPanel,
    children: [
        {
            path: 'dashboard',
            component: DashboardControl
        },
        {
            path: 'buildings',
            component: BuildingsControl
        },
        {
            path: 'premises',
            component: PremisesControl
        },
        {
            path: 'equipments',
            component: EquipmentsControl
        },
        {
            path: 'water',
            component: WaterControl
        },
        {
            path: 'electricity',
            component: ElectricityControl
        },
        {
            path: 'balancer',
            component: BalancerControl,
            children: [
                {
                    path: 'system',
                    component: BalancerSystemControl
                },
                {
                    path: 'monitoring',
                    component: BalancerMonitoringControl
                }
            ]
        },
        {
            path: 'elevators',
            component: ElevatorsControl
        },
        {
            path: 'security',
            component: SecurityControl
        }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: UserLogin
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to,from,next) => {
  await checkAccess()
      .then(userSession => {
        if (!userSession && to.path !== '/login') {
          router.push({ name: 'Login' });
        }

        next();
      });
});

export default router

