import { validateHex } from "./common";
import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const bcErrors = {
    building: t('validation.required'),
    floor: t('validation.required'),
    name: t('validation.required'),
    serialNumber: t('validation.hex'),
}

const dcErrors = {
    bc: t('validation.required'),
    floor: t('validation.required'),
    name: t('validation.required'),
    serialNumber: t('validation.hex'),
}

function validateEquipmentsBC(bc, building, floor) {
    let isValid = false;

    const errors = {
        building: '',
        name: '',
        serialNumber: '',
        floor: '',
    }

    const isValidBuilding = !!building;

    if (!isValidBuilding) {
        errors.building = bcErrors.building;
    }

    const isValidFloor = !!floor;

    if (!isValidFloor) {
        errors.floor = bcErrors.floor;
    }

    const isValidName = !!bc.name;

    if (!isValidName) {
        errors.name = bcErrors.name;
    }

    const isValidSerialNumber = validateHex(bc.serialNumber);

    if (!isValidSerialNumber) {
        errors.serialNumber = bcErrors.serialNumber;
    }

    isValid = isValidBuilding && isValidFloor && isValidName && isValidSerialNumber;

    return { isValid, errors };
}

function validateEquipmentsDC(dc, bc, floor) {
    let isValid = false;

    const errors = {
        bc: '',
        name: '',
        serialNumber: '',
        floor: '',
    }

    const isValidBC = !!bc;

    if (!bc) {
        errors.bc = dcErrors.bc;
    }

    const isValidFloor = !!floor;

    if (!isValidFloor) {
        errors.floor = dcErrors.floor;
    }

    const isValidName = !!dc.name;

    if (!isValidName) {
        errors.name = dcErrors.name;
    }

    const isValidSerialNumber = validateHex(dc.serialNumber);

    if (!isValidSerialNumber) {
        errors.serialNumber = dcErrors.serialNumber;
    }

    isValid = isValidBC && isValidFloor && isValidName && isValidSerialNumber;

    return { isValid, errors };
}

export {
    validateEquipmentsBC,
    validateEquipmentsDC
}