<template>
    <div class="elevator-channels-port">
        <div class="elevator-channels-port__entry"
            :class="{
                'elevator-channels-port__entry--is-open' :isOpenSelect && !showError,
                'elevator-channels-port__entry--is-error': showError
            }"
        >
            <div class="elevator-channels-port__name">
                {{ name }}
            </div>
            <div class="elevator-channels-port__pin">
                <app-base-select
                    :is-clear="true"
                    :place-holder="$t('elevators.elevator.ports.ph')"
                    :selected-option="selectedPin"
                    :options="filteredPins"
                    :type="'pin'"
                    @select="selectPin"
                    @focus="focusPin"
                    @close="closePin"
                />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ElevatorChannelsPort",

        props: {
            isUpdate: {
                type: Boolean,
                required: true
            },
            id: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            pins: {
                type: Array,
                required: true
            },
            selectedPins: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                selectedPin: null,
                isOpenSelect: false,
                showError: false
            }
        },

        created() {
            this.checkUpdateElevator();
        },

        mounted() {
            this.subscribeToEvents();
        },

        unmounted() {
            this.unsubscribeFromEvents();
        },

        computed: {
            filteredPins() {
                return this.pins.filter((pin) => !Object.values(this.selectedPins).includes(pin.id));
            }
        },

        methods: {
            subscribeToEvents() {
                this.emitter.on('validateChannelPort', this.validateChannelPort);
            },

            unsubscribeFromEvents() {
                this.emitter.off('validateChannelPort', this.validateChannelPort);
            },

            checkUpdateElevator() {
                if (this.isUpdate) {
                    this.selectedPin = this.pins.find((pin) => pin.id === this.selectedPins[this.id]);
                }
            },

            focusPin() {
                this.isOpenSelect = true;
            },

            closePin() {
                this.isOpenSelect = false;
            },

            selectPin(pin) {
                this.selectedPin = pin;
                this.$emit('update', this.id, this.selectedPin);
            },

            validateChannelPort() {
                this.showError = !this.selectedPin;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .elevator-channels-port {
        &__entry {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 4px 0 4px 14px;
            color: var(--app-base-primary);
            font-size: 16px;
            line-height: 18px;
            border-radius: 13px;
            border: 1px solid var(--app-border);
            transition: border 0.3s ease;

            &--is-open {
                border: 1px solid var(--brand-main);
            }

            &--is-error {
                border: 1px solid var(--system-red);
            }
        }

        &__name {
            width: 100%;
            line-height: 28px;
            border-right: 1px solid var(--app-border);
        }

        &__pin {
            max-width: 100px;
        }

        &__error {
            margin-top: 10px;
            font-size: 14px;
            color: var(--system-red);
        }
    }
</style>