const defaultColors = {
    '--app-menu-background': '#EDEDED',
    '--app-table-background': '#F9F9F9',
    '--app-base-primary': '#3A3A3A',
    '--app-base-secondary': '#848484',
    '--app-border': '#CECECE',
    '--app-btn-secondary': '#E6F5EB',
    '--brand-main': '#52D40F',
    '--brand-purple': '#33AB5C',
    '--brand-pink': '#CDEAD7',
    '--system-red': '#FF3B30',
    '--system-orange': '#FF9500',
    '--system-yellow': '#F2C200',
    '--system-green': '#24A645',
    '--system-blue': '#007AFF',
    '--system-purple': '#AF52DE',
    '--system-pink': '#FF2D55'
}

function hexToRGBA(hex, opacity) {
    const [ hex1, hex2, hex3, hex4, hex5, hex6 ] = hex.substring(1).split('');
    const r = hexPairToDecimal(hex1, hex2);
    const g = hexPairToDecimal(hex3, hex4);
    const b = hexPairToDecimal(hex5, hex6);
    const a = opacity/100;

    return `rgba( ${r}, ${g}, ${b}, ${a})`;
}

function hexPairToDecimal(firstHex, secondHex) {
    return parseInt(firstHex, 16) * 16 + parseInt(secondHex, 16);
}

export {
    defaultColors,
    hexToRGBA
}