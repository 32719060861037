<template>
    <div class="balancer-equipment-row">
        <div class="table__row balancer-equipment-row__entry" @click="handlerClickEquipment">
            <div class="table__column balancer-equipment__building">
                <app-text-wrapper
                    :text="buildingName"
                    :sub-text="searchSubString"
                    :is-match="true"
                />
            </div>
            <div class="table__column balancer-equipment__premise-type">
                <app-premise-type
                    :type="premiseType"
                />
            </div>
            <div class="table__column balancer-equipment__entrance">
                {{ entrance }}
            </div>
            <div class="table__column balancer-equipment__floor">
                {{ floor }}
            </div>
            <div class="table__column balancer-equipment__premise-name">
                <app-text-wrapper
                    :text="premiseName"
                    :sub-text="searchSubString"
                    :is-match="true"
                />
            </div>
            <div class="table__column balancer-equipment__temp-status">
                <app-base-status
                    :type="'temperature'"
                    :status="tempStatus"
                />
            </div>
            <div class="table__column balancer-equipment__boiler-power">
                {{ formattedBoilerPower }}
            </div>
            <div class="table__column balancer-equipment__boiler-limit">
                {{ formattedBoilerLimit }}
            </div>
            <div class="table__column balancer-equipment__status">
                <app-base-status
                    :show-errors="showErrors"
                    :type="'boiler'"
                    :status="status"
                    :errors="errors"
                    @show-errors="showErrorsBalancerEquipment"
                />
            </div>
        </div>
        <div v-if="showErrors" class="balancer-equipment-row__errors">
            <app-errors-row
                :type="'boiler'"
                :errors="errors"
            />
        </div>
    </div>
</template>

<script>
    import AppPremiseType from "../../common/local/AppPremiseType";

    export default {
        name: "BalancerEquipmentRow",

        components: {
            AppPremiseType
        },

        props: {
            id: {
                type: String,
                required: true
            },
            buildingName: {
                type: String,
                required: true
            },
            premiseType: {
                type: Number,
                required: true
            },
            floor: {
                type: Number,
                required: true
            },
            entrance: {
                type: Number,
                default: null
            },
            tempStatus: {
                type: Number,
                required: true
            },
            premiseName: {
                type: String,
                required: true
            },
            boilerCurrentPower: {
                type: Number,
                required: true
            },
            boilerMaxPower: {
                type: Number,
                required: true
            },
            boilerLimit: {
                type: Number,
                required: true
            },
            status: {
                type: Boolean,
                required: true
            },
            errors: {
                type: Array,
                required: true
            }
        },

        inject: {
            subString: {
                default: ''
            }
        },

        data() {
            return {
                showErrors: false,
                searchSubString: this.subString
            }
        },

        watch: {
            hasErrors: {
                handler(newValue) {
                    this.showErrors = newValue;
                },

                immediate: true
            }
        },

        computed: {
            hasErrors() {
                return !!this.errors.length;
            },

            formattedBoilerPower() {
                return `${this.boilerCurrentPower.toFixed(1)}/${this.boilerMaxPower.toFixed(1)} ${this.$t('common.units.power')}`;
            },

            formattedBoilerLimit() {
                return `${this.boilerLimit} ${this.$t('common.units.percent')}`;
            }
        },

        methods: {
            handlerClickEquipment() {
                this.$emit('update', this.id);
            },

            showErrorsBalancerEquipment() {
                this.showErrors = !this.showErrors;
            }
        }
    }
</script>

<style lang="scss">
    .balancer-equipment-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: var(--system-white);

        .table__row {
            background: unset;
        }

        &__entry {
            &:hover {
                cursor: pointer;
            }
        }

        &:nth-child(2n) {
            background: var(--app-table-background);
        }
    }
</style>