import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const premiseTypes = [
    {
        id: 1,
        name: t('premises.types.living'),
        background: 'var(--system-green-15)'
    },
    {
        id: 2,
        name: t('premises.types.commercial'),
        background: 'var(--system-blue-15)'
    },
    {
        id: 3,
        name: t('premises.types.technical'),
        background: 'var(--system-red-15)'
    }
]

export {
    premiseTypes
}