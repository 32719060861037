<template>
    <div class="dashboard">
        <div class="dashboard__content">
            <div class="dashboard__panel">
                <div class="dashboard__title">
                    {{ $t('dashboard.title') }}
                </div>
            </div>
            <div class="dashboard__details">
                <div class="dashboard__system-states">
                    <div class="dashboard__system-state" v-for="systemState in systemStates" :key="systemState.id">
                        <app-system-card-state
                            :id="systemState.id"
                            :element="systemState.element"
                            :type="systemState.type"
                            :errors-qty="systemState.errorsQty"
                        />
                    </div>
                </div>
                <div class="dashboard__balancer-state balancer-state">
                    <div class="balancer-state__panel">
                        <div class="balancer-state__title">
                            <h4>{{ $t('balancer.title') }}</h4>
                        </div>
                        <div class="balancer-state__control">
                            <button type="button" class="balancer-state__control-btn">
                                <span>{{ balancerErrorsQty }}</span>
                                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.82178 13.1437L4.92803 14.25L11.178 8L4.92803 1.75L3.82178 2.85625L8.96553 8L3.82178 13.1437Z" fill="var(--system-orange)"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="balancer-state__input" v-for="input in stations[0].inputs" :key="input.id">
                        <balancer-input-station-row
                            :id="input.id"
                            :station-name="stations[0].name"
                            :input-name="input.name"
                            :power="input.power"
                            :unbalance="input.unbalance"
                            :state="input.state"
                        />
                    </div>
                </div>
                <div class="dashboard__systems">
                    <div class="dashboard__system" v-for="system in systemStatistics" :key="system.id">
                        <system-statistics-row
                            :id="system.id"
                            :type="system.type"
                            :buildings="system.buildings"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="dashboard__device-states">
            <div class="dashboard__device-state" v-for="deviceState in deviceStates" :key="deviceState.id">
                <app-device-state-card
                    :id="deviceState.id"
                    :building="deviceState.building"
                    :element="deviceState.element"
                    :device="deviceState.device"
                    :type="deviceState.type"
                    :state="deviceState.state"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { dashboardSystemStates, dashboardDeviceStates, dashboardSystemStatistics } from "../../constants/dashboard";
    import AppDeviceStateCard from "../common/local/AppDeviceStateCard";
    import AppSystemCardState from "../common/local/AppSystemCardState";
    import BalancerInputStationRow from "./elements/BalancerInputStationRow";
    import SystemStatisticsRow from "./elements/SystemStatisticsRow";

    export default {
        name: "DashboardControl",

        components: {
            SystemStatisticsRow,
            BalancerInputStationRow,
            AppSystemCardState,
            AppDeviceStateCard
        },

        computed: {
            systemStates() {
                return dashboardSystemStates;
            },

            deviceStates() {
                return dashboardDeviceStates;
            },

            stations() {
                return [];
            },

            balancerErrorsQty() {
                return `9 ${this.$t('common.states.errors').toLowerCase()}`;
            },

            systemStatistics() {
                return dashboardSystemStatistics;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dashboard {
        display: flex;
        height: 100%;

        &__content {
            width: 66.66%;
        }

        &__device-states {
            width: 33.33%;
            background: var(--app-table-background);
            padding: 30px;
            max-height: calc(100vh);
            overflow-y: auto;
            scrollbar-width: thin;
        }

        &__device-state {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &__panel {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 80px;
            padding: 0 30px;
        }

        &__title {
            font-size: 32px;
            line-height: 38px;
            color: var(--app-base-primary);
            white-space: nowrap;
        }

        &__details {
            padding: 30px 30px 15px 30px;
        }

        &__system-states {
            display: flex;
            flex-wrap: wrap;
        }

        &__system-state {
            flex-basis: 50%;
            padding-bottom: 30px;
            padding-left: 15px;

            &:nth-child(2n + 1) {
                padding-right: 15px;
                padding-left: 0;
            }
        }

        &__systems {
            display: flex;
        }

        &__system {
            flex-basis: 50%;

            &:first-child {
                margin-right: 30px;
            }
        }
    }

    .balancer-state {
        padding: 15px 15px 0 15px;
        background: var(--app-table-background);
        border-radius: 15px;
        margin-bottom: 30px;

        &__panel {
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
        }

        &__title {
            h4 {
                font-weight: 400;
            }
        }

        &__control-btn {
            display: flex;
            align-items: center;
            color: var(--system-orange);

            svg {
                margin-left: 10px;
            }
        }
    }
</style>

<style lang="scss">
    .balancer-input {
        font-size: 16px;
        background: var(--app-table-background) !important;
        padding: 15px !important;
        min-height: unset !important;

        &__station {
            width: 15%;
        }

        &__name {
            width: 15%;
        }

        &__power {
            width: 30%;
        }

        &__unbalance {
            width: 30%;
        }

        &__state {
            width: 10%;
            font-size: 12px;
        }
    }

    .balancer-input.table__row {
        border-top: 1px solid var(--app-border);
        border-bottom: none !important;
    }
</style>