import i18n from "../../../src/localization/i18n"
const { t } = i18n.global;

function mapWaterSystemToView(system) {
    let qty = 0;

    const pads = system.enterWaterPads.map((pad) => {
        qty += 1;

        const nodeWaterPads = pad.nodeWaterPads;
        const consumerWaterPads = pad.consumerWaterPads;
        const enterWaterPad = mapWaterPadToView(pad);

        const childrenWaterPads = nodeWaterPads.concat(consumerWaterPads);

        enterWaterPad.children = childrenWaterPads.map((pad) => {
            qty += 1;

            const consumerWaterPads = pad.consumerWaterPads;
            const childrenWaterPads = mapWaterPadToView(pad);

            if (consumerWaterPads && consumerWaterPads.length) {
                childrenWaterPads.children = consumerWaterPads.map((pad) => {
                    qty += 1;

                    return mapWaterPadToView(pad);
                });
            }

            return childrenWaterPads;
        })

        return enterWaterPad;
    });

    return { qty, pads };
}

function mapWaterPadToView(pad, type) {
    return {
        id: pad._id,
        hwId: pad.hw_id,
        buildingId: pad.building_id,
        bcId: pad.bc_sw_id,
        premiseId: pad.premise_id,
        premiseName: pad.premise_name,
        name: pad.name || '',
        floor: pad.floor,
        entrance: pad.entrance || null,
        counterId: pad.counter_id,
        model: pad.pad_model,
        type: pad.type,
        parentId: pad.parent_pad_id || null,
        readings: pad.readings || 0,
        consumptions: pad.consumptions || 0,
        consumpted: pad.consumpted || 0,
        imbalance: pad.imbalance || 0,
        status: pad.status,
        errors: pad.devErrors,
    }
}

function mapWaterPadsToSelect(system) {
    return system.enterWaterPads.map((pad) => {
        return {
            id: pad._id,
            name: `${t('water.pad.types.enter')} ${pad.hw_id}`,
            nodePads: mapNodeWaterPadsToSelect(pad.nodeWaterPads)
        }
    })
}

function mapNodeWaterPadsToSelect(pads) {
    return pads.map((pad) => {
        return {
            id: pad._id,
            name: `${t('water.pad.types.node')} ${pad.hw_id}`,
        }
    })
}

function mapWaterPadToSend(pad, isUpdate) {
    const padToSend = {
        floor: pad.floor,
        counter_id: pad.counterId,
        premise_id: pad.premiseId,
        hw_id: pad.hwId,
        pad_model: pad.padModel,
    }

    if (!isUpdate) {
        padToSend.bc_sw_id = pad.bcId;
        padToSend.type = pad.type;
    }

    if (pad.name) {
        padToSend.name = pad.name;
    }

    if (pad.parentId) {
        padToSend.parent_pad_id = pad.parentId;
    }

    if (pad.entrance) {
        padToSend.entrance = pad.entrance;
    }

    return padToSend;
}

export {
    mapWaterSystemToView,
    mapWaterPadsToSelect,
    mapWaterPadToSend
}