<template>
    <div class="parameter">
        <div v-if="showParameterValue" class="parameter__value">
            <app-text-wrapper
                :text="formattedValue"
            />
        </div>
        <div v-else class="parameter__status">
            <app-base-status
                :type="'parameter'"
                :status="value"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppBaseParameter",

        props: {
            text: {
                type: String,
                default: ''
            },
            value: {
                type: Number,
                required: true
            },
            units: {
                type: String,
                required: true
            }
        },

        computed: {
            formattedValue() {
                return this.text ? `${this.text} ${this.value} ${this.units}` : `${this.value} ${this.units}`;
            },

            showParameterValue() {
                return this.value !== -1;
            }
        }
    }
</script>