<template>
    <div class="water-pad-row">
        <div class="water-pad-row__parent"
             :class="{
                'water-pad-row__parent--is-enter-consumer': isEnterConsumer,
                'water-pad-row__parent--is-input-consumer': isInputConsumer,
                'water-pad-row__parent--is-last-input-consumer': isLastInputConsumer,
                'water-pad-row__parent--is-show-input-consumers': isShowInputConsumers
             }"
        >
            <div class="table__row water-pad-row__entry" @click.stop="handlerClickWaterPad">
                <div class="table__column water-pad__name"
                    :class="{
                        'water-pad__name--is-enter': !level
                    }"
                     :style="{
                        'paddingLeft': `${40 * level}px`
                     }"
                >
                    <div class="water-pad__collapse-btn">
                        <app-collapse-btn v-if="isExistChildren"
                            :default-is-active="true"
                            @collapse="collapseChildren"
                        />
                    </div>
                    <app-text-wrapper
                        :is-parent="true"
                        :text="name"
                    />
                </div>
                <div class="table__column water-pad__entrance">
                    {{ entrance }}
                </div>
                <div class="table__column water-pad__floor">
                    {{ floor }}
                </div>
                <div class="table__column water-pad__premise">
                    <app-text-wrapper
                        :text="premiseName"
                        :sub-text="searchSubString"
                        :is-match="isConsumer"
                    />
                </div>
                <div class="table__column water-pad__counterId">
                    <app-text-wrapper
                        :text="counterId"
                        :sub-text="searchSubString"
                        :is-match="isConsumer"
                    />
                </div>
                <div class="table__column water-pad__hwId">
                    <app-text-wrapper
                        :text="hwId"
                        :sub-text="searchSubString"
                        :is-match="isConsumer"
                    />
                </div>
                <div class="table__column water-pad__model">
                    <app-text-wrapper
                        :text="padModel"
                    />
                </div>
                <div class="table__column water-pad__data">
                    <div v-if="isOnline" class="water-pad__data-view">
                        <div class="table__column water-pad__readings">
                            <div class="water-pad__readings-wrapper">
                                <app-text-wrapper
                                    :text="formattedReadings"
                                />
                            </div>
                            <app-circle-btn
                                @action="openModalSetReadings"
                            >
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.24951 12.2505H4.43701L10.8887 5.79881L8.70118 3.61132L2.24951 10.063V12.2505ZM3.41618 10.5471L8.70118 5.26215L9.23784 5.79881L3.95285 11.0838H3.41618V10.5471Z" fill="#3A3A3A"/>
                                    <path d="M11.2153 1.91965C10.9878 1.69215 10.6203 1.69215 10.3928 1.91965L9.32535 2.98715L11.5128 5.17465L12.5803 4.10715C12.8078 3.87965 12.8078 3.51215 12.5803 3.28465L11.2153 1.91965Z" fill="#3A3A3A"/>
                                </svg>
                            </app-circle-btn>
                        </div>
                        <div class="table__column water-pad__consumptions">
                            <app-text-wrapper
                                :text="formattedConsumptions"
                            />
                        </div>
                    </div>
                    <div v-else class="water-pad__data-view">
                        <div class="table__column water-pad__consumpted">
                            <app-text-wrapper
                                :text="formattedConsumpted"
                            />
                        </div>
                        <div class="table__column water-pad__imbalance">
                            <app-text-wrapper
                                :text="formattedImbalance"
                            />
                        </div>
                    </div>
                </div>
                <div class="table__column water-pad__status">
                    <app-base-status
                        :show-errors="showErrors"
                        :type="'water'"
                        :status="status"
                        :errors="errors"
                        @show-errors="showErrorsWaterPad"
                    />
                </div>
            </div>
            <div v-if="showErrors" class="water-pad-row__errors">
                <app-errors-row
                    :type="'water'"
                    :errors="errors"
                    @clear="clearErrors"
                />
            </div>
        </div>
        <div v-if="isExistChildren && showChildren">
            <water-pad-row v-for="(pad, index) in children" :key="pad.id"
                :index="index"
                :length="children.length"
                :is-online="isOnline"
                :level="level + 1"
                :id="pad.id"
                :name="pad.name"
                :floor="pad.floor"
                :entrance="pad.entrance"
                :premise-name="pad.premiseName"
                :counter-id="pad.counterId"
                :hw-id="pad.hwId"
                :model="pad.model"
                :type="pad.type"
                :readings="pad.readings"
                :consumptions="pad.consumptions"
                :consumpted="pad.consumpted"
                :imbalance="pad.imbalance"
                :children="pad.children || []"
                :status="pad.status"
                :errors="pad.errors"
                @update="updateWaterPad"
            />
        </div>
    </div>
</template>

<script>
    import { waterPadModels } from "../../../constants/water";
    import { greenHubAPI } from "../../../config/api";
    import { waterToastsMixin } from "../../../mixins/toasts/water";

    export default {
        name: "WaterPadRow",

        props: {
            index: {
                type: Number,
                required: true
            },
            length: {
                type: Number,
                required: true
            },
            isOnline: {
                type: Boolean,
                required: true
            },
            level: {
                type: Number,
                required: true
            },
            id: {
                type: [Number, String],
                required: true
            },
            name: {
                type: String,
                required: true
            },
            entrance: {
                type: Number,
                default: null
            },
            floor: {
                type: Number,
                required: true
            },
            premiseName: {
                type: String,
                required: true
            },
            counterId: {
                type: [Number, String],
                required: true
            },
            hwId: {
                type: [Number, String],
                required: true
            },
            model: {
                type: Number,
                required: true
            },
            type: {
                type: Number,
                required: true
            },
            readings: {
                type: Number,
                required: true
            },
            consumptions: {
                type: Number,
                required: true
            },
            consumpted: {
                type: Number,
                required: true
            },
            imbalance: {
                type: Number,
                required: true
            },
            children: {
                type: Array,
                required: true
            },
            status: {
                type: Boolean,
                required: true
            },
            errors: {
                type: Array,
                required: true
            }
        },

        inject: {
            subString: {
                default: ''
            }
        },

        mixins: [
            waterToastsMixin
        ],

        data() {
            return {
                showChildren: true,
                showErrors: false,
                searchSubString: this.subString
            }
        },

        watch: {
            hasErrors: {
                handler(newValue) {
                    this.showErrors = newValue;
                },

                immediate: true
            }
        },

        computed: {
            hasErrors() {
                return !!this.errors.length;
            },

            isExistChildren() {
                return !!this.children.length;
            },

            isEnterConsumer() {
                return this.type === 3 && this.level === 1;
            },

            isInputConsumer() {
                return this.type === 3 && this.level === 2;
            },

            isConsumer() {
                return this.type === 3;
            },

            isLastInputConsumer() {
                return this.isInputConsumer && this.index === this.length - 1;
            },

            isShowInputConsumers() {
                return this.type === 2 && this.showChildren && this.children.length;
            },

            padModel() {
                const model = waterPadModels.find((model) => model.id === this.model);

                return model ? model.name : this.$t('common.errors.unknown');
            },

            formattedReadings() {
                return `${this.readings.toFixed(3)} ${this.$t('common.units.volume')}`;
            },

            formattedConsumptions() {
                return `${this.consumptions.toFixed(2)} ${this.$t('common.units.flow')}`;
            },

            formattedConsumpted() {
                return `${this.consumpted.toFixed(3)} ${this.$t('common.units.volume')}`;
            },

            formattedImbalance() {
                return `${this.imbalance.toFixed(3)} ${this.$t('common.units.volume')}`;
            }
        },

        methods: {
            collapseChildren() {
                this.showChildren = !this.showChildren;
            },

            handlerClickWaterPad() {
                this.$emit('update', this.id);
            },

            updateWaterPad(id) {
                const pad = this.children.find((pad) => pad.id === id);

                this.openModal({
                    name: 'modalWaterPad',
                    selectedEl: pad
                })
            },

            showErrorsWaterPad() {
                this.showErrors = !this.showErrors;
            },

            clearErrors() {
                greenHubAPI.post(`/water_supply/reset-errors/${this.id}`)
                    .then(() => {
                        this.showClearErrorsToast();
                    })
            },

            openModalSetReadings() {
                const selectedElement = {
                    id: this.id,
                    name: this.hwId,
                    readings: this.readings
                };

                this.openModal({
                    name: 'modalSetReadings',
                    selectedEl: selectedElement
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .water-pad-row {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__parent{
            &--is-enter-consumer {
                background: var(--app-table-background) !important;
            }

            &--is-input-consumer {
                background: var(--app-table-background-secondary) !important;
            }

            &--is-last-input-consumer,
            &--is-show-input-consumers {
                border-bottom: 1px solid var(--app-base-secondary) !important;
            }
        }

        .table__row {
            background: unset;
        }

        &__entry {
            &:hover {
                cursor: pointer;
            }
        }
    }
</style>