import axios from 'axios';
import { checkAccess } from "../services/checkAccess";
import { Auth } from 'aws-amplify';
import router from "../router";
import i18n from "../../src/localization/i18n"
const { t } = i18n.global;
import { showToast } from "../helpers/toasts";

const greenHubAPI = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
});

greenHubAPI.interceptors.request.use(
    (config) => {
        return new Promise((resolve, reject) => {
           checkAccess()
                .then(session => {
                    if (session) {
                        config.headers.Authorization = "Bearer " + session.accessToken.jwtToken;
                        resolve(config);
                    } else {
                        reject('No access');
                    }
                })
               .catch(error => {
                   reject(error);
               });
        })
    }
)

greenHubAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        switch(error.response?.status) {
            case 400:
                handlerError400(error.response?.data.code);
                break;
            case 401:
                handlerError401();
                break;
        }

        return Promise.reject(error);
})

function handlerError400(code) {
    if (code === 7) {
        showToast(
            'error',
            t('toasts.error'),
            t('toasts.access.title')
        );
    }
}

function handlerError401() {
    Auth.signOut();
    router.push({ name: 'Login' });
}



export {
    greenHubAPI
}