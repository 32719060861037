import { validateHex, validatePositiveNumber, validateRangeNumber } from "./common";
import i18n from "../../src/localization/i18n"
const { t } = i18n.global;

const equipmentErrors = {
    building: t('validation.required'),
    premise: t('validation.required'),
    switchgear: t('validation.required'),
    inputNumber: t('validation.required'),
    hwId: t('validation.hex'),
    controller: t('validation.required'),
    maxPower: t('validation.positiveNumber')
}

const stationErrors = {
    name: t('validation.required'),
    buildings: t('validation.buildings'),
}

const limitErrors = {
    range: t('validation.limit'),
}

function validateEquipment(equipment, building, premise, switchgear, inputNumber, controller) {
    let isValid = false;

    const errors = {
        building: '',
        premise: '',
        switchgear: '',
        inputNumber: '',
        hwId: '',
        controller: '',
        maxPower: ''
    }

    const isValidBuilding = !!building;

    if (!isValidBuilding) {
        errors.building = equipmentErrors.building;
    }

    const isValidPremise = !!premise;

    if (!isValidPremise) {
        errors.premise = equipmentErrors.premise;
    }

    const isValidSwitchgear = !!switchgear;

    if (!isValidSwitchgear) {
        errors.switchgear = equipmentErrors.switchgear;
    }

    const isValidInputNumber = !!inputNumber;

    if (!isValidInputNumber) {
        errors.inputNumber = equipmentErrors.inputNumber;
    }

    const isValidHWId = validateHex(equipment.boiler.hwId);

    if (!isValidHWId) {
        errors.hwId = equipmentErrors.hwId;
    }

    const isValidController = !!controller;

    if (!isValidController) {
        errors.controller = equipmentErrors.controller;
    }

    const isValidMaxPower = validatePositiveNumber(equipment.boiler.maxPower);

    if (!isValidMaxPower) {
        errors.maxPower = equipmentErrors.maxPower;
    }

    isValid = isValidBuilding && isValidPremise && isValidSwitchgear && isValidInputNumber && isValidHWId && isValidController && isValidMaxPower;

    return { isValid, errors };
}

function validateStation(station) {
    let isValid = false;

    const errors = {
        name: '',
        buildings: ''
    }

    const isValidName = !!station.name;

    if (!isValidName) {
        errors.name = stationErrors.name;
    }

    const isValidBuildings = !!station.buildings.length;

    if (!isValidBuildings) {
        errors.buildings = stationErrors.buildings;
    }

    isValid = isValidName && isValidBuildings;

    return { isValid, errors };
}

function validateBalancerLimit(limit) {
    let isValid = false;

    const errors = {
        limit: ''
    }

    const isValidLimitRange = validateRangeNumber(limit, 0, 10000);

    if (!isValidLimitRange) {
        errors.limit = limitErrors.range;
    }

    isValid = isValidLimitRange;

    return { isValid, errors };
}

export {
    validateEquipment,
    validateStation,
    validateBalancerLimit
}